import GlitchTitle from "../../../animations/glitchTitle/glitchTitle"
// import ForgetMeNotCarousel from "../../../ui-elements/carousel/carousel"
import Timeline from "../../../ui-elements/timeline/timeline"

import forgetMeNotLogo from "../../../assets/logos/fmn.png"
import './forgetMeNot.scss'
import Navbar from "../../../ui-elements/navbar/navbar"
import Scrollindicator from "../../../ui-elements/scrollindicator/scrollindicator"
import ForgetMeNotCarousel from "../../../ui-elements/carousel/carousel"

const ForgetMeNot = () => {
  return (
    <>
    <Navbar page="ForgetMeNot" />
      <div className="forget">
        <div className="main">
          <img className ="forget-img" src={forgetMeNotLogo} alt="" />
          <GlitchTitle title="Forget Me Not" />
          {/* <div className="para">
              Shaastra is an annual technical festival 
              hosted by the Indian Institute of Technology (IIT) Madras,
              one of India's premier engineering institutions. This event
              serves as a platform for students and technophiles from around 
              the country to showcase their technical prowess, creativity, 
              and innovation. Shaastra encompasses a wide range of activities,
              including workshops, lectures, competitions, and exhibitions, 
              all focused on various domains of
          </div> */}
        </div> 
        {/* <div className="timeline">
          <Timeline />
        </div> */}
        <div className="carousel">
          <ForgetMeNotCarousel title={""} />
        </div>
        <Scrollindicator/>
      </div>
    </>
  )
}

export default ForgetMeNot
      
