import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
};

export type Accommodation = {
  __typename?: 'Accommodation';
  accoId: Scalars['String']['output'];
  accomodationFrom: Scalars['DateTimeISO']['output'];
  accomodationTo: Scalars['DateTimeISO']['output'];
  address: Scalars['String']['output'];
  amount: Scalars['Float']['output'];
  dob: Scalars['DateTimeISO']['output'];
  email: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  noOfDays: Scalars['Float']['output'];
  noOfMen: Scalars['Float']['output'];
  noOfWomen: Scalars['Float']['output'];
  orderId: Scalars['String']['output'];
  organization: Scalars['String']['output'];
  paid: Scalars['Boolean']['output'];
  paymentId?: Maybe<Scalars['String']['output']>;
  paymentSignature?: Maybe<Scalars['String']['output']>;
  personalId: Scalars['String']['output'];
  personalIdType: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type CaptureAccommodationPaymentInput = {
  orderId: Scalars['String']['input'];
  paymentId: Scalars['String']['input'];
  paymentSignature: Scalars['String']['input'];
};

export type CapturePassportPaymentInput = {
  orderId: Scalars['String']['input'];
  paymentId?: InputMaybe<Scalars['String']['input']>;
  paymentSignature?: InputMaybe<Scalars['String']['input']>;
};

export type CapturePaymentInput = {
  orderId: Scalars['String']['input'];
  paymentId: Scalars['String']['input'];
  paymentSignature: Scalars['String']['input'];
};

export type CreateAccommodationInput = {
  accomodationFrom: Scalars['String']['input'];
  accomodationTo: Scalars['String']['input'];
  address: Scalars['String']['input'];
  dob: Scalars['String']['input'];
  email: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  name: Scalars['String']['input'];
  noOfDays: Scalars['Float']['input'];
  noOfMen: Scalars['Float']['input'];
  noOfWomen: Scalars['Float']['input'];
  organization: Scalars['String']['input'];
  personalId: Scalars['String']['input'];
  personalIdType: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type CreateEventInput = {
  amount: Scalars['String']['input'];
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  contentDoc?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  eventDate?: InputMaybe<Scalars['String']['input']>;
  poster: Scalars['String']['input'];
  title: Scalars['String']['input'];
  unstopLink: Scalars['String']['input'];
  venue: Scalars['String']['input'];
  venueLink: Scalars['String']['input'];
};

export type CreateEventSjInput = {
  amount: Scalars['String']['input'];
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  contentDoc?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  eventDate?: InputMaybe<Scalars['String']['input']>;
  poster: Scalars['String']['input'];
  title: Scalars['String']['input'];
  venue: Scalars['String']['input'];
  venueLink: Scalars['String']['input'];
};

export type CreateExhibitionInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  poster: Scalars['String']['input'];
};

export type CreatePassportInput = {
  passportType: Scalars['String']['input'];
};

export type CreateQuizInput = {
  answers: Array<Scalars['String']['input']>;
  options: Array<Array<Scalars['String']['input']>>;
  questions: Array<Scalars['String']['input']>;
};

export type CreateSlotInput = {
  date: Scalars['String']['input'];
  timeRange: Scalars['String']['input'];
};

export type CreateSpeakerInput = {
  date?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  poster: Scalars['String']['input'];
  time?: InputMaybe<Scalars['String']['input']>;
  venue?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserQuizInput = {
  answer: Array<Scalars['String']['input']>;
  quizId: Scalars['String']['input'];
};

export type CreateUserSlotInput = {
  noOfPeople: Scalars['Float']['input'];
  slotId: Scalars['String']['input'];
};

export type CreateUserWorkshopInput = {
  amount: Scalars['Float']['input'];
  autodeskWorkshop: Scalars['Boolean']['input'];
  ciscoWorkshop: Scalars['Boolean']['input'];
  merch: Scalars['String']['input'];
  workshopIds: Array<Scalars['String']['input']>;
};

export type CreateWorkshopInput = {
  basket: Scalars['String']['input'];
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  contentDoc?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  eveningStartTime?: InputMaybe<Scalars['Float']['input']>;
  poster: Scalars['String']['input'];
  title: Scalars['String']['input'];
  venue: Scalars['String']['input'];
  venueLink: Scalars['String']['input'];
  workshopDate?: InputMaybe<Scalars['String']['input']>;
};

export type EditEventInput = {
  amount?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  contentDoc?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  eveningStartTime?: InputMaybe<Scalars['Float']['input']>;
  eventDate?: InputMaybe<Scalars['String']['input']>;
  poster?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  unstopLink?: InputMaybe<Scalars['String']['input']>;
  venue?: InputMaybe<Scalars['String']['input']>;
  venueLink?: InputMaybe<Scalars['String']['input']>;
};

export type EditEventSjInput = {
  amount?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  contentDoc?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  eventDate?: InputMaybe<Scalars['String']['input']>;
  poster?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  venue?: InputMaybe<Scalars['String']['input']>;
  venueLink?: InputMaybe<Scalars['String']['input']>;
};

export type EditWorkshopInput = {
  basket?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  contentDoc?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  eveningStartTime?: InputMaybe<Scalars['Float']['input']>;
  poster?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  venue?: InputMaybe<Scalars['String']['input']>;
  venueLink?: InputMaybe<Scalars['String']['input']>;
  workshopDate?: InputMaybe<Scalars['String']['input']>;
};

export type Event = {
  __typename?: 'Event';
  amount: Scalars['String']['output'];
  contactName?: Maybe<Scalars['String']['output']>;
  contactNumber?: Maybe<Scalars['String']['output']>;
  contentDoc?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  eventDate?: Maybe<Scalars['DateTimeISO']['output']>;
  faqs: Array<Faq>;
  id: Scalars['ID']['output'];
  poster: Scalars['String']['output'];
  title: Scalars['String']['output'];
  unstopLink: Scalars['String']['output'];
  venue: Scalars['String']['output'];
  venueLink: Scalars['String']['output'];
};

export type EventSj = {
  __typename?: 'EventSJ';
  amount: Scalars['String']['output'];
  contactName?: Maybe<Scalars['String']['output']>;
  contactNumber?: Maybe<Scalars['String']['output']>;
  contentDoc?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  eventDate?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  poster: Scalars['String']['output'];
  title: Scalars['String']['output'];
  users: Array<User>;
  venue: Scalars['String']['output'];
  venueLink: Scalars['String']['output'];
};

export type Exhibition = {
  __typename?: 'Exhibition';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  poster: Scalars['String']['output'];
};

export type Faq = {
  __typename?: 'Faq';
  answer: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  question: Scalars['String']['output'];
  updatedOn: Scalars['String']['output'];
  workshop: Workshop;
};

export type Feedback = {
  __typename?: 'Feedback';
  anySuggestions?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  likeMostAboutAppandWhy?: Maybe<Scalars['String']['output']>;
  notlikeMostAboutAppandWhy?: Maybe<Scalars['String']['output']>;
  rateOurApp?: Maybe<Scalars['String']['output']>;
  recommendAppToYourFriends?: Maybe<Scalars['String']['output']>;
  useOurApp?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type FeedbackInput = {
  anySuggestions?: InputMaybe<Scalars['String']['input']>;
  likeMostAboutAppandWhy?: InputMaybe<Scalars['String']['input']>;
  notlikeMostAboutAppandWhy?: InputMaybe<Scalars['String']['input']>;
  rateOurApp?: InputMaybe<Scalars['String']['input']>;
  recommendAppToYourFriends?: InputMaybe<Scalars['String']['input']>;
  useOurApp?: InputMaybe<Scalars['String']['input']>;
};

export type ForgotPasswordCheckingOtpInput = {
  email: Scalars['String']['input'];
  otp: Scalars['String']['input'];
};

export type ForgotPasswordGetEmailInput = {
  email: Scalars['String']['input'];
};

export type ForgotPasswordHashingInput = {
  confirmPassword: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type GetCiscoAutoOutput = {
  __typename?: 'GetCiscoAutoOutput';
  autodesk: Array<UserWorkshop>;
  cisco: Array<UserWorkshop>;
};

export type GetLeaderboardInput = {
  quizId: Scalars['String']['input'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginUserOutput = {
  __typename?: 'LoginUserOutput';
  ifUser: User;
  token: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  UpdateFeedback: Scalars['Boolean']['output'];
  UpdateUser: Scalars['Boolean']['output'];
  captureAccommodationPayment: Scalars['Boolean']['output'];
  capturePassportPayment: Scalars['Boolean']['output'];
  captureUserWorkshopPayment: Scalars['Boolean']['output'];
  changeAman: Scalars['Boolean']['output'];
  checkUserWorkshop: Scalars['Boolean']['output'];
  createAccommodation: Accommodation;
  createEvent: Scalars['Boolean']['output'];
  createEventSJ: Scalars['Boolean']['output'];
  createExhibition: Exhibition;
  createFeedback: Feedback;
  createPassport: Passport;
  createQuiz: Quiz;
  createSlot: Slot;
  createSlots: Scalars['Boolean']['output'];
  createSpeaker: Speakers;
  createUserEventSJ: Scalars['Boolean']['output'];
  createUserQuiz: UserQuiz;
  createUserSlot: UserSlot;
  createUserWorkshop: UserWorkshop;
  createWorkshop: Scalars['Boolean']['output'];
  delSlotsA: Scalars['Boolean']['output'];
  deleteEvent: Scalars['Boolean']['output'];
  deleteEventSJ: Scalars['Boolean']['output'];
  deleteExhibtion: Scalars['Boolean']['output'];
  deleteSpeaker: Scalars['Boolean']['output'];
  deleteWorkshop: Scalars['Boolean']['output'];
  disableUser: Scalars['Boolean']['output'];
  editEvent: Scalars['Boolean']['output'];
  editEventSJ: Scalars['Boolean']['output'];
  editWorkshop: Scalars['Boolean']['output'];
  forgotPasswordCheckingOtp: Scalars['Boolean']['output'];
  forgotPasswordGetEmail: Scalars['Boolean']['output'];
  forgotpasswordHashingPassword: LoginUserOutput;
  loginUser: LoginUserOutput;
  logoutUser: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  sendOtpVerifyEmail: Scalars['Boolean']['output'];
  signUpUser: SignUpOutput;
  toggleLeaderboardVisibility: Scalars['Boolean']['output'];
  upgradeUser: Scalars['Boolean']['output'];
  verifyEmail: Scalars['Boolean']['output'];
};


export type MutationUpdateFeedbackArgs = {
  data: FeedbackInput;
};


export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
};


export type MutationCaptureAccommodationPaymentArgs = {
  data: CaptureAccommodationPaymentInput;
};


export type MutationCapturePassportPaymentArgs = {
  data: CapturePassportPaymentInput;
};


export type MutationCaptureUserWorkshopPaymentArgs = {
  data: CapturePaymentInput;
};


export type MutationCheckUserWorkshopArgs = {
  shaastraID: Scalars['String']['input'];
};


export type MutationCreateAccommodationArgs = {
  data: CreateAccommodationInput;
};


export type MutationCreateEventArgs = {
  data: CreateEventInput;
};


export type MutationCreateEventSjArgs = {
  data: CreateEventSjInput;
};


export type MutationCreateExhibitionArgs = {
  data: CreateExhibitionInput;
};


export type MutationCreateFeedbackArgs = {
  data: FeedbackInput;
};


export type MutationCreatePassportArgs = {
  data: CreatePassportInput;
};


export type MutationCreateQuizArgs = {
  data: CreateQuizInput;
};


export type MutationCreateSlotArgs = {
  data: CreateSlotInput;
};


export type MutationCreateSpeakerArgs = {
  data: CreateSpeakerInput;
};


export type MutationCreateUserEventSjArgs = {
  id: Scalars['String']['input'];
};


export type MutationCreateUserQuizArgs = {
  data: CreateUserQuizInput;
};


export type MutationCreateUserSlotArgs = {
  data: CreateUserSlotInput;
};


export type MutationCreateUserWorkshopArgs = {
  data: CreateUserWorkshopInput;
};


export type MutationCreateWorkshopArgs = {
  data: CreateWorkshopInput;
};


export type MutationDeleteEventArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteEventSjArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteExhibtionArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSpeakerArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteWorkshopArgs = {
  id: Scalars['String']['input'];
};


export type MutationEditEventArgs = {
  data: EditEventInput;
  id: Scalars['String']['input'];
};


export type MutationEditEventSjArgs = {
  data: EditEventSjInput;
  id: Scalars['String']['input'];
};


export type MutationEditWorkshopArgs = {
  data: EditWorkshopInput;
  id: Scalars['String']['input'];
};


export type MutationForgotPasswordCheckingOtpArgs = {
  data: ForgotPasswordCheckingOtpInput;
};


export type MutationForgotPasswordGetEmailArgs = {
  data: ForgotPasswordGetEmailInput;
};


export type MutationForgotpasswordHashingPasswordArgs = {
  data: ForgotPasswordHashingInput;
};


export type MutationLoginUserArgs = {
  data: LoginInput;
};


export type MutationResetPasswordArgs = {
  data: ResetPasswordInput;
};


export type MutationSignUpUserArgs = {
  data: SignUpInput;
};


export type MutationUpgradeUserArgs = {
  email: Scalars['String']['input'];
};


export type MutationVerifyEmailArgs = {
  data: Scalars['String']['input'];
};

export type Passport = {
  __typename?: 'Passport';
  amount: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  orderId: Scalars['String']['output'];
  paid: Scalars['Boolean']['output'];
  passportId: Scalars['String']['output'];
  passportType: Scalars['String']['output'];
  paymentId?: Maybe<Scalars['String']['output']>;
  paymentSignature?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type Query = {
  __typename?: 'Query';
  getAccommodations: Array<Accommodation>;
  getCiscoAuto: GetCiscoAutoOutput;
  getEventSJs: Array<EventSj>;
  getEvents: Array<Event>;
  getExhibition: Array<Exhibition>;
  getFeedbacks: Array<Feedback>;
  getLeaderboard: Array<UserQuiz>;
  getLeaderboardVisibility: Scalars['Boolean']['output'];
  getMe: User;
  getMyEventSJIds: Array<Scalars['String']['output']>;
  getMyFeedback?: Maybe<Feedback>;
  getMyPassport: Passport;
  getMyUserEventSJsCountById: Scalars['Float']['output'];
  getMyUserSlot: UserSlot;
  getMyWorkshopIds: Array<Scalars['String']['output']>;
  getOHUsers: Array<User>;
  getPassports: Array<Passport>;
  getQuiz: Quiz;
  getQuizzes: Array<Quiz>;
  getScore: UserQuiz;
  getSlots: Array<Slot>;
  getSpeakers: Array<Speakers>;
  getUserList: Array<User>;
  getUserQuiz: Array<UserQuiz>;
  getUserSlots: Array<UserSlot>;
  getUserWorkshops: Array<UserWorkshop>;
  getUserWorkshopsAmountByCombos: Scalars['Float']['output'];
  getUserWorkshopsByCombos: Array<UserWorkshop>;
  getUserWorkshopsById: Array<UserWorkshop>;
  getUserWorkshopsCountByCombos: Scalars['Float']['output'];
  getUserWorkshopsCountById: Scalars['Float']['output'];
  getUserWorkshopsPaid: Array<UserWorkshop>;
  getUsers: Array<User>;
  getWorkshops: Array<Workshop>;
};


export type QueryGetLeaderboardArgs = {
  data: GetLeaderboardInput;
};


export type QueryGetMyUserEventSJsCountByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetScoreArgs = {
  data: GetLeaderboardInput;
};


export type QueryGetUserWorkshopsAmountByCombosArgs = {
  noOfWorkshops: Scalars['Float']['input'];
};


export type QueryGetUserWorkshopsByCombosArgs = {
  noOfWorkshops: Scalars['Float']['input'];
};


export type QueryGetUserWorkshopsByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetUserWorkshopsCountByCombosArgs = {
  noOfWorkshops: Scalars['Float']['input'];
};


export type QueryGetUserWorkshopsCountByIdArgs = {
  id: Scalars['String']['input'];
};

export type Quiz = {
  __typename?: 'Quiz';
  answers: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  options: Array<Array<Scalars['String']['output']>>;
  questions: Array<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['DateTimeISO']['output']>;
  userQuiz: Array<UserQuiz>;
};

export type ResetPasswordInput = {
  newPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type SignUpInput = {
  Class?: InputMaybe<Scalars['String']['input']>;
  alternateMobile?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  course?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  hostel?: InputMaybe<Scalars['String']['input']>;
  isIITM?: Scalars['Boolean']['input'];
  mobile: Scalars['String']['input'];
  name: Scalars['String']['input'];
  noOfPeople?: InputMaybe<Scalars['Float']['input']>;
  organization: Scalars['String']['input'];
  password: Scalars['String']['input'];
  personalId?: InputMaybe<Scalars['String']['input']>;
  specialization?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  yearOfGraduation?: InputMaybe<Scalars['String']['input']>;
};

export type SignUpOutput = {
  __typename?: 'SignUpOutput';
  ifUser?: Maybe<User>;
  token: Scalars['String']['output'];
};

export type Slot = {
  __typename?: 'Slot';
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  noOfPeople: Scalars['Float']['output'];
  timeRange: Scalars['String']['output'];
  userSlots: Array<UserSlot>;
};

export type Speakers = {
  __typename?: 'Speakers';
  date?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  format?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  poster: Scalars['String']['output'];
  time?: Maybe<Scalars['String']['output']>;
  venue?: Maybe<Scalars['String']['output']>;
};

export type UpdateUserInput = {
  Class?: InputMaybe<Scalars['String']['input']>;
  alternateMobile?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  course?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  hostel?: InputMaybe<Scalars['String']['input']>;
  isIITM: Scalars['Boolean']['input'];
  mobile: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organization: Scalars['String']['input'];
  profession?: InputMaybe<Scalars['String']['input']>;
  specialization?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  yearOfGraduation?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  Class?: Maybe<Scalars['String']['output']>;
  alternateMobile?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  course?: Maybe<Scalars['String']['output']>;
  disabled: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  emailVerified: Scalars['Boolean']['output'];
  eventsjs: Array<EventSj>;
  feedback?: Maybe<Feedback>;
  gender: Scalars['String']['output'];
  hostel?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isIITM: Scalars['Boolean']['output'];
  mobile: Scalars['String']['output'];
  name: Scalars['String']['output'];
  noOfPeople?: Maybe<Scalars['Float']['output']>;
  organization: Scalars['String']['output'];
  otp?: Maybe<Scalars['String']['output']>;
  passport?: Maybe<Passport>;
  personalId?: Maybe<Scalars['String']['output']>;
  profession?: Maybe<Scalars['String']['output']>;
  role: UserRole;
  shaastraID: Scalars['String']['output'];
  specialization?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  userQuiz: Array<UserQuiz>;
  userSlots?: Maybe<UserSlot>;
  yearOfGraduation?: Maybe<Scalars['String']['output']>;
};

export type UserQuiz = {
  __typename?: 'UserQuiz';
  answer: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  quiz?: Maybe<Quiz>;
  score?: Maybe<Scalars['Float']['output']>;
  timestamp?: Maybe<Scalars['DateTimeISO']['output']>;
  user?: Maybe<User>;
};

/** This are the allowed roles for user */
export enum UserRole {
  Admin = 'ADMIN',
  User = 'USER'
}

export type UserSlot = {
  __typename?: 'UserSlot';
  id: Scalars['ID']['output'];
  noOfPeople: Scalars['Float']['output'];
  slot: Slot;
  user?: Maybe<User>;
};

export type UserWorkshop = {
  __typename?: 'UserWorkshop';
  amount: Scalars['Float']['output'];
  autodeskWorkshop: Scalars['Boolean']['output'];
  ciscoWorkshop: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  merch: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  paid: Scalars['Boolean']['output'];
  paymentId?: Maybe<Scalars['String']['output']>;
  paymentSignature?: Maybe<Scalars['String']['output']>;
  user: User;
  workshopIds: Array<Scalars['String']['output']>;
  workshops: Array<Workshop>;
};

export type Workshop = {
  __typename?: 'Workshop';
  basket: Scalars['String']['output'];
  contactName?: Maybe<Scalars['String']['output']>;
  contactNumber?: Maybe<Scalars['String']['output']>;
  contentDoc?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  eveningStartTime?: Maybe<Scalars['Float']['output']>;
  faqs: Array<Faq>;
  id: Scalars['ID']['output'];
  poster: Scalars['String']['output'];
  title: Scalars['String']['output'];
  userWorkshops: Array<UserWorkshop>;
  venue: Scalars['String']['output'];
  venueLink: Scalars['String']['output'];
  workshopDate?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type SignUpUserMutationVariables = Exact<{
  data: SignUpInput;
}>;


export type SignUpUserMutation = { __typename?: 'Mutation', signUpUser: { __typename?: 'SignUpOutput', token: string, ifUser?: { __typename?: 'User', name: string } | null } };

export type LoginUserMutationVariables = Exact<{
  data: LoginInput;
}>;


export type LoginUserMutation = { __typename?: 'Mutation', loginUser: { __typename?: 'LoginUserOutput', ifUser: { __typename?: 'User', name: string } } };

export type CreateUserWorkshopMutationVariables = Exact<{
  data: CreateUserWorkshopInput;
}>;


export type CreateUserWorkshopMutation = { __typename?: 'Mutation', createUserWorkshop: { __typename?: 'UserWorkshop', orderId: string, amount: number } };

export type CaptureUserWorkshopPaymentMutationVariables = Exact<{
  data: CapturePaymentInput;
}>;


export type CaptureUserWorkshopPaymentMutation = { __typename?: 'Mutation', captureUserWorkshopPayment: boolean };

export type CreateAccommodationMutationVariables = Exact<{
  data: CreateAccommodationInput;
}>;


export type CreateAccommodationMutation = { __typename?: 'Mutation', createAccommodation: { __typename?: 'Accommodation', orderId: string, name: string, email: string, phone: string, amount: number } };

export type CaptureAccommodationPaymentMutationVariables = Exact<{
  data: CaptureAccommodationPaymentInput;
}>;


export type CaptureAccommodationPaymentMutation = { __typename?: 'Mutation', captureAccommodationPayment: boolean };

export type ForgotPasswordGetEmailMutationVariables = Exact<{
  data: ForgotPasswordGetEmailInput;
}>;


export type ForgotPasswordGetEmailMutation = { __typename?: 'Mutation', forgotPasswordGetEmail: boolean };

export type ForgotPasswordCheckingOtpMutationVariables = Exact<{
  data: ForgotPasswordCheckingOtpInput;
}>;


export type ForgotPasswordCheckingOtpMutation = { __typename?: 'Mutation', forgotPasswordCheckingOtp: boolean };

export type ForgotpasswordHashingPasswordMutationVariables = Exact<{
  data: ForgotPasswordHashingInput;
}>;


export type ForgotpasswordHashingPasswordMutation = { __typename?: 'Mutation', forgotpasswordHashingPassword: { __typename?: 'LoginUserOutput', token: string, ifUser: { __typename?: 'User', id: string } } };

export type CreateEventMutationVariables = Exact<{
  data: CreateEventInput;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent: boolean };

export type LogoutUserMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutUserMutation = { __typename?: 'Mutation', logoutUser: boolean };

export type CreatePassportMutationVariables = Exact<{
  data: CreatePassportInput;
}>;


export type CreatePassportMutation = { __typename?: 'Mutation', createPassport: { __typename?: 'Passport', passportType: string, orderId: string } };

export type CapturePassportPaymentMutationVariables = Exact<{
  data: CapturePassportPaymentInput;
}>;


export type CapturePassportPaymentMutation = { __typename?: 'Mutation', capturePassportPayment: boolean };

export type CreateEventSjMutationVariables = Exact<{
  data: CreateEventSjInput;
}>;


export type CreateEventSjMutation = { __typename?: 'Mutation', createEventSJ: boolean };

export type CreateUserEventSjMutationVariables = Exact<{
  createUserEventSjId: Scalars['String']['input'];
}>;


export type CreateUserEventSjMutation = { __typename?: 'Mutation', createUserEventSJ: boolean };

export type VerifyEmailMutationVariables = Exact<{
  data: Scalars['String']['input'];
}>;


export type VerifyEmailMutation = { __typename?: 'Mutation', verifyEmail: boolean };

export type SendOtpVerifyEmailMutationVariables = Exact<{ [key: string]: never; }>;


export type SendOtpVerifyEmailMutation = { __typename?: 'Mutation', sendOtpVerifyEmail: boolean };

export type CreateUserSlotMutationVariables = Exact<{
  data: CreateUserSlotInput;
}>;


export type CreateUserSlotMutation = { __typename?: 'Mutation', createUserSlot: { __typename?: 'UserSlot', noOfPeople: number } };

export type CheckUserWorkshopMutationVariables = Exact<{
  shaastraId: Scalars['String']['input'];
}>;


export type CheckUserWorkshopMutation = { __typename?: 'Mutation', checkUserWorkshop: boolean };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', getMe: { __typename?: 'User', shaastraID: string, name: string, email: string, mobile: string, role: UserRole, emailVerified: boolean, personalId?: string | null, noOfPeople?: number | null, disabled: boolean, userSlots?: { __typename?: 'UserSlot', id: string } | null } };

export type GetWorkshopsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWorkshopsQuery = { __typename?: 'Query', getWorkshops: Array<{ __typename?: 'Workshop', id: string, title: string, description: string, venue: string, venueLink: string, poster: string, basket: string, workshopDate?: any | null, eveningStartTime?: number | null, contentDoc?: string | null, contactNumber?: string | null, contactName?: string | null }> };

export type GetEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventsQuery = { __typename?: 'Query', getEvents: Array<{ __typename?: 'Event', amount: string, contactName?: string | null, contactNumber?: string | null, contentDoc?: string | null, description: string, eventDate?: any | null, id: string, poster: string, title: string, unstopLink: string, venue: string, venueLink: string }> };

export type GetMyWorkshopIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyWorkshopIdsQuery = { __typename?: 'Query', getMyWorkshopIds: Array<string> };

export type GetUserWorkshopsCountByIdQueryVariables = Exact<{
  getUserWorkshopsCountByIdId: Scalars['String']['input'];
}>;


export type GetUserWorkshopsCountByIdQuery = { __typename?: 'Query', getUserWorkshopsCountById: number };

export type GetMyPassportQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyPassportQuery = { __typename?: 'Query', getMyPassport: { __typename?: 'Passport', paid: boolean, passportType: string, passportId: string } };

export type GetUserWorkshopsAmountByCombosQueryVariables = Exact<{
  noOfWorkshops: Scalars['Float']['input'];
}>;


export type GetUserWorkshopsAmountByCombosQuery = { __typename?: 'Query', getUserWorkshopsAmountByCombos: number };

export type GetUserWorkshopsCountByCombosQueryVariables = Exact<{
  noOfWorkshops: Scalars['Float']['input'];
}>;


export type GetUserWorkshopsCountByCombosQuery = { __typename?: 'Query', getUserWorkshopsCountByCombos: number };

export type GetUserWorkshopsByIdQueryVariables = Exact<{
  getUserWorkshopsByIdId: Scalars['String']['input'];
}>;


export type GetUserWorkshopsByIdQuery = { __typename?: 'Query', getUserWorkshopsById: Array<{ __typename?: 'UserWorkshop', merch: string, user: { __typename?: 'User', shaastraID: string, name: string, email: string, mobile: string, alternateMobile?: string | null, gender: string, isIITM: boolean, hostel?: string | null, organization: string, type: string, Class?: string | null, course?: string | null, specialization?: string | null, yearOfGraduation?: string | null, profession?: string | null, country?: string | null } }> };

export type GetUserWorkshopsPaidQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserWorkshopsPaidQuery = { __typename?: 'Query', getUserWorkshopsPaid: Array<{ __typename?: 'UserWorkshop', amount: number, workshopIds: Array<string>, merch: string, user: { __typename?: 'User', shaastraID: string, name: string, email: string, mobile: string } }> };

export type GetEventSJsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventSJsQuery = { __typename?: 'Query', getEventSJs: Array<{ __typename?: 'EventSJ', contactName?: string | null, contactNumber?: string | null, contentDoc?: string | null, description: string, eventDate?: any | null, id: string, poster: string, title: string, venue: string, venueLink: string, amount: string }> };

export type GetMyEventSjIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyEventSjIdsQuery = { __typename?: 'Query', getMyEventSJIds: Array<string> };

export type GetMyUserEventSJsCountByIdQueryVariables = Exact<{
  getMyUserEventSJsCountByIdId: Scalars['String']['input'];
}>;


export type GetMyUserEventSJsCountByIdQuery = { __typename?: 'Query', getMyUserEventSJsCountById: number };

export type GetCiscoAutoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCiscoAutoQuery = { __typename?: 'Query', getCiscoAuto: { __typename?: 'GetCiscoAutoOutput', autodesk: Array<{ __typename?: 'UserWorkshop', user: { __typename?: 'User', name: string, shaastraID: string, email: string, mobile: string } }>, cisco: Array<{ __typename?: 'UserWorkshop', user: { __typename?: 'User', name: string, shaastraID: string, email: string, mobile: string } }> } };

export type GetAccommodationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccommodationsQuery = { __typename?: 'Query', getAccommodations: Array<{ __typename?: 'Accommodation', id: string, accoId: string, email: string, name: string, phone: string, address: string, dob: any, organization: string, amount: number, personalId: string, personalIdType: string, noOfDays: number, accomodationFrom: any, accomodationTo: any, noOfMen: number, noOfWomen: number }> };

export type GetPassportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPassportsQuery = { __typename?: 'Query', getPassports: Array<{ __typename?: 'Passport', passportId: string, amount: number, passportType: string, user?: { __typename?: 'User', email: string, name: string, mobile: string, shaastraID: string } | null }> };

export type GetSlotsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSlotsQuery = { __typename?: 'Query', getSlots: Array<{ __typename?: 'Slot', id: string, timeRange: string, date: string, noOfPeople: number }> };

export type GetMyUserSlotQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyUserSlotQuery = { __typename?: 'Query', getMyUserSlot: { __typename?: 'UserSlot', noOfPeople: number, slot: { __typename?: 'Slot', date: string, timeRange: string } } };

export type GetUserSlotsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserSlotsQuery = { __typename?: 'Query', getUserSlots: Array<{ __typename?: 'UserSlot', user?: { __typename?: 'User', shaastraID: string, name: string, mobile: string, email: string } | null, slot: { __typename?: 'Slot', date: string, timeRange: string } }> };

export type GetOhUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOhUsersQuery = { __typename?: 'Query', getOHUsers: Array<{ __typename?: 'User', shaastraID: string, email: string, name: string, mobile: string, noOfPeople?: number | null, personalId?: string | null, organization: string, userSlots?: { __typename?: 'UserSlot', slot: { __typename?: 'Slot', date: string, timeRange: string } } | null }> };


export const SignUpUserDocument = gql`
    mutation SignUpUser($data: SignUpInput!) {
  signUpUser(data: $data) {
    ifUser {
      name
    }
    token
  }
}
    `;
export type SignUpUserMutationFn = Apollo.MutationFunction<SignUpUserMutation, SignUpUserMutationVariables>;

/**
 * __useSignUpUserMutation__
 *
 * To run a mutation, you first call `useSignUpUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpUserMutation, { data, loading, error }] = useSignUpUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignUpUserMutation(baseOptions?: Apollo.MutationHookOptions<SignUpUserMutation, SignUpUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpUserMutation, SignUpUserMutationVariables>(SignUpUserDocument, options);
      }
export type SignUpUserMutationHookResult = ReturnType<typeof useSignUpUserMutation>;
export type SignUpUserMutationResult = Apollo.MutationResult<SignUpUserMutation>;
export type SignUpUserMutationOptions = Apollo.BaseMutationOptions<SignUpUserMutation, SignUpUserMutationVariables>;
export const LoginUserDocument = gql`
    mutation LoginUser($data: LoginInput!) {
  loginUser(data: $data) {
    ifUser {
      name
    }
  }
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const CreateUserWorkshopDocument = gql`
    mutation CreateUserWorkshop($data: CreateUserWorkshopInput!) {
  createUserWorkshop(data: $data) {
    orderId
    amount
  }
}
    `;
export type CreateUserWorkshopMutationFn = Apollo.MutationFunction<CreateUserWorkshopMutation, CreateUserWorkshopMutationVariables>;

/**
 * __useCreateUserWorkshopMutation__
 *
 * To run a mutation, you first call `useCreateUserWorkshopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserWorkshopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserWorkshopMutation, { data, loading, error }] = useCreateUserWorkshopMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserWorkshopMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserWorkshopMutation, CreateUserWorkshopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserWorkshopMutation, CreateUserWorkshopMutationVariables>(CreateUserWorkshopDocument, options);
      }
export type CreateUserWorkshopMutationHookResult = ReturnType<typeof useCreateUserWorkshopMutation>;
export type CreateUserWorkshopMutationResult = Apollo.MutationResult<CreateUserWorkshopMutation>;
export type CreateUserWorkshopMutationOptions = Apollo.BaseMutationOptions<CreateUserWorkshopMutation, CreateUserWorkshopMutationVariables>;
export const CaptureUserWorkshopPaymentDocument = gql`
    mutation CaptureUserWorkshopPayment($data: CapturePaymentInput!) {
  captureUserWorkshopPayment(data: $data)
}
    `;
export type CaptureUserWorkshopPaymentMutationFn = Apollo.MutationFunction<CaptureUserWorkshopPaymentMutation, CaptureUserWorkshopPaymentMutationVariables>;

/**
 * __useCaptureUserWorkshopPaymentMutation__
 *
 * To run a mutation, you first call `useCaptureUserWorkshopPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaptureUserWorkshopPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [captureUserWorkshopPaymentMutation, { data, loading, error }] = useCaptureUserWorkshopPaymentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCaptureUserWorkshopPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CaptureUserWorkshopPaymentMutation, CaptureUserWorkshopPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CaptureUserWorkshopPaymentMutation, CaptureUserWorkshopPaymentMutationVariables>(CaptureUserWorkshopPaymentDocument, options);
      }
export type CaptureUserWorkshopPaymentMutationHookResult = ReturnType<typeof useCaptureUserWorkshopPaymentMutation>;
export type CaptureUserWorkshopPaymentMutationResult = Apollo.MutationResult<CaptureUserWorkshopPaymentMutation>;
export type CaptureUserWorkshopPaymentMutationOptions = Apollo.BaseMutationOptions<CaptureUserWorkshopPaymentMutation, CaptureUserWorkshopPaymentMutationVariables>;
export const CreateAccommodationDocument = gql`
    mutation CreateAccommodation($data: CreateAccommodationInput!) {
  createAccommodation(data: $data) {
    orderId
    name
    email
    phone
    amount
  }
}
    `;
export type CreateAccommodationMutationFn = Apollo.MutationFunction<CreateAccommodationMutation, CreateAccommodationMutationVariables>;

/**
 * __useCreateAccommodationMutation__
 *
 * To run a mutation, you first call `useCreateAccommodationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccommodationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccommodationMutation, { data, loading, error }] = useCreateAccommodationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAccommodationMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccommodationMutation, CreateAccommodationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccommodationMutation, CreateAccommodationMutationVariables>(CreateAccommodationDocument, options);
      }
export type CreateAccommodationMutationHookResult = ReturnType<typeof useCreateAccommodationMutation>;
export type CreateAccommodationMutationResult = Apollo.MutationResult<CreateAccommodationMutation>;
export type CreateAccommodationMutationOptions = Apollo.BaseMutationOptions<CreateAccommodationMutation, CreateAccommodationMutationVariables>;
export const CaptureAccommodationPaymentDocument = gql`
    mutation CaptureAccommodationPayment($data: CaptureAccommodationPaymentInput!) {
  captureAccommodationPayment(data: $data)
}
    `;
export type CaptureAccommodationPaymentMutationFn = Apollo.MutationFunction<CaptureAccommodationPaymentMutation, CaptureAccommodationPaymentMutationVariables>;

/**
 * __useCaptureAccommodationPaymentMutation__
 *
 * To run a mutation, you first call `useCaptureAccommodationPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaptureAccommodationPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [captureAccommodationPaymentMutation, { data, loading, error }] = useCaptureAccommodationPaymentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCaptureAccommodationPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CaptureAccommodationPaymentMutation, CaptureAccommodationPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CaptureAccommodationPaymentMutation, CaptureAccommodationPaymentMutationVariables>(CaptureAccommodationPaymentDocument, options);
      }
export type CaptureAccommodationPaymentMutationHookResult = ReturnType<typeof useCaptureAccommodationPaymentMutation>;
export type CaptureAccommodationPaymentMutationResult = Apollo.MutationResult<CaptureAccommodationPaymentMutation>;
export type CaptureAccommodationPaymentMutationOptions = Apollo.BaseMutationOptions<CaptureAccommodationPaymentMutation, CaptureAccommodationPaymentMutationVariables>;
export const ForgotPasswordGetEmailDocument = gql`
    mutation ForgotPasswordGetEmail($data: ForgotPasswordGetEmailInput!) {
  forgotPasswordGetEmail(data: $data)
}
    `;
export type ForgotPasswordGetEmailMutationFn = Apollo.MutationFunction<ForgotPasswordGetEmailMutation, ForgotPasswordGetEmailMutationVariables>;

/**
 * __useForgotPasswordGetEmailMutation__
 *
 * To run a mutation, you first call `useForgotPasswordGetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordGetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordGetEmailMutation, { data, loading, error }] = useForgotPasswordGetEmailMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useForgotPasswordGetEmailMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordGetEmailMutation, ForgotPasswordGetEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordGetEmailMutation, ForgotPasswordGetEmailMutationVariables>(ForgotPasswordGetEmailDocument, options);
      }
export type ForgotPasswordGetEmailMutationHookResult = ReturnType<typeof useForgotPasswordGetEmailMutation>;
export type ForgotPasswordGetEmailMutationResult = Apollo.MutationResult<ForgotPasswordGetEmailMutation>;
export type ForgotPasswordGetEmailMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordGetEmailMutation, ForgotPasswordGetEmailMutationVariables>;
export const ForgotPasswordCheckingOtpDocument = gql`
    mutation ForgotPasswordCheckingOtp($data: ForgotPasswordCheckingOtpInput!) {
  forgotPasswordCheckingOtp(data: $data)
}
    `;
export type ForgotPasswordCheckingOtpMutationFn = Apollo.MutationFunction<ForgotPasswordCheckingOtpMutation, ForgotPasswordCheckingOtpMutationVariables>;

/**
 * __useForgotPasswordCheckingOtpMutation__
 *
 * To run a mutation, you first call `useForgotPasswordCheckingOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordCheckingOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordCheckingOtpMutation, { data, loading, error }] = useForgotPasswordCheckingOtpMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useForgotPasswordCheckingOtpMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordCheckingOtpMutation, ForgotPasswordCheckingOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordCheckingOtpMutation, ForgotPasswordCheckingOtpMutationVariables>(ForgotPasswordCheckingOtpDocument, options);
      }
export type ForgotPasswordCheckingOtpMutationHookResult = ReturnType<typeof useForgotPasswordCheckingOtpMutation>;
export type ForgotPasswordCheckingOtpMutationResult = Apollo.MutationResult<ForgotPasswordCheckingOtpMutation>;
export type ForgotPasswordCheckingOtpMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordCheckingOtpMutation, ForgotPasswordCheckingOtpMutationVariables>;
export const ForgotpasswordHashingPasswordDocument = gql`
    mutation ForgotpasswordHashingPassword($data: ForgotPasswordHashingInput!) {
  forgotpasswordHashingPassword(data: $data) {
    ifUser {
      id
    }
    token
  }
}
    `;
export type ForgotpasswordHashingPasswordMutationFn = Apollo.MutationFunction<ForgotpasswordHashingPasswordMutation, ForgotpasswordHashingPasswordMutationVariables>;

/**
 * __useForgotpasswordHashingPasswordMutation__
 *
 * To run a mutation, you first call `useForgotpasswordHashingPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotpasswordHashingPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotpasswordHashingPasswordMutation, { data, loading, error }] = useForgotpasswordHashingPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useForgotpasswordHashingPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotpasswordHashingPasswordMutation, ForgotpasswordHashingPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotpasswordHashingPasswordMutation, ForgotpasswordHashingPasswordMutationVariables>(ForgotpasswordHashingPasswordDocument, options);
      }
export type ForgotpasswordHashingPasswordMutationHookResult = ReturnType<typeof useForgotpasswordHashingPasswordMutation>;
export type ForgotpasswordHashingPasswordMutationResult = Apollo.MutationResult<ForgotpasswordHashingPasswordMutation>;
export type ForgotpasswordHashingPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotpasswordHashingPasswordMutation, ForgotpasswordHashingPasswordMutationVariables>;
export const CreateEventDocument = gql`
    mutation CreateEvent($data: CreateEventInput!) {
  createEvent(data: $data)
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const LogoutUserDocument = gql`
    mutation LogoutUser {
  logoutUser
}
    `;
export type LogoutUserMutationFn = Apollo.MutationFunction<LogoutUserMutation, LogoutUserMutationVariables>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutUserMutation(baseOptions?: Apollo.MutationHookOptions<LogoutUserMutation, LogoutUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutUserMutation, LogoutUserMutationVariables>(LogoutUserDocument, options);
      }
export type LogoutUserMutationHookResult = ReturnType<typeof useLogoutUserMutation>;
export type LogoutUserMutationResult = Apollo.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = Apollo.BaseMutationOptions<LogoutUserMutation, LogoutUserMutationVariables>;
export const CreatePassportDocument = gql`
    mutation CreatePassport($data: CreatePassportInput!) {
  createPassport(data: $data) {
    passportType
    orderId
  }
}
    `;
export type CreatePassportMutationFn = Apollo.MutationFunction<CreatePassportMutation, CreatePassportMutationVariables>;

/**
 * __useCreatePassportMutation__
 *
 * To run a mutation, you first call `useCreatePassportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePassportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPassportMutation, { data, loading, error }] = useCreatePassportMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePassportMutation(baseOptions?: Apollo.MutationHookOptions<CreatePassportMutation, CreatePassportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePassportMutation, CreatePassportMutationVariables>(CreatePassportDocument, options);
      }
export type CreatePassportMutationHookResult = ReturnType<typeof useCreatePassportMutation>;
export type CreatePassportMutationResult = Apollo.MutationResult<CreatePassportMutation>;
export type CreatePassportMutationOptions = Apollo.BaseMutationOptions<CreatePassportMutation, CreatePassportMutationVariables>;
export const CapturePassportPaymentDocument = gql`
    mutation CapturePassportPayment($data: CapturePassportPaymentInput!) {
  capturePassportPayment(data: $data)
}
    `;
export type CapturePassportPaymentMutationFn = Apollo.MutationFunction<CapturePassportPaymentMutation, CapturePassportPaymentMutationVariables>;

/**
 * __useCapturePassportPaymentMutation__
 *
 * To run a mutation, you first call `useCapturePassportPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCapturePassportPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [capturePassportPaymentMutation, { data, loading, error }] = useCapturePassportPaymentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCapturePassportPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CapturePassportPaymentMutation, CapturePassportPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CapturePassportPaymentMutation, CapturePassportPaymentMutationVariables>(CapturePassportPaymentDocument, options);
      }
export type CapturePassportPaymentMutationHookResult = ReturnType<typeof useCapturePassportPaymentMutation>;
export type CapturePassportPaymentMutationResult = Apollo.MutationResult<CapturePassportPaymentMutation>;
export type CapturePassportPaymentMutationOptions = Apollo.BaseMutationOptions<CapturePassportPaymentMutation, CapturePassportPaymentMutationVariables>;
export const CreateEventSjDocument = gql`
    mutation CreateEventSJ($data: CreateEventSJInput!) {
  createEventSJ(data: $data)
}
    `;
export type CreateEventSjMutationFn = Apollo.MutationFunction<CreateEventSjMutation, CreateEventSjMutationVariables>;

/**
 * __useCreateEventSjMutation__
 *
 * To run a mutation, you first call `useCreateEventSjMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventSjMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventSjMutation, { data, loading, error }] = useCreateEventSjMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventSjMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventSjMutation, CreateEventSjMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventSjMutation, CreateEventSjMutationVariables>(CreateEventSjDocument, options);
      }
export type CreateEventSjMutationHookResult = ReturnType<typeof useCreateEventSjMutation>;
export type CreateEventSjMutationResult = Apollo.MutationResult<CreateEventSjMutation>;
export type CreateEventSjMutationOptions = Apollo.BaseMutationOptions<CreateEventSjMutation, CreateEventSjMutationVariables>;
export const CreateUserEventSjDocument = gql`
    mutation CreateUserEventSJ($createUserEventSjId: String!) {
  createUserEventSJ(id: $createUserEventSjId)
}
    `;
export type CreateUserEventSjMutationFn = Apollo.MutationFunction<CreateUserEventSjMutation, CreateUserEventSjMutationVariables>;

/**
 * __useCreateUserEventSjMutation__
 *
 * To run a mutation, you first call `useCreateUserEventSjMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserEventSjMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserEventSjMutation, { data, loading, error }] = useCreateUserEventSjMutation({
 *   variables: {
 *      createUserEventSjId: // value for 'createUserEventSjId'
 *   },
 * });
 */
export function useCreateUserEventSjMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserEventSjMutation, CreateUserEventSjMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserEventSjMutation, CreateUserEventSjMutationVariables>(CreateUserEventSjDocument, options);
      }
export type CreateUserEventSjMutationHookResult = ReturnType<typeof useCreateUserEventSjMutation>;
export type CreateUserEventSjMutationResult = Apollo.MutationResult<CreateUserEventSjMutation>;
export type CreateUserEventSjMutationOptions = Apollo.BaseMutationOptions<CreateUserEventSjMutation, CreateUserEventSjMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($data: String!) {
  verifyEmail(data: $data)
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const SendOtpVerifyEmailDocument = gql`
    mutation SendOtpVerifyEmail {
  sendOtpVerifyEmail
}
    `;
export type SendOtpVerifyEmailMutationFn = Apollo.MutationFunction<SendOtpVerifyEmailMutation, SendOtpVerifyEmailMutationVariables>;

/**
 * __useSendOtpVerifyEmailMutation__
 *
 * To run a mutation, you first call `useSendOtpVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOtpVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOtpVerifyEmailMutation, { data, loading, error }] = useSendOtpVerifyEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendOtpVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendOtpVerifyEmailMutation, SendOtpVerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendOtpVerifyEmailMutation, SendOtpVerifyEmailMutationVariables>(SendOtpVerifyEmailDocument, options);
      }
export type SendOtpVerifyEmailMutationHookResult = ReturnType<typeof useSendOtpVerifyEmailMutation>;
export type SendOtpVerifyEmailMutationResult = Apollo.MutationResult<SendOtpVerifyEmailMutation>;
export type SendOtpVerifyEmailMutationOptions = Apollo.BaseMutationOptions<SendOtpVerifyEmailMutation, SendOtpVerifyEmailMutationVariables>;
export const CreateUserSlotDocument = gql`
    mutation CreateUserSlot($data: CreateUserSlotInput!) {
  createUserSlot(data: $data) {
    noOfPeople
  }
}
    `;
export type CreateUserSlotMutationFn = Apollo.MutationFunction<CreateUserSlotMutation, CreateUserSlotMutationVariables>;

/**
 * __useCreateUserSlotMutation__
 *
 * To run a mutation, you first call `useCreateUserSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSlotMutation, { data, loading, error }] = useCreateUserSlotMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserSlotMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserSlotMutation, CreateUserSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserSlotMutation, CreateUserSlotMutationVariables>(CreateUserSlotDocument, options);
      }
export type CreateUserSlotMutationHookResult = ReturnType<typeof useCreateUserSlotMutation>;
export type CreateUserSlotMutationResult = Apollo.MutationResult<CreateUserSlotMutation>;
export type CreateUserSlotMutationOptions = Apollo.BaseMutationOptions<CreateUserSlotMutation, CreateUserSlotMutationVariables>;
export const CheckUserWorkshopDocument = gql`
    mutation CheckUserWorkshop($shaastraId: String!) {
  checkUserWorkshop(shaastraID: $shaastraId)
}
    `;
export type CheckUserWorkshopMutationFn = Apollo.MutationFunction<CheckUserWorkshopMutation, CheckUserWorkshopMutationVariables>;

/**
 * __useCheckUserWorkshopMutation__
 *
 * To run a mutation, you first call `useCheckUserWorkshopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckUserWorkshopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkUserWorkshopMutation, { data, loading, error }] = useCheckUserWorkshopMutation({
 *   variables: {
 *      shaastraId: // value for 'shaastraId'
 *   },
 * });
 */
export function useCheckUserWorkshopMutation(baseOptions?: Apollo.MutationHookOptions<CheckUserWorkshopMutation, CheckUserWorkshopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckUserWorkshopMutation, CheckUserWorkshopMutationVariables>(CheckUserWorkshopDocument, options);
      }
export type CheckUserWorkshopMutationHookResult = ReturnType<typeof useCheckUserWorkshopMutation>;
export type CheckUserWorkshopMutationResult = Apollo.MutationResult<CheckUserWorkshopMutation>;
export type CheckUserWorkshopMutationOptions = Apollo.BaseMutationOptions<CheckUserWorkshopMutation, CheckUserWorkshopMutationVariables>;
export const GetMeDocument = gql`
    query GetMe {
  getMe {
    shaastraID
    name
    email
    mobile
    role
    emailVerified
    personalId
    noOfPeople
    disabled
    userSlots {
      id
    }
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export function useGetMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeSuspenseQueryHookResult = ReturnType<typeof useGetMeSuspenseQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetWorkshopsDocument = gql`
    query GetWorkshops {
  getWorkshops {
    id
    title
    description
    venue
    venueLink
    poster
    basket
    workshopDate
    eveningStartTime
    contentDoc
    contactNumber
    contactName
  }
}
    `;

/**
 * __useGetWorkshopsQuery__
 *
 * To run a query within a React component, call `useGetWorkshopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkshopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkshopsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkshopsQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkshopsQuery, GetWorkshopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkshopsQuery, GetWorkshopsQueryVariables>(GetWorkshopsDocument, options);
      }
export function useGetWorkshopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkshopsQuery, GetWorkshopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkshopsQuery, GetWorkshopsQueryVariables>(GetWorkshopsDocument, options);
        }
export function useGetWorkshopsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWorkshopsQuery, GetWorkshopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWorkshopsQuery, GetWorkshopsQueryVariables>(GetWorkshopsDocument, options);
        }
export type GetWorkshopsQueryHookResult = ReturnType<typeof useGetWorkshopsQuery>;
export type GetWorkshopsLazyQueryHookResult = ReturnType<typeof useGetWorkshopsLazyQuery>;
export type GetWorkshopsSuspenseQueryHookResult = ReturnType<typeof useGetWorkshopsSuspenseQuery>;
export type GetWorkshopsQueryResult = Apollo.QueryResult<GetWorkshopsQuery, GetWorkshopsQueryVariables>;
export const GetEventsDocument = gql`
    query GetEvents {
  getEvents {
    amount
    contactName
    contactNumber
    contentDoc
    description
    eventDate
    id
    poster
    title
    unstopLink
    venue
    venueLink
  }
}
    `;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
      }
export function useGetEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
        }
export function useGetEventsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
        }
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsSuspenseQueryHookResult = ReturnType<typeof useGetEventsSuspenseQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
export const GetMyWorkshopIdsDocument = gql`
    query GetMyWorkshopIds {
  getMyWorkshopIds
}
    `;

/**
 * __useGetMyWorkshopIdsQuery__
 *
 * To run a query within a React component, call `useGetMyWorkshopIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyWorkshopIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyWorkshopIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyWorkshopIdsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyWorkshopIdsQuery, GetMyWorkshopIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyWorkshopIdsQuery, GetMyWorkshopIdsQueryVariables>(GetMyWorkshopIdsDocument, options);
      }
export function useGetMyWorkshopIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyWorkshopIdsQuery, GetMyWorkshopIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyWorkshopIdsQuery, GetMyWorkshopIdsQueryVariables>(GetMyWorkshopIdsDocument, options);
        }
export function useGetMyWorkshopIdsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyWorkshopIdsQuery, GetMyWorkshopIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyWorkshopIdsQuery, GetMyWorkshopIdsQueryVariables>(GetMyWorkshopIdsDocument, options);
        }
export type GetMyWorkshopIdsQueryHookResult = ReturnType<typeof useGetMyWorkshopIdsQuery>;
export type GetMyWorkshopIdsLazyQueryHookResult = ReturnType<typeof useGetMyWorkshopIdsLazyQuery>;
export type GetMyWorkshopIdsSuspenseQueryHookResult = ReturnType<typeof useGetMyWorkshopIdsSuspenseQuery>;
export type GetMyWorkshopIdsQueryResult = Apollo.QueryResult<GetMyWorkshopIdsQuery, GetMyWorkshopIdsQueryVariables>;
export const GetUserWorkshopsCountByIdDocument = gql`
    query GetUserWorkshopsCountById($getUserWorkshopsCountByIdId: String!) {
  getUserWorkshopsCountById(id: $getUserWorkshopsCountByIdId)
}
    `;

/**
 * __useGetUserWorkshopsCountByIdQuery__
 *
 * To run a query within a React component, call `useGetUserWorkshopsCountByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWorkshopsCountByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWorkshopsCountByIdQuery({
 *   variables: {
 *      getUserWorkshopsCountByIdId: // value for 'getUserWorkshopsCountByIdId'
 *   },
 * });
 */
export function useGetUserWorkshopsCountByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserWorkshopsCountByIdQuery, GetUserWorkshopsCountByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserWorkshopsCountByIdQuery, GetUserWorkshopsCountByIdQueryVariables>(GetUserWorkshopsCountByIdDocument, options);
      }
export function useGetUserWorkshopsCountByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserWorkshopsCountByIdQuery, GetUserWorkshopsCountByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserWorkshopsCountByIdQuery, GetUserWorkshopsCountByIdQueryVariables>(GetUserWorkshopsCountByIdDocument, options);
        }
export function useGetUserWorkshopsCountByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserWorkshopsCountByIdQuery, GetUserWorkshopsCountByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserWorkshopsCountByIdQuery, GetUserWorkshopsCountByIdQueryVariables>(GetUserWorkshopsCountByIdDocument, options);
        }
export type GetUserWorkshopsCountByIdQueryHookResult = ReturnType<typeof useGetUserWorkshopsCountByIdQuery>;
export type GetUserWorkshopsCountByIdLazyQueryHookResult = ReturnType<typeof useGetUserWorkshopsCountByIdLazyQuery>;
export type GetUserWorkshopsCountByIdSuspenseQueryHookResult = ReturnType<typeof useGetUserWorkshopsCountByIdSuspenseQuery>;
export type GetUserWorkshopsCountByIdQueryResult = Apollo.QueryResult<GetUserWorkshopsCountByIdQuery, GetUserWorkshopsCountByIdQueryVariables>;
export const GetMyPassportDocument = gql`
    query GetMyPassport {
  getMyPassport {
    paid
    passportType
    passportId
  }
}
    `;

/**
 * __useGetMyPassportQuery__
 *
 * To run a query within a React component, call `useGetMyPassportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPassportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPassportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyPassportQuery(baseOptions?: Apollo.QueryHookOptions<GetMyPassportQuery, GetMyPassportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyPassportQuery, GetMyPassportQueryVariables>(GetMyPassportDocument, options);
      }
export function useGetMyPassportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyPassportQuery, GetMyPassportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyPassportQuery, GetMyPassportQueryVariables>(GetMyPassportDocument, options);
        }
export function useGetMyPassportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyPassportQuery, GetMyPassportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyPassportQuery, GetMyPassportQueryVariables>(GetMyPassportDocument, options);
        }
export type GetMyPassportQueryHookResult = ReturnType<typeof useGetMyPassportQuery>;
export type GetMyPassportLazyQueryHookResult = ReturnType<typeof useGetMyPassportLazyQuery>;
export type GetMyPassportSuspenseQueryHookResult = ReturnType<typeof useGetMyPassportSuspenseQuery>;
export type GetMyPassportQueryResult = Apollo.QueryResult<GetMyPassportQuery, GetMyPassportQueryVariables>;
export const GetUserWorkshopsAmountByCombosDocument = gql`
    query GetUserWorkshopsAmountByCombos($noOfWorkshops: Float!) {
  getUserWorkshopsAmountByCombos(noOfWorkshops: $noOfWorkshops)
}
    `;

/**
 * __useGetUserWorkshopsAmountByCombosQuery__
 *
 * To run a query within a React component, call `useGetUserWorkshopsAmountByCombosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWorkshopsAmountByCombosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWorkshopsAmountByCombosQuery({
 *   variables: {
 *      noOfWorkshops: // value for 'noOfWorkshops'
 *   },
 * });
 */
export function useGetUserWorkshopsAmountByCombosQuery(baseOptions: Apollo.QueryHookOptions<GetUserWorkshopsAmountByCombosQuery, GetUserWorkshopsAmountByCombosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserWorkshopsAmountByCombosQuery, GetUserWorkshopsAmountByCombosQueryVariables>(GetUserWorkshopsAmountByCombosDocument, options);
      }
export function useGetUserWorkshopsAmountByCombosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserWorkshopsAmountByCombosQuery, GetUserWorkshopsAmountByCombosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserWorkshopsAmountByCombosQuery, GetUserWorkshopsAmountByCombosQueryVariables>(GetUserWorkshopsAmountByCombosDocument, options);
        }
export function useGetUserWorkshopsAmountByCombosSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserWorkshopsAmountByCombosQuery, GetUserWorkshopsAmountByCombosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserWorkshopsAmountByCombosQuery, GetUserWorkshopsAmountByCombosQueryVariables>(GetUserWorkshopsAmountByCombosDocument, options);
        }
export type GetUserWorkshopsAmountByCombosQueryHookResult = ReturnType<typeof useGetUserWorkshopsAmountByCombosQuery>;
export type GetUserWorkshopsAmountByCombosLazyQueryHookResult = ReturnType<typeof useGetUserWorkshopsAmountByCombosLazyQuery>;
export type GetUserWorkshopsAmountByCombosSuspenseQueryHookResult = ReturnType<typeof useGetUserWorkshopsAmountByCombosSuspenseQuery>;
export type GetUserWorkshopsAmountByCombosQueryResult = Apollo.QueryResult<GetUserWorkshopsAmountByCombosQuery, GetUserWorkshopsAmountByCombosQueryVariables>;
export const GetUserWorkshopsCountByCombosDocument = gql`
    query GetUserWorkshopsCountByCombos($noOfWorkshops: Float!) {
  getUserWorkshopsCountByCombos(noOfWorkshops: $noOfWorkshops)
}
    `;

/**
 * __useGetUserWorkshopsCountByCombosQuery__
 *
 * To run a query within a React component, call `useGetUserWorkshopsCountByCombosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWorkshopsCountByCombosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWorkshopsCountByCombosQuery({
 *   variables: {
 *      noOfWorkshops: // value for 'noOfWorkshops'
 *   },
 * });
 */
export function useGetUserWorkshopsCountByCombosQuery(baseOptions: Apollo.QueryHookOptions<GetUserWorkshopsCountByCombosQuery, GetUserWorkshopsCountByCombosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserWorkshopsCountByCombosQuery, GetUserWorkshopsCountByCombosQueryVariables>(GetUserWorkshopsCountByCombosDocument, options);
      }
export function useGetUserWorkshopsCountByCombosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserWorkshopsCountByCombosQuery, GetUserWorkshopsCountByCombosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserWorkshopsCountByCombosQuery, GetUserWorkshopsCountByCombosQueryVariables>(GetUserWorkshopsCountByCombosDocument, options);
        }
export function useGetUserWorkshopsCountByCombosSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserWorkshopsCountByCombosQuery, GetUserWorkshopsCountByCombosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserWorkshopsCountByCombosQuery, GetUserWorkshopsCountByCombosQueryVariables>(GetUserWorkshopsCountByCombosDocument, options);
        }
export type GetUserWorkshopsCountByCombosQueryHookResult = ReturnType<typeof useGetUserWorkshopsCountByCombosQuery>;
export type GetUserWorkshopsCountByCombosLazyQueryHookResult = ReturnType<typeof useGetUserWorkshopsCountByCombosLazyQuery>;
export type GetUserWorkshopsCountByCombosSuspenseQueryHookResult = ReturnType<typeof useGetUserWorkshopsCountByCombosSuspenseQuery>;
export type GetUserWorkshopsCountByCombosQueryResult = Apollo.QueryResult<GetUserWorkshopsCountByCombosQuery, GetUserWorkshopsCountByCombosQueryVariables>;
export const GetUserWorkshopsByIdDocument = gql`
    query GetUserWorkshopsById($getUserWorkshopsByIdId: String!) {
  getUserWorkshopsById(id: $getUserWorkshopsByIdId) {
    user {
      shaastraID
      name
      email
      mobile
      alternateMobile
      gender
      isIITM
      hostel
      organization
      type
      Class
      course
      specialization
      yearOfGraduation
      profession
      country
    }
    merch
  }
}
    `;

/**
 * __useGetUserWorkshopsByIdQuery__
 *
 * To run a query within a React component, call `useGetUserWorkshopsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWorkshopsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWorkshopsByIdQuery({
 *   variables: {
 *      getUserWorkshopsByIdId: // value for 'getUserWorkshopsByIdId'
 *   },
 * });
 */
export function useGetUserWorkshopsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserWorkshopsByIdQuery, GetUserWorkshopsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserWorkshopsByIdQuery, GetUserWorkshopsByIdQueryVariables>(GetUserWorkshopsByIdDocument, options);
      }
export function useGetUserWorkshopsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserWorkshopsByIdQuery, GetUserWorkshopsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserWorkshopsByIdQuery, GetUserWorkshopsByIdQueryVariables>(GetUserWorkshopsByIdDocument, options);
        }
export function useGetUserWorkshopsByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserWorkshopsByIdQuery, GetUserWorkshopsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserWorkshopsByIdQuery, GetUserWorkshopsByIdQueryVariables>(GetUserWorkshopsByIdDocument, options);
        }
export type GetUserWorkshopsByIdQueryHookResult = ReturnType<typeof useGetUserWorkshopsByIdQuery>;
export type GetUserWorkshopsByIdLazyQueryHookResult = ReturnType<typeof useGetUserWorkshopsByIdLazyQuery>;
export type GetUserWorkshopsByIdSuspenseQueryHookResult = ReturnType<typeof useGetUserWorkshopsByIdSuspenseQuery>;
export type GetUserWorkshopsByIdQueryResult = Apollo.QueryResult<GetUserWorkshopsByIdQuery, GetUserWorkshopsByIdQueryVariables>;
export const GetUserWorkshopsPaidDocument = gql`
    query GetUserWorkshopsPaid {
  getUserWorkshopsPaid {
    amount
    user {
      shaastraID
      name
      email
      mobile
    }
    workshopIds
    merch
  }
}
    `;

/**
 * __useGetUserWorkshopsPaidQuery__
 *
 * To run a query within a React component, call `useGetUserWorkshopsPaidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWorkshopsPaidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWorkshopsPaidQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserWorkshopsPaidQuery(baseOptions?: Apollo.QueryHookOptions<GetUserWorkshopsPaidQuery, GetUserWorkshopsPaidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserWorkshopsPaidQuery, GetUserWorkshopsPaidQueryVariables>(GetUserWorkshopsPaidDocument, options);
      }
export function useGetUserWorkshopsPaidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserWorkshopsPaidQuery, GetUserWorkshopsPaidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserWorkshopsPaidQuery, GetUserWorkshopsPaidQueryVariables>(GetUserWorkshopsPaidDocument, options);
        }
export function useGetUserWorkshopsPaidSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserWorkshopsPaidQuery, GetUserWorkshopsPaidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserWorkshopsPaidQuery, GetUserWorkshopsPaidQueryVariables>(GetUserWorkshopsPaidDocument, options);
        }
export type GetUserWorkshopsPaidQueryHookResult = ReturnType<typeof useGetUserWorkshopsPaidQuery>;
export type GetUserWorkshopsPaidLazyQueryHookResult = ReturnType<typeof useGetUserWorkshopsPaidLazyQuery>;
export type GetUserWorkshopsPaidSuspenseQueryHookResult = ReturnType<typeof useGetUserWorkshopsPaidSuspenseQuery>;
export type GetUserWorkshopsPaidQueryResult = Apollo.QueryResult<GetUserWorkshopsPaidQuery, GetUserWorkshopsPaidQueryVariables>;
export const GetEventSJsDocument = gql`
    query GetEventSJs {
  getEventSJs {
    contactName
    contactNumber
    contentDoc
    description
    eventDate
    id
    poster
    title
    venue
    venueLink
    amount
  }
}
    `;

/**
 * __useGetEventSJsQuery__
 *
 * To run a query within a React component, call `useGetEventSJsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventSJsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventSJsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEventSJsQuery(baseOptions?: Apollo.QueryHookOptions<GetEventSJsQuery, GetEventSJsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventSJsQuery, GetEventSJsQueryVariables>(GetEventSJsDocument, options);
      }
export function useGetEventSJsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventSJsQuery, GetEventSJsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventSJsQuery, GetEventSJsQueryVariables>(GetEventSJsDocument, options);
        }
export function useGetEventSJsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEventSJsQuery, GetEventSJsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEventSJsQuery, GetEventSJsQueryVariables>(GetEventSJsDocument, options);
        }
export type GetEventSJsQueryHookResult = ReturnType<typeof useGetEventSJsQuery>;
export type GetEventSJsLazyQueryHookResult = ReturnType<typeof useGetEventSJsLazyQuery>;
export type GetEventSJsSuspenseQueryHookResult = ReturnType<typeof useGetEventSJsSuspenseQuery>;
export type GetEventSJsQueryResult = Apollo.QueryResult<GetEventSJsQuery, GetEventSJsQueryVariables>;
export const GetMyEventSjIdsDocument = gql`
    query GetMyEventSJIds {
  getMyEventSJIds
}
    `;

/**
 * __useGetMyEventSjIdsQuery__
 *
 * To run a query within a React component, call `useGetMyEventSjIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyEventSjIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyEventSjIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyEventSjIdsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyEventSjIdsQuery, GetMyEventSjIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyEventSjIdsQuery, GetMyEventSjIdsQueryVariables>(GetMyEventSjIdsDocument, options);
      }
export function useGetMyEventSjIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyEventSjIdsQuery, GetMyEventSjIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyEventSjIdsQuery, GetMyEventSjIdsQueryVariables>(GetMyEventSjIdsDocument, options);
        }
export function useGetMyEventSjIdsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyEventSjIdsQuery, GetMyEventSjIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyEventSjIdsQuery, GetMyEventSjIdsQueryVariables>(GetMyEventSjIdsDocument, options);
        }
export type GetMyEventSjIdsQueryHookResult = ReturnType<typeof useGetMyEventSjIdsQuery>;
export type GetMyEventSjIdsLazyQueryHookResult = ReturnType<typeof useGetMyEventSjIdsLazyQuery>;
export type GetMyEventSjIdsSuspenseQueryHookResult = ReturnType<typeof useGetMyEventSjIdsSuspenseQuery>;
export type GetMyEventSjIdsQueryResult = Apollo.QueryResult<GetMyEventSjIdsQuery, GetMyEventSjIdsQueryVariables>;
export const GetMyUserEventSJsCountByIdDocument = gql`
    query GetMyUserEventSJsCountById($getMyUserEventSJsCountByIdId: String!) {
  getMyUserEventSJsCountById(id: $getMyUserEventSJsCountByIdId)
}
    `;

/**
 * __useGetMyUserEventSJsCountByIdQuery__
 *
 * To run a query within a React component, call `useGetMyUserEventSJsCountByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserEventSJsCountByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserEventSJsCountByIdQuery({
 *   variables: {
 *      getMyUserEventSJsCountByIdId: // value for 'getMyUserEventSJsCountByIdId'
 *   },
 * });
 */
export function useGetMyUserEventSJsCountByIdQuery(baseOptions: Apollo.QueryHookOptions<GetMyUserEventSJsCountByIdQuery, GetMyUserEventSJsCountByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyUserEventSJsCountByIdQuery, GetMyUserEventSJsCountByIdQueryVariables>(GetMyUserEventSJsCountByIdDocument, options);
      }
export function useGetMyUserEventSJsCountByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyUserEventSJsCountByIdQuery, GetMyUserEventSJsCountByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyUserEventSJsCountByIdQuery, GetMyUserEventSJsCountByIdQueryVariables>(GetMyUserEventSJsCountByIdDocument, options);
        }
export function useGetMyUserEventSJsCountByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyUserEventSJsCountByIdQuery, GetMyUserEventSJsCountByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyUserEventSJsCountByIdQuery, GetMyUserEventSJsCountByIdQueryVariables>(GetMyUserEventSJsCountByIdDocument, options);
        }
export type GetMyUserEventSJsCountByIdQueryHookResult = ReturnType<typeof useGetMyUserEventSJsCountByIdQuery>;
export type GetMyUserEventSJsCountByIdLazyQueryHookResult = ReturnType<typeof useGetMyUserEventSJsCountByIdLazyQuery>;
export type GetMyUserEventSJsCountByIdSuspenseQueryHookResult = ReturnType<typeof useGetMyUserEventSJsCountByIdSuspenseQuery>;
export type GetMyUserEventSJsCountByIdQueryResult = Apollo.QueryResult<GetMyUserEventSJsCountByIdQuery, GetMyUserEventSJsCountByIdQueryVariables>;
export const GetCiscoAutoDocument = gql`
    query GetCiscoAuto {
  getCiscoAuto {
    autodesk {
      user {
        name
        shaastraID
        email
        mobile
      }
    }
    cisco {
      user {
        name
        shaastraID
        email
        mobile
      }
    }
  }
}
    `;

/**
 * __useGetCiscoAutoQuery__
 *
 * To run a query within a React component, call `useGetCiscoAutoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCiscoAutoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCiscoAutoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCiscoAutoQuery(baseOptions?: Apollo.QueryHookOptions<GetCiscoAutoQuery, GetCiscoAutoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCiscoAutoQuery, GetCiscoAutoQueryVariables>(GetCiscoAutoDocument, options);
      }
export function useGetCiscoAutoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCiscoAutoQuery, GetCiscoAutoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCiscoAutoQuery, GetCiscoAutoQueryVariables>(GetCiscoAutoDocument, options);
        }
export function useGetCiscoAutoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCiscoAutoQuery, GetCiscoAutoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCiscoAutoQuery, GetCiscoAutoQueryVariables>(GetCiscoAutoDocument, options);
        }
export type GetCiscoAutoQueryHookResult = ReturnType<typeof useGetCiscoAutoQuery>;
export type GetCiscoAutoLazyQueryHookResult = ReturnType<typeof useGetCiscoAutoLazyQuery>;
export type GetCiscoAutoSuspenseQueryHookResult = ReturnType<typeof useGetCiscoAutoSuspenseQuery>;
export type GetCiscoAutoQueryResult = Apollo.QueryResult<GetCiscoAutoQuery, GetCiscoAutoQueryVariables>;
export const GetAccommodationsDocument = gql`
    query GetAccommodations {
  getAccommodations {
    id
    accoId
    email
    name
    phone
    address
    dob
    organization
    amount
    personalId
    personalIdType
    noOfDays
    accomodationFrom
    accomodationTo
    noOfMen
    noOfWomen
  }
}
    `;

/**
 * __useGetAccommodationsQuery__
 *
 * To run a query within a React component, call `useGetAccommodationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccommodationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccommodationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccommodationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccommodationsQuery, GetAccommodationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccommodationsQuery, GetAccommodationsQueryVariables>(GetAccommodationsDocument, options);
      }
export function useGetAccommodationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccommodationsQuery, GetAccommodationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccommodationsQuery, GetAccommodationsQueryVariables>(GetAccommodationsDocument, options);
        }
export function useGetAccommodationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAccommodationsQuery, GetAccommodationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAccommodationsQuery, GetAccommodationsQueryVariables>(GetAccommodationsDocument, options);
        }
export type GetAccommodationsQueryHookResult = ReturnType<typeof useGetAccommodationsQuery>;
export type GetAccommodationsLazyQueryHookResult = ReturnType<typeof useGetAccommodationsLazyQuery>;
export type GetAccommodationsSuspenseQueryHookResult = ReturnType<typeof useGetAccommodationsSuspenseQuery>;
export type GetAccommodationsQueryResult = Apollo.QueryResult<GetAccommodationsQuery, GetAccommodationsQueryVariables>;
export const GetPassportsDocument = gql`
    query GetPassports {
  getPassports {
    passportId
    amount
    passportType
    user {
      email
      name
      mobile
      shaastraID
    }
  }
}
    `;

/**
 * __useGetPassportsQuery__
 *
 * To run a query within a React component, call `useGetPassportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPassportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPassportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPassportsQuery(baseOptions?: Apollo.QueryHookOptions<GetPassportsQuery, GetPassportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPassportsQuery, GetPassportsQueryVariables>(GetPassportsDocument, options);
      }
export function useGetPassportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPassportsQuery, GetPassportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPassportsQuery, GetPassportsQueryVariables>(GetPassportsDocument, options);
        }
export function useGetPassportsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPassportsQuery, GetPassportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPassportsQuery, GetPassportsQueryVariables>(GetPassportsDocument, options);
        }
export type GetPassportsQueryHookResult = ReturnType<typeof useGetPassportsQuery>;
export type GetPassportsLazyQueryHookResult = ReturnType<typeof useGetPassportsLazyQuery>;
export type GetPassportsSuspenseQueryHookResult = ReturnType<typeof useGetPassportsSuspenseQuery>;
export type GetPassportsQueryResult = Apollo.QueryResult<GetPassportsQuery, GetPassportsQueryVariables>;
export const GetSlotsDocument = gql`
    query GetSlots {
  getSlots {
    id
    timeRange
    date
    noOfPeople
  }
}
    `;

/**
 * __useGetSlotsQuery__
 *
 * To run a query within a React component, call `useGetSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSlotsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSlotsQuery(baseOptions?: Apollo.QueryHookOptions<GetSlotsQuery, GetSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSlotsQuery, GetSlotsQueryVariables>(GetSlotsDocument, options);
      }
export function useGetSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSlotsQuery, GetSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSlotsQuery, GetSlotsQueryVariables>(GetSlotsDocument, options);
        }
export function useGetSlotsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSlotsQuery, GetSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSlotsQuery, GetSlotsQueryVariables>(GetSlotsDocument, options);
        }
export type GetSlotsQueryHookResult = ReturnType<typeof useGetSlotsQuery>;
export type GetSlotsLazyQueryHookResult = ReturnType<typeof useGetSlotsLazyQuery>;
export type GetSlotsSuspenseQueryHookResult = ReturnType<typeof useGetSlotsSuspenseQuery>;
export type GetSlotsQueryResult = Apollo.QueryResult<GetSlotsQuery, GetSlotsQueryVariables>;
export const GetMyUserSlotDocument = gql`
    query GetMyUserSlot {
  getMyUserSlot {
    noOfPeople
    slot {
      date
      timeRange
    }
  }
}
    `;

/**
 * __useGetMyUserSlotQuery__
 *
 * To run a query within a React component, call `useGetMyUserSlotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserSlotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserSlotQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserSlotQuery(baseOptions?: Apollo.QueryHookOptions<GetMyUserSlotQuery, GetMyUserSlotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyUserSlotQuery, GetMyUserSlotQueryVariables>(GetMyUserSlotDocument, options);
      }
export function useGetMyUserSlotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyUserSlotQuery, GetMyUserSlotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyUserSlotQuery, GetMyUserSlotQueryVariables>(GetMyUserSlotDocument, options);
        }
export function useGetMyUserSlotSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyUserSlotQuery, GetMyUserSlotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyUserSlotQuery, GetMyUserSlotQueryVariables>(GetMyUserSlotDocument, options);
        }
export type GetMyUserSlotQueryHookResult = ReturnType<typeof useGetMyUserSlotQuery>;
export type GetMyUserSlotLazyQueryHookResult = ReturnType<typeof useGetMyUserSlotLazyQuery>;
export type GetMyUserSlotSuspenseQueryHookResult = ReturnType<typeof useGetMyUserSlotSuspenseQuery>;
export type GetMyUserSlotQueryResult = Apollo.QueryResult<GetMyUserSlotQuery, GetMyUserSlotQueryVariables>;
export const GetUserSlotsDocument = gql`
    query GetUserSlots {
  getUserSlots {
    user {
      shaastraID
      name
      mobile
      email
    }
    slot {
      date
      timeRange
    }
  }
}
    `;

/**
 * __useGetUserSlotsQuery__
 *
 * To run a query within a React component, call `useGetUserSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSlotsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserSlotsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserSlotsQuery, GetUserSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserSlotsQuery, GetUserSlotsQueryVariables>(GetUserSlotsDocument, options);
      }
export function useGetUserSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSlotsQuery, GetUserSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserSlotsQuery, GetUserSlotsQueryVariables>(GetUserSlotsDocument, options);
        }
export function useGetUserSlotsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserSlotsQuery, GetUserSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserSlotsQuery, GetUserSlotsQueryVariables>(GetUserSlotsDocument, options);
        }
export type GetUserSlotsQueryHookResult = ReturnType<typeof useGetUserSlotsQuery>;
export type GetUserSlotsLazyQueryHookResult = ReturnType<typeof useGetUserSlotsLazyQuery>;
export type GetUserSlotsSuspenseQueryHookResult = ReturnType<typeof useGetUserSlotsSuspenseQuery>;
export type GetUserSlotsQueryResult = Apollo.QueryResult<GetUserSlotsQuery, GetUserSlotsQueryVariables>;
export const GetOhUsersDocument = gql`
    query GetOHUsers {
  getOHUsers {
    shaastraID
    email
    name
    mobile
    noOfPeople
    personalId
    organization
    userSlots {
      slot {
        date
        timeRange
      }
    }
  }
}
    `;

/**
 * __useGetOhUsersQuery__
 *
 * To run a query within a React component, call `useGetOhUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOhUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOhUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOhUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetOhUsersQuery, GetOhUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOhUsersQuery, GetOhUsersQueryVariables>(GetOhUsersDocument, options);
      }
export function useGetOhUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOhUsersQuery, GetOhUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOhUsersQuery, GetOhUsersQueryVariables>(GetOhUsersDocument, options);
        }
export function useGetOhUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOhUsersQuery, GetOhUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOhUsersQuery, GetOhUsersQueryVariables>(GetOhUsersDocument, options);
        }
export type GetOhUsersQueryHookResult = ReturnType<typeof useGetOhUsersQuery>;
export type GetOhUsersLazyQueryHookResult = ReturnType<typeof useGetOhUsersLazyQuery>;
export type GetOhUsersSuspenseQueryHookResult = ReturnType<typeof useGetOhUsersSuspenseQuery>;
export type GetOhUsersQueryResult = Apollo.QueryResult<GetOhUsersQuery, GetOhUsersQueryVariables>;