import React from "react";
import "./exhibitions.scss";
import ExhiCard from "../../../ui-elements/exhi-cards/exhiCard";
import { currentExhibitions, pastExhibitions } from "../../../utils/Helpers";
import { RootState } from "../../../redux/reducers";
import { useSelector } from "react-redux";
import Navbar from "../../../ui-elements/navbar/navbar";
import GlitchTitle from "../../../animations/glitchTitle/glitchTitle";

type ExhibitionsProps = {};

const Exhibitions: React.FC<ExhibitionsProps> = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);

  function ScrollFunction(elem: string) {
    const element = document.getElementById(elem);
    element!.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className="exhibitions-wrapper">
      <div className={`Exhibitions-page ${device}`} id="Exhibitions">
        <Navbar page="Exhibitions" />
        {/* <video autoPlay loop muted id="exhibition-video">
                <source src={backgroundvideo} type="video/mp4" />
            </video> */}
        <div className={`area ${device}`}>
          <ul className={`circles ${device}`}>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div className={`exhi-body ${device}`}>
          <GlitchTitle title="Exhibitions" />
          <div className={`exhi-para1 ${device}`}>
            Shaastra Exhibitions is a platform to showcase cutting-edge
            technology. Exhibitions provide an opportunity for a large number of
            buyers and sellers in an industry to interact with each other. They
            offer an unparalleled and ideal opportunity to showcase innovative
            products to a broad spectrum of people.
          </div>
          <div className={`exhi-buttons ${device}`}>
            {currentExhibitions.length > 0 && (
              <div
                className={`exhi-button1 ${device}`}
                onClick={() => ScrollFunction("CurrentBlock")}
              >
                Current Exhibitions
              </div>
            )}
            <div
              className={`exhi-button1 ${device}`}
              onClick={() => ScrollFunction("PastBlock")}
            >
              Past Exhibitions
            </div>
          </div>
        </div>
        {currentExhibitions.length > 0 && (
          <div className={`ExhiBlock ${device}`}>
            <div className={`ExhiBlockTitle ${device}`} id="CurrentBlock">
              Current Exhibitions
            </div>
            <div className={`currentExhiCards ${device}`}>
              {currentExhibitions.map((exhibition, index) => (
                <ExhiCard
                  exhibition={exhibition}
                  index={index}
                  past={false}
                ></ExhiCard>
              ))}
            </div>
          </div>
        )}
        <div className={`ExhiBlock ${device}`}>
          <div className={`ExhiBlockTitle ${device}`} id="PastBlock">
            Past Exhibitions
          </div>
          <div className={`currentExhiCards ${device}`}>
            {pastExhibitions.map((exhibition, index) => (
              <ExhiCard
                exhibition={exhibition}
                index={index}
                past={true}
              ></ExhiCard>
            ))}
          </div>
        </div>
        {/* <div className='dcontainer'>
                <div className='containerTitle'>Vintage Shaastra</div>
                <div className='mcontainer'>
                    <img className='containerImg' src={img}/>
                    <div className='aboutContainer'>Shaastra Exhibitions is a platforlksamlvm to lkamsmflksm showcase cutting-ksfgledge technology.vidlsalfvlsfmve an opportunity for a large number of laslbuyers and sellers in an industry to inte ract with each other. They offer an unparallele d and ideal o to showcase innovative products</div>
                </div>
            </div> */}
        {/* <ExhiContainer title="Vintage Shaastra" image={img} desc="Shaastra Exhibitions is a platforlksamlvm to lkamsmflksm showcase cutting-ksfgledge technology.vidlsalfvlsfmve an opportunity for a large number of laslbuyers and sellers in an industry to inte ract with each other. They offer an unparallele d and ideal o to showcase innovative products"/> */}
      </div>
    </div>
  );
};

export default Exhibitions;
