import React from "react";
import EnvisageSection from "./envisageSection/envisageSection";
// import demo from "../../../assets/images/Envisage/techAmbience/demo.jpg";
// import demo2 from "../../../assets/images/Envisage/techAmbience/demo2.jpg";
// import demo3 from "../../../assets/images/Envisage/techAmbience/demo3.jpg";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import Navbar from "../../../ui-elements/navbar/navbar";
import GlitchTitle from "../../../animations/glitchTitle/glitchTitle";

type EnvisageProps = {};

const Envisage: React.FC<EnvisageProps> = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);

  const gameDevProjects = [
    {
      // image: demo3,
      heading: "Echoes of Darknes",
      para: "Dive into the captivating world of game development with 'Echoes of Darkness.' Follow the courageous journey of a young hero who, despite battling severe eyesight impairments from birth, embarks on a daring quest to infiltrate a mysterious dungeon in search of a life-saving cure. Along the way, players will rely on their acute sense of hearing and heightened senses to navigate the treacherous path, listening for guard patrols, mastering lock-picking, and deciphering intricate door patterns. To succeed, they must also decode transmissions to unlock vaults and collect vital components of the cure. Immerse yourself in this extraordinary adventure, where darkness becomes your ally, on this website.",
    },
    {
      // image: demo2,
      heading: "One day",
      para: "Step into a surreal time loop in 'One Day,' where the ordinary becomes extraordinary. Experience the relentless repetition of just another day, with subtle variations that beguile the senses. Will you take control of the loop or allow it to control you? The choice is yours. 'One Day' invites you to explore a unique blend of Unity, Blender, and a deranged imagination, fusing creative innovation with a mind-bending narrative. Break free from the loop, challenge the boundaries of reality, and unravel the mysteries that lie within 'One Day.' Join us on this website and embark on an unforgettable journey where every day is a new adventure",
    },
    {
      // image: demo3,
      heading: "Break a Leg(s)!",
      para: "Get ready to break a leg(s)! Meet Impi and Aer, two friends who wake up one day to discover they've lost control over their own bodies. Instead, they find themselves sharing control over each other's bodies! In this unique and hilarious game, your controller is divided, with one part governing your character and the other part controlling your friend's character. It's a test of coordination, communication, and possibly chaos as you work together (or conspire) to escape the bizarre confines of the room where you're inexplicably trapped. Join us on this website and embark on a comical and challenging adventure that's sure to test your friendships and reflexes!",
    },
  ];

  const techAmbiProjects = [
    {
      // image: demo,
      heading: "Vanguard",
      para: "Experience the ultimate fusion of technology and imagination with Project Vanguard. Step into Tony Stark's shoes and bring Iron Man's iconic tech to life. We're not just talking about a fancy costume – we're crafting a cutting-edge exosuit that's as close to reality as it gets. Picture sleek, responsive armor, intuitive controls, and yes, the power of flight. Our innovative use of smart materials and ingenious design ensures a lightweight yet robust suit that amplifies your movements. That iconic glowing chest piece isn't just cosmetic; we've harnessed compact power systems to keep you going. Plus, we've added intuitive, responsive controls that react to your every move and even your voice. It's like science fiction brought to life! Project Vanguard is your gateway to bridging the Marvel universe with the real world. Join us in this exciting venture, where we're making superhero dreams a reality. With expertise in Fusion 360, Blender, electronics, and software, we're bringing innovation to life on this website.",
    },
    {
      // image: demo2,
      heading: "Swift Canvas",
      para: "Swift Canvas redefines the creative process with algorithms that rapidly produce stunning artwork through digital means. Our intelligent bots range from crafting abstract designs to emulating specific artistic styles, all with unmatched speed and precision. Meet the upgraded XY plotter, designed to replicate the organic beauty of hand-drawn strokes. This cutting-edge project is committed to enhancing the painting experience with an array of brushes, various sizes, and models, along with a diverse palette of colors. The result? A crystal-clear, highly efficient image output. With expertise in electronics and software, Swift Canvas is your gateway to a world of artistic innovation. Join us on this website and explore the future of digital art creation.",
    },
    {
      // image: demo2,
      heading: "Pet-a-mech",
      para: "Introducing Pet-a-Mech, your gateway to a world of robotic companionship. Also known as robot pets, these ingenious mechanical and electronic devices have been carefully crafted to mimic the endearing behaviors of real animals, providing companionship, entertainment, and even therapeutic benefits. For those unable to have traditional pets due to allergies, living restrictions, or other constraints, Pet-a-Mech is the answer. Our latest creation, a remarkable replica of the beloved BB8, promises excitement and innovation. With expertise in mechanical design, Fusion 360, electronics, and software, we're pioneering the future of robotic companions. Join us on this website and embrace a new era of companionship and delight",
    },
  ];

  const showsProjects = [
    {
      heading: "ReflectoSpectra",
      para: "Explore the world of laser projection mapping with our mirror matrix system, creating captivating laser patterns.",
    },
    {
      heading: "Rubik Artistry",
      para: "Witness the fusion of technology and art with our Rubik's Cube Mosaic Automator, crafting intricate designs from Rubik's Cubes.",
    },
    {
      heading: "Automode Gala",
      para: "Experience the future of fashion with a robotic fashion show, featuring garments infused with cutting-edge tech.",
    },
    {
      heading: "Flash Wave",
      para: "Engage audiences with an interactive light show through synchronized smartphone screens, uniting technology and art for a mesmerizing experience",
    },
  ];

  return (
    <div className="envisage-page" id="envisage">
      <Navbar page="Envisage" />

      <div className="envisage-content">
        <div className="title-wrapper">
            <GlitchTitle title="Envisage" />
        </div>
        <EnvisageSection
          time="3rd Jan"
          venue="CLT"
          desc="Embark on a journey through a world of captivating projects that shine brightly on our website. Here, you'll discover a diverse array of exhibits, from the interactive wonder of Automode Gala to the mesmerizing Rubik's Artistry experiences, as well as mind-bending virtual reality demonstrations. Immerse yourself in the captivating world of ReflectoSpectra light shows, where interactive installations and awe-inspiring audiovisual performances await. Be sure to check out Flash Wave, an engaging light show that unites technology and art through synchronized smartphone screens, delivering a mesmerizing experience. Join us to unravel the mysteries of scientific phenomena with enlightening experiments, captivating physics displays, and engaging chemistry showcases. Witness the magic of knowledge and innovation right here!"
          projectImages={showsProjects}
          title="Shows"
        />
        <EnvisageSection
          time="3rd Jan"
          venue="CLT"
          desc=""
          projectImages={gameDevProjects}
          title="Game Dev"
        />
        <EnvisageSection
          time=""
          venue=""
          desc=""
          projectImages={techAmbiProjects}
          title="Tech Ambience"
        />
      </div>
    </div>
  );
};

export default Envisage;
