import "./SpotLight.scss";
import Banner from "../../../ui-elements/Banner/Banner";
import React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import Navbar from "../../../ui-elements/navbar/navbar";
import GlitchTitle from "../../../animations/glitchTitle/glitchTitle";

// import current speakers imgs
import ThiruPalanivelThiagaRajan from "../../../assets/images/spotlightSpeakers/Thiru Palanivel Thiaga Rajan.jpg";
import RohiniGodbole from "../../../assets/images/spotlightSpeakers/rohiniGodbole.png";
import KiranBedi from "../../../assets/images/spotlightSpeakers/kiranBedi.jpeg";
import RamNathKovind from "../../../assets/images/spotlightSpeakers/ramNathKovind.jpeg";
import UllasKaranth from "../../../assets/images/spotlightSpeakers/Ullas Karanth _Ramnath Chandrashekar photo.jpg";
import SanghamitraBandhopadhyay from "../../../assets/images/spotlightSpeakers/Sanghamitra Bandyopadhyay.jpeg";
import ManMohanSharma from "../../../assets/images/spotlightSpeakers/Man mohan Sharma.jpeg";
import Mr_R_G_Chandramogan from "../../../assets/images/spotlightSpeakers/R G Chandramogan.webp";
import Dr_V_Adimurthy from "../../../assets/images/spotlightSpeakers/V Adimurthy.jpeg";
import Mr_Punith_Nanda_Kumar from "../../../assets/images/spotlightSpeakers/Punith Nanda Kumar.jpeg";
import Dr_Deepak_Dhar from "../../../assets/images/spotlightSpeakers/Deepak Dhar.jpg";
import ComingSoon from "../../../assets/images/spotlightSpeakers/comingSoon.jpg";
import { SpotlightData } from "../../../types/spotlightData";

const SpotLight: React.FC = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);

  const currSpotLightData: SpotlightData[] = [
    {
      name: "Thiru Palanivel Thiaga Rajan",
      designation: "Honorable Minister for IT & Digital Services of Tamil Nadu",
      spotlightImage: ThiruPalanivelThiagaRajan,
      date: "4th Jan",
      time: "4:00PM - 5:00PM",
      venue: "CLT",
    },
    {
      name: "Dr Rohini Godbole",
      designation: "Physicist, Padma Shri Awardee",
      spotlightImage: RohiniGodbole,
      date: "6th Jan",
      time: "2:00PM - 3:00PM",
      venue: "CLT",
    },
    {
      name: "Dr Kiran Bedi",
      designation: "Former Lieutenant-Governor of Puducherry",
      spotlightImage: KiranBedi,
      date: "5th Jan",
      time: "4:00PM - 5:00PM",
      venue: "CLT",
    },
    {
      name: "Shri Ram Nath Kovind",
      designation: "Hon’ble Former President of India",
      spotlightImage: RamNathKovind,
      date: "4th Jan",
      time: "11:15AM - 12:30PM",
      venue: "CLT",
    },
    {
      name: "Sanghamitra Bandhopadhyay",
      designation: "Director of ISI Kolkata, Padma Shri Awardee",
      spotlightImage: SanghamitraBandhopadhyay,
      date: "7th Jan",
      time: "11:00AM - 12:00PM",
      venue: "CLT",
    },
    {
      name: "Man Mohan Sharma",
      designation: "Padma Vibhushan Awardee, Ex Director of UDCT",
      spotlightImage: ManMohanSharma,
      date: "5th Jan",
      time: "12:00PM - 1:00PM",
      venue: "CLT",
    },
    {
      name: "Ullas Karanth",
      designation: "Director of Centre for Wildlife Studies, Padma Shri Awardee",
      spotlightImage: UllasKaranth,
      date: "6th Jan",
      time: "11:00AM - 12:00PM",
      venue: "CLT",
    },
    {
      name: "Mr R. G. Chandramogan",
      designation: "Founder of Hatsun Agro",
      spotlightImage: Mr_R_G_Chandramogan,
      date: "7th Jan",
      time: "4:00PM - 5:00PM",
      venue: "CLT",
    },
    {
      name: "Dr V. Adimurthy",
      designation: " ISRO Honorary Distinguished Professor, Padma Shri Awardee",
      spotlightImage: Dr_V_Adimurthy,
      date: "3rd Jan",
      time: "11:00AM - 12:00PM",
      venue: "CLT",
    },
    {
      name: "Mr Punith Nanda Kumar",
      designation: " Paraolympic Swimmer",
      spotlightImage: Mr_Punith_Nanda_Kumar,
      date: "3rd Jan",
      time: "3:00PM - 4:00PM",
      venue: "CLT",
    },
    {
      name: "Dr Deepak Dhar",
      designation: "Boltzmann Medal and Padma Bhushan Awardee",
      spotlightImage: Dr_Deepak_Dhar,
      date: "5th Jan",
      time: "2:00PM - 3:00PM",
      venue: "CLT",
    },
    { name: "Coming soon...", designation: "", spotlightImage: ComingSoon },
  ];

  const pastSpotLightData: SpotlightData[] = [
    {
      name: "Mr. Vetri maaran",
      designation: "National Award winning Director Producer and Writer",
      spotlightImage:
        "https://drive.google.com/uc?export=view&id=1R0X5HX9bUs2wSrU2m-sXOPV9tsJ3c37T",
    },
    {
      name: "Dr. Rajagopala Chidambaram",
      designation:
        "Padma Vibhushan Former Principal Scientific Advisor to the Government of India",
      spotlightImage:
        "https://drive.google.com/uc?export=view&id=1yGmTHzCBUX3w4zTD4A0GTTU6di0Mbcgv",
    },
    {
      name: "Dr. Montek Singh Ahluwalia",
      designation:
        "Former Deputy Chairman Planning Commision, Former Director of the Independent Evaluation Office at IMF",
      spotlightImage:
        "https://drive.google.com/uc?export=view&id=1d4eDdKTTXPsKXZYbbaCR0C64Yly7lyor",
    },
    {
      name: "Mrs. Vineeta Singh",
      designation: "CEO, Sugar Cosmetics, Judge at Shark Tank",
      spotlightImage:
        "https://drive.google.com/uc?export=view&id=1M4W4fickDXaeapJuDFl77xhgU-QwbN_T",
    },
    {
      name: "Thiru R. N.Ravi",
      designation: "Governor of Tamil Nadu",
      spotlightImage:
        "https://drive.google.com/uc?export=view&id=1PtUwa1-MC9yrBhBdd04_1rKkRRHFeyDQ",
    },
    {
      name: "Dr. M.S. Valiathan",
      designation:
        "Padma Vibhushan, Eminent Cardiac Surgeon, Inventor-Chitra Heart Valve",
      spotlightImage:
        "https://drive.google.com/uc?export=view&id=1dgk1D95LRIzlhtLrNf_dcDM58IJdje6q",
    },
    {
      name: "Dr. A. Sivathanu Pillai",
      designation: "Padma Bhushan ,Founder-CEO of BrahMos Aerospace",
      spotlightImage:
        "https://drive.google.com/uc?export=view&id=1ysnRPiUdVRwxlG4UpxKB5yhjXF1nffAt",
    },
    {
      name: "Mr. Srinivas Mohan",
      designation: "VFX Designer for RRR and Baahubali",
      spotlightImage:
        "https://drive.google.com/uc?export=view&id=1VczEfFTm7v-zECUAC1mRAKjyA9_f2qZM",
    },
  ];

  return (
    <div className={`spotLight_page ${device}`}>
      <Navbar page="Spotlight" />
      <div className={`background ${device}`}>
        <div className={`bg_img  ${device} `}></div>
        <div className={`spotlight-container ${device}`}>
          <GlitchTitle title="Spotlight" />
          <div className={`spotlight-paragraph ${device}`}>
            Spanning Science, Technology, Art, Entertainment & more, Spotlight
            is the flagship lecture series of Shaastra which brings visionaries
            from across the world, creating a unique platform for learning and
            interaction with pioneers who have transformed the world in
            remarkable ways. Spotlight stands for the convergence of brilliance,
            love of knowledge and the very spirit of engineering that Shaastra
            embodies.
          </div>
        </div>
      </div>

      <h1> CURRENT SPEAKERS</h1>

      <div className={`${device}`} id="line">
        {/* <div id="line-progress" style={{ width: ${(activeStep - 1) * 33.3}% }}></div> */}
        {/* <div className={`line-end-pntLeft ${device}`}></div>
        <div className={`line-end-pntRight ${device}`}></div> */}
      </div>

      <div className="bottom-wrapper">
        <Swiper
          slidesPerView={
            device === "mobile" || device === "tab"
              ? 1
              : device === "notebook"
              ? 1
              : 3
          }
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          {currSpotLightData.map((item) => (
            <SwiperSlide>
              <Banner spotlightData={item} />
            </SwiperSlide>
          ))}
          {/* <SwiperSlide>
          <Banner
            Heading={"Mr. Vetri Maaran"}
            Content={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}
            image={ speaker1}
          />
        </SwiperSlide> */}
        </Swiper>
      </div>

      <h1>PAST SPEAKERS</h1>

      <div className={`${device}`} id="line">
        {/* <div id="line-progress" style={{ width: ${(activeStep - 1) * 33.3}% }}></div> */}
        {/* <div className={`line-end-pntLeft ${device}`}></div>
        <div className={`line-end-pntRight ${device}`}></div> */}
      </div>

      <div className="bottom-wrapper">
        <Swiper
          slidesPerView={
            device === "mobile" || device === "tab"
              ? 1
              : device === "notebook"
              ? 1
              : 3
          }
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          {pastSpotLightData.map((item) => (
            <SwiperSlide>
              <Banner spotlightData={item} />
            </SwiperSlide>
          ))}
          {/* <SwiperSlide>
          <Banner
            Heading={"Mr. Vetri Maaran"}
            Content={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}
            image={ speaker1}
          />
        </SwiperSlide> */}
        </Swiper>
      </div>
    </div>
  );
};

export default SpotLight;
