import React from 'react';
import './ruleContainer.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/reducers';

type RuleContainerProps = {
    rule: string;
    index: number;
};

const RuleContainer:React.FC<RuleContainerProps> = ({ rule, index }) => {
    const { device } = useSelector((state: RootState) => state.windowSize);
    
    return (
        <div className={`rule-container ${index % 2 === 0 ? null : "right"} ${device}`}>
            <div className={`line ${index % 2 === 0 ? null : "right"} ${device}`}></div>
            <div className={`rule-no ${index % 2 === 0 ? null : "right"} ${device}`}>{index + 1}</div>
            <div className={`rule-desc ${index % 2 === 0 ? null : "right"} ${device}`}>{rule}</div>
        </div>
    )
}
export default RuleContainer;