import React, { useContext, useEffect, useState } from "react";
import "./stats.scss";
import { useSelector } from "react-redux";
import { AuthContext } from "../../../utils/authProvider";
import { RootState } from "../../../redux/reducers";
import GlitchTitle from "../../../animations/glitchTitle/glitchTitle";
import { CSVLink } from "react-csv";
import { GetOhUsersQuery, useGetOhUsersQuery } from "../../../generated/graphql";

type OHStatsProps = {};

type SearchField = "mobile" | "email" | "shaastraID" | "name";

const OpenHouseStats: React.FC<OHStatsProps> = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);

const [ohData, setOhData] = useState<GetOhUsersQuery>();
const [searchField, setSearchField] = useState<SearchField>("email");
const [total, setTotal] = useState(0);

  const {
    data,
    refetch
  } = useGetOhUsersQuery();

  useEffect(() => {
    if(data) {
        setOhData(data)
        setTotal(data.getOHUsers.reduce((a,b) => a + (b.noOfPeople  || 0) + 1, 0))
    }
  }, [data]);

  const [search, setSearch] = useState("");

  console.log(data)

  return (
    <div className="stats-page">
      <GlitchTitle title="Open House Stats" />

<div className="inputs">
  <div className="data-wrapper">
      {/* <button onClick={onSubmit} >Refetch New Passport</button> */}
      <div>Total No: of Registrations: {data?.getOHUsers.length}</div>
      <div>Total No: of People: {total}</div>
      <CSVLink filename={`OHstats.csv`} data={data?.getOHUsers ? data.getOHUsers.map(item => {
        if(item && item.userSlots?.slot) return {...item, date: item.userSlots?.slot.date, time: item.userSlots?.slot.timeRange}
        else return {...item}
      }) : []}>Download CSV</CSVLink>
      <div>
    <select defaultValue={"email"} onChange={(e) => setSearchField(e.target.value as SearchField)}>
      <option value="shaastraID">shaastraID</option>
      <option value="name">name</option>
      <option value="email">email</option>
      <option value="mobile">mobile</option>
    </select>
      <input onChange={(e) => setSearch(e.target.value)} placeholder={"Enter " + searchField}></input>
      </div>
  </div>

</div>

      <table>
        <tr>
          <td>ShaastraID</td>
          <td>Name</td>
          <td>Email</td>
          <td>Mobile</td>
          <td>No: of People</td>
          <td>Personal ID</td>
        </tr>
        {ohData?.getOHUsers.filter(item => {
            return item[searchField].toLowerCase().includes(search.toLowerCase())
        }
            ).map((item) => {
          return (
            <tr>
              <td>{item.shaastraID}</td>
              <td>{item.name}</td>
              <td>{item.email}</td>
              <td>{item.mobile}</td>
              <td>{item.noOfPeople}</td>
              <td><a href={item.personalId!} target="_blank">{item.personalId}</a></td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};
export default OpenHouseStats;
