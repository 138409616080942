import React, { useContext, useEffect, useState } from "react";
import "./eventDetails.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { useNavigate, useParams } from "react-router-dom";
// import workshop_poster from "../../../../assets/images/event-workshop.png";
import btnArrowImg from "../../../../assets/images/workshop-btn-arrow.svg";
import rsImg from "../../../../assets/images/workshop-rs.svg";
import WorkshopRules from "../Rules/workshopRules";
import Navbar from "../../../../ui-elements/navbar/navbar";
import { useEventList } from "../../../../utils/events";
import { AuthContext } from "../../../../utils/authProvider";
import timeIcon from "../../../../assets/icons/timeIcon.svg";
import locationIcon from "../../../../assets/icons/locationIcon.svg";
import fileIcon from "../../../../assets/icons/fileIcon.svg";
import phoneIcon from "../../../../assets/icons/phoneIcon.svg";
import downArrow from "../../../../assets/icons/downArrow.svg";
import Faq from "./faq/faq";
import { FAQ } from "../../../../types/faq";
import {
  UserRole,
  useGetUserWorkshopsCountByIdQuery,
} from "../../../../generated/graphql";

type EventDetailsProps = {};

const EventDetails: React.FC<EventDetailsProps> = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);

  const [noOfRegs, setNoOfRegs] = useState(0);

  const { id } = useParams();

  const { user } = useContext(AuthContext);

  const { eventList } = useEventList();

  let event = eventList.find(ev => ev.id.slice(-5) === id);

  // const { data, loading, error, refetch } = useGetUserWorkshopsCountByIdQuery({variables: {getUserWorkshopsCountByIdId: ""}, skip: true})

  // useEffect(() => {
  //   if(event) {
  //     const func = async () => {
  //       const {data} = await refetch({getUserWorkshopsCountByIdId: event.id})
  //       setNoOfRegs(data.getUserWorkshopsCountById)
  //     }
  //     try {
  //       func()
  //     } catch(err) {
  //       console.log(err)
  //     }
  //   }
  // }, [event]);

  const navigate = useNavigate();

  const [showFAQs, setShowFAQs] = useState<boolean>(false);

  const faqs: FAQ[] = [
    {
      ques: "Will the workshop be offline or online?",
      ans: "Both the workshop sessions will be held offline at IIT Madras campus",
    },
    {
      ques: "Will I receive a certificate after the workshop?",
      ans: "Yes, all registered participants will receive a certificate from IIT Madras on completion of both sessions of the workshop",
    },
    {
      ques: "Do I need to carry a laptop for the workshop?",
      ans: "Kindly check the content doc of the respective workshop to know about any prerequisites",
    },
    {
      ques: "How do I register for multiple workshops?",
      ans: "Kindly check the guidelines to know how to avail our combo offers",
    },
  ];

  return (
    <>
      <Navbar page="Workshops" />

      <div className={`details-container ${device}`}>
        {event ? (
          <div className={`wrapper ${device}`}>
            <div className={`left-container ${device}`}>
              <div className={`about-workshop ${device}`}>
                <div className={`title ${device}`}>{event!.title}</div>
                <div className={`desc ${device}`}>{event!.description}</div>
              </div>
              <div className="phone-doc-wrapper">
                {event.contentDoc && (
                  <div
                    className="phone-doc content-doc"
                    onClick={() => window.open(event?.contentDoc || "")}
                  >
                    <img src={fileIcon} alt="Time Icon" height={"20px"} />
                    <span>Problem Statement</span>
                  </div>
                )}
                {event.contactNumber && (
                  <div className="phone-doc">
                    <img src={phoneIcon} alt="Time Icon" height={"20px"} />
                    <div>
                    <span>{event.contactName}</span><br />
                    <span>{event.contactNumber}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="event-details">
                <div className="date-month">
                  <div className="month">{event.eventDate?.toLocaleString('default', {month: 'short'})}</div>
                  <div className="date">{event.eventDate?.getDate()}</div>
                </div>
                <div className="time-venue-wrapper">
                  {/* <div className="closing">
                    Registration closes on Jan{" "}
                    {(event.eventDate?.getDate() || 3) - 1}, 11:59 PM
                  </div> */}
                  <div className={`time-venue ${device}`}>
                    <div className="time">
                      <img src={timeIcon} alt="Time Icon" height={"30px"} />
                      <div className="times">
                        <div>{event.eventDate?.toLocaleTimeString('en-IN', {hour: 'numeric', minute: '2-digit'})}</div>
                        {/* <div>
                          {workshop.eveningStartTime === 1
                            ? "1:00 PM - 4:00 PM"
                            : "2:00 PM - 5:00 PM"}
                        </div> */}
                      </div>
                    </div>
                    <div
                      className="venue"
                      onClick={() => window.open(event?.venueLink || "")}
                    >
                      <img
                        src={locationIcon}
                        alt="Venue Icon"
                        height={"30px"}
                      />
                      <span>{event.venue}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`price-container ${device}`}>
                <div className={`price-text ${device}`}>Prize Pool: </div>
                <div className={`rs-text`}>
                  <img src={rsImg} alt="" className={`rsImg ${device}`} />
                  &nbsp;{event!.amount}
                </div>
              </div>
              {user.shaastraID !== "TESTID" && user.role === UserRole.Admin && (
                <div>Number of participants: {noOfRegs}</div>
              )}
            </div>
            <div className={`right-container ${device}`}>
              <img
                src={event.poster}
                alt=""
                className={`posterImg ${device}`}
              />
              <button
                className={`register-btn ${device} `}
                onClick={() => {
                   window.open(event?.unstopLink, "_blank");
                }}
              >
                Register&nbsp;
                <span>
                  <img
                    src={btnArrowImg}
                    alt=""
                    className={`btnArrow ${device}`}
                  />
                </span>
              </button>
            </div>
          </div>
        ) : null}
      </div>
      {event?.rules ? (
        <div className={`rules-container ${device}`}>
          <div className={`wrapper ${device}`}>
            <div className={`rules-text ${device}`}>Rules</div>
            <WorkshopRules rules={event!.rules} />
          </div>
        </div>
      ) : null}

    </>
  );
};
export default EventDetails;
