import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import './typeWriter.scss'

type TypeWriterProps = {
  text: string;
};

const TypeWriter: React.FC<TypeWriterProps> = ({ text }) => {
  const { device } = useSelector((state: RootState) => state.windowSize);

  return (
    <div className="typewriter-heading-wrapper">
      <div className="typewriter-heading-line"></div>
      <div className="typewriter">
        <h1 className={`h1 ${device}`}>{text}</h1>
      </div>
      <div className="typewriter-heading-line"></div>
    </div>
  );
};

export default TypeWriter;
