import React, { useState } from "react";
import './faq.scss'
import { FAQ } from "../../../../../types/faq";
import downArrow from "../../../../../assets/icons/downArrow.svg";

type FaqProps = {
  faq: FAQ;
  index: number;
  faqs: FAQ[];
};

const Faq: React.FC<FaqProps> = ({ faq, index, faqs }) => {
  const [showAns, setShowAns] = useState<boolean>(false);

  return (
    <div className="faq">
      <div className="question" onClick={()=>{setShowAns(!showAns)}}>
        {faq.ques}
        <img className={`arrow ${showAns ? "up" : ""}`} alt="" src={downArrow}></img>
      </div>
      {showAns ? <div className="answer">{faq.ans}</div> : null}
      {index !== faqs.length - 1 ? <div className="line"></div> : null}
    </div>
  );
};
export default Faq;
