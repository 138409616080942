import React from 'react';
import './workshopRules.scss';
import RuleContainer from './rulesContainer/ruleContainer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';

type WorkshopRulesProps = {
    rules: string[];
};

const WorkshopRules:React.FC<WorkshopRulesProps> = ({rules}) => {
    const { device } = useSelector((state: RootState) => state.windowSize);
    
    return (
        <div className={`rules ${device}`}>
            {rules.map((rule, index) => (
                <RuleContainer rule={rule} index={index}/>
            ))}
        </div>
    )
}
export default WorkshopRules;