import React, { useContext, useEffect, useState } from "react";
import "./eventDetails.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { useNavigate, useParams } from "react-router-dom";
import btnArrowImg from "../../../../assets/images/workshop-btn-arrow.svg";
import rsImg from "../../../../assets/images/workshop-rs.svg";
import WorkshopRules from "../Rules/workshopRules";
import Navbar from "../../../../ui-elements/navbar/navbar";
import { useEventList } from "../../../../utils/events";
import { AuthContext } from "../../../../utils/authProvider";
import timeIcon from "../../../../assets/icons/timeIcon.svg";
import locationIcon from "../../../../assets/icons/locationIcon.svg";
import fileIcon from "../../../../assets/icons/fileIcon.svg";
import phoneIcon from "../../../../assets/icons/phoneIcon.svg";
import {
  UserRole, useCreateUserEventSjMutation, useGetMyUserEventSJsCountByIdQuery,
} from "../../../../generated/graphql";
import { useEventSJList } from "../../../../utils/eventsj";
import MessagePopup from "../../../../ui-elements/messagePopup/messagePoup";

type EventSJDetailsProps = {};

const EventSJDetails: React.FC<EventSJDetailsProps> = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);

  const navigate = useNavigate();

  const { id } = useParams();

  const { user } = useContext(AuthContext);

  const { eventsjList, refetch } = useEventSJList();

  let event = eventsjList.find(ev => ev.id.slice(-5) === id);

  const [createUserEventSjMutation, { data, loading, error }] = useCreateUserEventSjMutation()

  const [noOfRegs, setNoOfRegs] = useState(0);
  
  const { refetch: numberRefetch } = useGetMyUserEventSJsCountByIdQuery({variables: {getMyUserEventSJsCountByIdId: ""}, skip: true})

  useEffect(() => {
    if(event) {
      const func = async () => {
        const {data} = await numberRefetch({getMyUserEventSJsCountByIdId: event?.id || ""})
        setNoOfRegs(data.getMyUserEventSJsCountById)
      }
      try {
        func()
      } catch(err) {
        console.log(err)
      }
    }
  }, [event]);

  const onSubmit = async () => {
    try {
      await createUserEventSjMutation({variables: {createUserEventSjId: event?.id || ""}})
    } catch(err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if(data?.createUserEventSJ) {
      refetch()
      setTimeout(() => {
        navigate('/events')
      }, 2000);
    }
  }, [data]);

  return (
    <>

{error && (
        <MessagePopup
          message={"Workshop not selected"}
          position={"top-center"}
          category={"warning"}
        />
      )}
      {data && (
        <MessagePopup
          message={"Workshop Registration Success"}
          position={"top-center"}
          category={"success"}
        />
      )}
      {loading && (
        <MessagePopup
          message={"Loaing..."}
          position={"top-center"}
          category={"warning"}
        />
      )}
      <Navbar page="Events" />

      <div className={`details-container ${device}`}>
        {event ? (
          <div className={`wrapper ${device}`}>
            <div className={`left-container ${device}`}>
              <div className={`about-workshop ${device}`}>
                <div className={`title ${device}`}>{event!.title}</div>
                <div className={`desc ${device}`}>{event!.description}</div>
              </div>
              <div className="phone-doc-wrapper">
                {event.contentDoc && (
                  <div
                    className="phone-doc content-doc"
                    onClick={() => window.open(event?.contentDoc || "")}
                  >
                    <img src={fileIcon} alt="Time Icon" height={"20px"} />
                    <span>Problem Statement</span>
                  </div>
                )}
                {event.contactNumber && (
                  <div className="phone-doc">
                    <img src={phoneIcon} alt="Time Icon" height={"20px"} />
                    <div>
                    <span>{event.contactName}</span><br />
                    <span>{event.contactNumber}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="event-details">
                <div className="date-month">
                  <div className="month">{event.eventDate?.toLocaleString('default', {month: 'short'})}</div>
                  <div className="date">{event.eventDate?.getDate()}</div>
                </div>
                <div className="time-venue-wrapper">

                  <div className={`time-venue ${device}`}>
                    <div className="time">
                      <img src={timeIcon} alt="Time Icon" height={"30px"} />
                      <div className="times">
                        <div>{event.eventDate?.toLocaleTimeString('en-IN', {hour: 'numeric', minute: '2-digit'})}</div>
                      </div>
                    </div>
                    <div
                      className="venue"
                      onClick={() => window.open(event?.venueLink)}
                    >
                      <img
                        src={locationIcon}
                        alt="Venue Icon"
                        height={"30px"}
                      />
                      <span>{event.venue}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`price-container ${device}`}>
                <div className={`price-text ${device}`}>Prize Pool: </div>
                <div className={`rs-text`}>
                  <img src={rsImg} alt="" className={`rsImg ${device}`} />
                  &nbsp;{event!.amount}
                </div>
              </div>
              {user.shaastraID !== "TESTID" && user.role === UserRole.Admin &&
              <div>
                Number of participants: {noOfRegs}
              </div>
                }
            </div>
            <div className={`right-container ${device}`}>
              <img
                src={event.poster}
                alt=""
                className={`posterImg ${device}`}
              />
              <button
                disabled={event.registered}
                className={`register-btn ${device} ${
                  event.registered ? "registered" : ""
                }`}
                onClick={() => {
                  user.shaastraID === "TESTID"
                    ? navigate("/login")
                    : onSubmit();
                }}
              >
                {event.registered ? "Registered" : "Register"} &nbsp;
                {event.registered || (
                  <span>
                    <img
                      src={btnArrowImg}
                      alt=""
                      className={`btnArrow ${device}`}
                    />
                  </span>
                )}
              </button>
            </div>
          </div>
        ) : null}
      </div>

    </>
  );
};
export default EventSJDetails;
