import React, { createContext, useEffect, useState } from "react";
import { UserRole, useGetMeQuery } from "../generated/graphql";
import { User } from "../types/user";


interface AuthContextType {
    user: User;
    loading: boolean;
    setUser: React.Dispatch<React.SetStateAction<User>>;
    refetch: () => void;
}
interface AuthProviderProps {
    children: React.ReactNode;
}

export const initialUser: User = {
    shaastraID: "TESTID",
    name: "User",
    email: "user@mail.com",
    mobile: "9999999999",
    role: UserRole.User,
    emailVerified: false,
    personalId: "",
    noOfPeople: 0,
    userSlots: {id: ""},
    disabled: false,
}

export const AuthContext = createContext<AuthContextType>({
    user: initialUser,
    loading: false,
    setUser: () => {},
    refetch: () => {},
})

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User>(initialUser);
    const { data, loading, error, refetch } = useGetMeQuery()
    useEffect(() => {
        if(data) {
            setUser(data.getMe)
        }
    }, [data]);

    useEffect(() => {
        if(error) {
            console.log(error)
        }
    }, [error]);

    return (
        <AuthContext.Provider value={{user, loading, setUser, refetch}}>
            {children}
        </AuthContext.Provider>
    )
}