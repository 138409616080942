import React from "react";
import "./errorPage.scss";
import errorPageGIF from "../../../assets/images/errorPageWithoutBG.gif";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";

type ErrorPageProps = {};

const ErrorPage: React.FC<ErrorPageProps> = () => {
    const {device} = useSelector((state: RootState) => state.windowSize)
  return (
    <div className={`error-page ${device}`}>
      <img className={`illustration ${device}`} src={errorPageGIF} alt="" />
      <div className="error-text">PAGE NOT FOUND</div>
    </div>
  );
};
export default ErrorPage;
