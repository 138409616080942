import React from "react";
import "./guidelines.scss";
import Navbar from "../../../../ui-elements/navbar/navbar";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import TypeWriter from "../../../../ui-elements/typeWriter/typeWriter";

type GuidelinesProps = {};

const Guidelines: React.FC<GuidelinesProps> = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);

  return (
    <div className="guidelines-page-wrapper">
      <Navbar page="Workshops" />

      <div className="guidelines-page">
        <TypeWriter text="Workshop Guidelines" />
        <div className="guidelines-wrapper">
          <div className="sub-heading">Combo Details</div>

          <div className="combo-name">Merry Combo:</div>
          <div className="instructions">
            • Select any two workshops from our list along with any one merch
            provided
          </div>
          <div className="instructions">
            • On selecting workshops from our list, the discount varies with the
            selection of merchandise as : ₹400, ₹350 and ₹400 off on selection
            of T-Shirt, Oversized T-Shirt or Hoodie respectively
          </div>

          <div className="combo-name">Phantom Combo</div>
          <div className="instructions">
            • Select any three workshops from our list along with any of the
            merch provided
          </div>
          <div className="instructions">
            • On selecting workshops from our list the discount varies with
            selection of merchandise as : ₹500, ₹450 and ₹500 off on selection
            of T-Shirt, Oversized T-Shirt or Hoodie respectively
          </div>

          <div className="combo-name">
            On registering for workshops without any merch, a discount of ₹100
            or ₹200 is applicable on selection of two or three workshops
            respectively
          </div>

          <div className="sub-heading">
            Eligibility for free company sponsored workshop
          </div>

          <div className="instructions">
            • To attend a workshop sponsored by Cisco and Autodesk , purchase of
            any of the Merry or Phantom combo is necessary
          </div>
          <div className="instructions">
            • Certificate by Shaastra will be provided only on attending all the
            sessions of the workshop successfully
          </div>
          <div className="instructions">
            • It is advisable to check the slots and dates for workshops
            properly before registering,as clashes of workshops on the same day
            won’t be entertained. Refunds are not applicable in such situations
          </div>
          <div className="instructions">
            • A Shaastra Passport is mandatory for attending
            workshop.Individuals must apply for a passport and accommodation on
            Shaastra Sales Portal. Those who are unable to, can apply for a
            passport and accommodation at IIT Madras campus
          </div>

          
          <div className="sub-heading">
            Hybrid Workshops
          </div>

          <div className="instructions">
            • Your certificate will mention if you attended the session online or offline.
          </div>
          <div className="instructions">
            •  The following workshops can only be attended offline:<br />
          </div>
          <div className="instructions">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Cisco's Technology Networking Ecosystem<br />
          </div>
          <div className="instructions">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Generative design for manufacturing applications<br />
          </div>
          <div className="instructions">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Mathworks in AI<br />
          </div>
          <div className="instructions">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Structural Analysis<br />
          </div>
          <div className="instructions">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Observational Astronomy and Computational Methods<br />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Guidelines;
