import React, { useContext, useState } from "react";
import "./navbar.scss";
import { Fade as Hamburger } from "hamburger-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HomeIcon from "../../assets/icons/icon _home_.svg";
import { RootState } from "../../redux/reducers";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import emulateLogo from "../../assets/logos/emulate.png";
import summitLogo from "../../assets/logos/summit.svg";
import envisageLogo from "../../assets/logos/Envisage_Light.png";
import eventIcon from "../../assets/icons/eventsIcon.svg";
import workshopIcon from "../../assets/icons/grommet-icons_workshop.svg";
import spotlightIcon from "../../assets/icons/bi_mic-fill.svg";
import exhibitionIcon from "../../assets/icons/exhibition.png";
import industryIcon from "../../assets/icons/industryIcon.svg";
import profileIcon from "../../assets/icons/user-profile.svg";
import tifIcon from "../../assets/logos/tif_light.png";
import encIcon from "../../assets/logos/encryptcon_white_CUT.png";
import cartIcon from "../../assets/icons/salesIcon.svg"; 
import passportIcon from "../../assets/icons/passportIcon.svg"; 
import shaastraLogo from "../../assets/logos/25yrsLogoSilver.png";
import accomodationIcon from "../../assets/icons/accommodationIcon.svg";
import logoutIcon from "../../assets/icons/logout.svg";
import forgetMeNotIcon from "../../assets/logos/fmn.png";
import sponsors from '../../assets/icons/sponsors.svg' 
import laserIcon from '../../assets/icons/laserIcon.svg' 
import openHouseIcon from '../../assets/icons/open-house.svg' 
import { AuthContext } from "../../utils/authProvider";
import FullScreenOverlay from "../fullScreenOverlay/fullScreenOverlay";
import { useLogoutUserMutation } from "../../generated/graphql";
import client from "../../apolloClient";

type NavbarProps = {
  page:
    | "Home"
    | "Exhibitions"
    | "Workshops"
    | "Events"
    | "Envisage"
    | "Spotlight"
    | "Emulate"
    | "JMT"
    | "TIF"
    | "ForgetMeNot"
    | "Team"
    | "ContactUs"
    | "Summit"
    | "Login"
    | "Register"
    | "Accommodation"
    | "Passport"
    | "Industry"
    | "Sponsors"
    | "LaserTag"
    | "Open House"
    | "Sales"
};

const Navbar: React.FC<NavbarProps> = ({ page }) => {
  const { device } = useSelector((state: RootState) => state.windowSize);

  const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation()

  const { user, refetch } = useContext(AuthContext);
  const navigate = useNavigate();

  const location = useLocation();

  const [activePage, setActivePage] = useState<string>(page);
  const [isHovered, setIsHovered] = useState(false);
  const [navItemHovered, setNavItemHovered] = useState(false);

  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleNavItemMouseEnter = () => {
    setNavItemHovered(true);
  };

  const handleNavItemMouseLeave = () => {
    setNavItemHovered(false);
  };

  const navItemNameMoveLeft = {
    initial: {
      translateX: "-10px",
    },
    animate: {
      transition: { duration: 0.3 },
      translateX: "0px",
    },
    exit: {
      transition: { duration: 0.3 },
      translateX: "30px",
    },
  };

  const navItemNameMoveRight = {
    initial: {
      translateX: "10px",
    },
    animate: {
      transition: { duration: 0.3 },
      translateX: "0px",
    },
    exit: {
      transition: { duration: 0.3 },
      translateX: "-30px",
    },
  };

  const navbarBackgroundOpacity = {
    initial: {
      backgroundColor: "rgba(0, 0, 0)",
    },
    hover: {
      backgroundColor: "rgba(0, 0, 0, 0.776)",
      transition: { duration: 0.3, ease: "easeOut" },
    },
    exit: {
      transition: { duration: 0.3, ease: "easeOut" },
      backgroundColor: "rgba(0, 0, 0)",
    },
  };

  const navItemsList: {
    icon: string;
    name: string;
    route: string;
    inWebsite: boolean;
  }[] = [
    {
      icon: HomeIcon,
      name: "Home",
      route: "/",
      inWebsite: true,
    },
    {
      icon: openHouseIcon,
      name: "Open House",
      route: "/open-house",
      inWebsite: true,
    },
    {
      icon: exhibitionIcon,
      name: "Exhibitions",
      route: "/exhibitions",
      inWebsite: true,
    },
    {
      icon: workshopIcon,
      name: "Workshops",
      route: "/workshops",
      inWebsite: true,
    },
    // {
    //   icon: accomodationIcon,
    //   name: "Accommodation",
    //   route: "/accommodation",
    //   inWebsite: true,
    // },
    {
      icon: passportIcon,
      name: "Passport",
      route: "/passport",
      inWebsite: true,
    },
    {
      icon: eventIcon,
      name: "Events",
      route: "/events",
      inWebsite: true,
    },
    {
      icon: envisageLogo,
      name: "Envisage",
      route: "/envisage",
      inWebsite: true,
    },
    // {
    //   icon: laserIcon,
    //   name: "LaserTag",
    //   route: "/lasertag",
    //   inWebsite: true,
    // },
    {
      icon: forgetMeNotIcon,
      name: "Forget Me Not",
      route: "/forgetmenot",
      inWebsite: true,
    },
    {
      icon: sponsors,
      name: "Sponsors",
      route: "/sponsors",
      inWebsite: true
    },
    {
      icon: spotlightIcon,
      name: "Spotlight",
      route: "/spotlight",
      inWebsite: true,
    },
    {
      icon: industryIcon,
      name: "Industry Insights",
      route: "/industry-insights",
      inWebsite: true,
    },
    {
      icon: emulateLogo,
      name: "Emulate",
      route: "https://emulate.shaastra.org",
      inWebsite: false,
    },
    {
      icon: summitLogo,
      name: "Summit",
      route: "https://summit.shaastra.org",
      inWebsite: false,
    },
    {
      icon: tifIcon,
      name: "TIF",
      route: "https://tif.shaastra.org",
      inWebsite: false,
    },
    {
      icon: encIcon,
      name: "Encryptcon",
      route: "https://encryptcon.shaastra.org",
      inWebsite: false,
    },
    {
      icon: "JMT",
      name: "JMT",
      route: "https://jmt.shaastra.org",
      inWebsite: false,
    },
    {
      icon: cartIcon,
      name: "Sales",
      route: "/sales",
      inWebsite: false,
    },
  ];

  return device === "mobile" ? (
    <div className={`navbar ${device}`}>
      <img className={`shaastra-logo ${device}`} alt="" src={shaastraLogo} />
      <div className="right-end">
        {user.shaastraID === "TESTID" ? (
          location.pathname !== "/register" &&
          location.pathname !== "/login" ? (
            <div
              className={`profile-container ${
                navItemHovered ? "hover" : null
              } ${device}`}
            >
              <div
                className={`profile ${device}`}
                onClick={() => navigate("/login")}
              >
                Login
              </div>
              {/* /
              <div
                className={`profile ${device}`}
                onClick={() => navigate("/register")}
              >
                Register
              </div> */}
            </div>
          ) : null
        ) : (
          <div
            className={`profile-container ${
              navItemHovered ? "hover" : null
            } ${device}`}
          >
            <div className={`profile ${device}`}>{user.shaastraID}</div>
            <img
              src={profileIcon}
              alt=""
              className={`profile-icon ${device}`}
            />
          </div>
        )}

        <div className="menu">
          <Hamburger toggle={setOpenMenu} toggled={openMenu} />
        </div>
      </div>
      {openMenu ? (
        <FullScreenOverlay>
          {navItemsList.map((navItem) => (
            <div
              className="menu-item-wrapper"
              onClick={() => {
                if (navItem.inWebsite) {
                  navigate(navItem.route);
                } else {
                  window.open(navItem.route);
                }
              }}
            >
              {navItem.icon !== "JMT" ? (
                <img className="item-icon" alt="" src={navItem.icon} />
              ) : (
                <div className="item-icon">{navItem.icon}</div>
              )}
              <div
                className={`item-name ${
                  activePage === navItem.name ? "active" : ""
                }`}
              >
                {navItem.name}
              </div>
            </div>
          ))}
          {user.shaastraID === "TESTID" ? null : (
            <div
              className="menu-item-wrapper"
              onClick={async () => {
                await logoutUserMutation()
                window.location.reload()
              }}
            >
              <img className="item-icon" alt="" src={logoutIcon} />
              <div
                className={`item-name ${
                  activePage === "Logout" ? "active" : ""
                }`}
              >
                {"Logout"}
              </div>
            </div>
          )}
        </FullScreenOverlay>
      ) : null}
    </div>
  ) : (
    <motion.div
      initial="initial"
      whileHover="hover"
      exit="exit"
      className={`navbar ${device}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      variants={navbarBackgroundOpacity}
    >
      <div className="underneath-layer"></div>
      <div className={`nav-left  ${navItemHovered ? "hover" : null} ${device}`}>
        <img
          src={shaastraLogo}
          alt=""
          className="shaastra-logo"
          onClick={() => {
            navigate("/");
          }}
        />
        <motion.div
          initial="initial"
          animate="initial"
          whileHover="animate"
          exit="exit"
          onMouseEnter={handleNavItemMouseEnter}
          onMouseLeave={handleNavItemMouseLeave}
          className={`nav-items ${navItemHovered ? "hover" : null} ${device}`}
        >
          <div
            className={`nav-item ${
              activePage === "Home" ? "active" : null
            } ${device}`}
          >
            <div
              className={`torch ${
                activePage === "Home" ? "active-hovered" : null
              } ${device}`}
            >
              <div className={`light ${device}`}></div>
              <div className={`triangle ${device}`}></div>
            </div>
            <Link to="/" className={`nav-link ${device}`}>
              <img src={HomeIcon} alt="" className={`nav-icon ${device}`} />
              <motion.div
                variants={navItemNameMoveLeft}
                className={`nav-link-name ${device}`}
              >
                Home
              </motion.div>
            </Link>
          </div>
          <div
            className={`nav-item ${
              activePage === "Open House" ? "active" : null
            } ${device}`}
          >
            <div
              className={`torch ${
                activePage === "Open House" ? "active-hovered" : null
              } ${device}`}
            >
              <div className={`light ${device}`}></div>
              <div className={`triangle ${device}`}></div>
            </div>
            <Link to="/open-house" className={`nav-link ${device}`}>
              <img
                src={openHouseIcon}
                alt=""
                className={`nav-icon ${device}`}
              />
              <motion.div
                variants={navItemNameMoveLeft}
                className={`nav-link-name ${device}`}
              >
                Open House
              </motion.div>
            </Link>
          </div>
          <div
            className={`nav-item ${
              activePage === "Exhibitions" ? "active" : null
            } ${device}`}
          >
            <div
              className={`torch ${
                activePage === "Exhibitions" ? "active-hovered" : null
              } ${device}`}
            >
              <div className={`light ${device}`}></div>
              <div className={`triangle ${device}`}></div>
            </div>
            <Link to="/exhibitions" className={`nav-link ${device}`}>
              <img
                src={exhibitionIcon}
                alt=""
                className={`nav-icon ${device}`}
              />
              <motion.div
                variants={navItemNameMoveLeft}
                className={`nav-link-name ${device}`}
              >
                Exhibitions
              </motion.div>
            </Link>
          </div>
          <div
            className={`nav-item ${
              activePage === "Workshops" ? "active" : null
            } ${device}`}
          >
            <div
              className={`torch ${
                activePage === "Workshops" ? "active-hovered" : null
              } ${device}`}
            >
              <div className={`light ${device}`}></div>
              <div className={`triangle ${device}`}></div>
            </div>
            <Link to="/workshops" className={`nav-link ${device}`}>
              <img src={workshopIcon} alt="" className={`nav-icon ${device}`} />
              <motion.div
                variants={navItemNameMoveLeft}
                className={`nav-link-name ${device}`}
              >
                Workshops
              </motion.div>
            </Link>
          </div>
          <div
            className={`nav-item ${
              activePage === "Events" ? "active" : null
            } ${device}`}
          >
            <div
              className={`torch ${
                activePage === "Events" ? "active-hovered" : null
              } ${device}`}
            >
              <div className={`light ${device}`}></div>
              <div className={`triangle ${device}`}></div>
            </div>
            <Link to="/events" className={`nav-link ${device}`}>
              <img src={eventIcon} alt="" className={`nav-icon ${device}`} />
              <motion.div
                variants={navItemNameMoveLeft}
                className={`nav-link-name ${device}`}
              >
                Events
              </motion.div>
            </Link>
          </div>
          {/* <div
            className={`nav-item ${
              activePage === "Accommodation" ? "active" : null
            } ${device}`}
          >
            <div
              className={`torch ${
                activePage === "Accommodation" ? "active-hovered" : null
              } ${device}`}
            >
              <div className={`light ${device}`}></div>
              <div className={`triangle ${device}`}></div>
            </div>
            <Link to="/accommodation" className={`nav-link ${device}`}>
              <img
                src={accomodationIcon}
                alt=""
                className={`nav-icon ${device}`}
              />
              <motion.div
                variants={navItemNameMoveLeft}
                className={`nav-link-name ${device}`}
              >
                Accommodation
              </motion.div>
            </Link>
          </div> */}
          <div
            className={`nav-item ${
              activePage === "Envisage" ? "active" : null
            } ${device}`}
          >
            <div
              className={`torch ${
                activePage === "Envisage" ? "active-hovered" : null
              } ${device}`}
            >
              <div className={`light ${device}`}></div>
              <div className={`triangle ${device}`}></div>
            </div>
            <Link to="/envisage" className={`nav-link ${device}`}>
              <img src={envisageLogo} alt="" className={`nav-icon ${device}`} />
              <motion.div
                variants={navItemNameMoveLeft}
                className={`nav-link-name ${device}`}
              >
                Envisage
              </motion.div>
            </Link>
          </div>
          <div
            className={`nav-item ${
              activePage === "Spotlight" ? "active" : null
            } ${device}`}
          >
            <div
              className={`torch ${
                activePage === "Spotlight" ? "active-hovered" : null
              } ${device}`}
            >
              <div className={`light ${device}`}></div>
              <div className={`triangle ${device}`}></div>
            </div>
            <Link to="/spotlight" className={`nav-link ${device}`}>
              <img
                src={spotlightIcon}
                alt=""
                className={`nav-icon ${device}`}
              />
              <motion.div
                variants={navItemNameMoveLeft}
                className={`nav-link-name ${device}`}
              >
                Spotlight
              </motion.div>
            </Link>
          </div>
          <div
            className={`nav-item ${
              activePage === "Industry" ? "active" : null
            } ${device}`}
          >
            <div
              className={`torch ${
                activePage === "Industry" ? "active-hovered" : null
              } ${device}`}
            >
              <div className={`light ${device}`}></div>
              <div className={`triangle ${device}`}></div>
            </div>
            <Link to="/industry-insights" className={`nav-link ${device}`}>
              <img
                src={industryIcon}
                alt=""
                className={`nav-icon ${device}`}
              />
              <motion.div
                variants={navItemNameMoveLeft}
                className={`nav-link-name ${device}`}
              >
                Industry Insights
              </motion.div>
            </Link>
          </div>
          {/* <div
            className={`nav-item ${
              activePage === "LaserTag" ? "active" : null
            } ${device}`}
          >
            <div
              className={`torch ${
                activePage === "LaserTag" ? "active-hovered" : null
              } ${device}`}
            >
              <div className={`light ${device}`}></div>
              <div className={`triangle ${device}`}></div>
            </div>
            <Link to="/lasertag" className={`nav-link ${device}`}>
              <img
                src={laserIcon}
                alt=""
                className={`nav-icon ${device}`}
              />
              <motion.div
                variants={navItemNameMoveLeft}
                className={`nav-link-name ${device}`}
              >
                LaserTag
              </motion.div>
            </Link>
          </div> */}
          
        </motion.div>
      </div>
      <div
        className={`nav-right  ${navItemHovered ? "hover" : null} ${device}`}
      >
        {user.shaastraID === "TESTID" ? (
          location.pathname !== "/register" &&
          location.pathname !== "/login" ? (
            <div
              className={`profile-container ${
                navItemHovered ? "hover" : null
              } ${device}`}
            >
              <div
                className={`profile ${device}`}
                onClick={() => navigate("/login")}
              >
                Login
              </div>
              {/* /
              <div
                className={`profile ${device}`}
                onClick={() => navigate("/register")}
              >
                Register
              </div> */}
            </div>
          ) : null
        ) : (
          <div
            className={`profile-container ${
              navItemHovered ? "hover" : null
            } ${device}`}
          >
            <div className={`profile ${device}`}>{user.shaastraID}</div>
            <img
              src={profileIcon}
              alt=""
              className={`profile-icon ${device}`}
            />
          </div>
        )}
        <motion.div
          initial="initial"
          animate="initial"
          whileHover="animate"
          exit="exit"
          onMouseEnter={handleNavItemMouseEnter}
          onMouseLeave={handleNavItemMouseLeave}
          className={`nav-items ${navItemHovered ? "hover" : null} ${device}`}
        >
         <div
            className={`nav-item ${
              activePage === "Passport" ? "active" : null
            } ${device}`}
          >
            <div
              className={`torch ${
                activePage === "Passport" ? "active-hovered" : null
              } ${device}`}
            >
              <div className={`light ${device}`}></div>
              <div className={`triangle ${device}`}></div>
            </div>
            <Link
              to={"/passport"}
              className={`nav-link ${device}`}
            >
              <motion.div
                variants={navItemNameMoveRight}
                className={`nav-link-name ${device}`}
              >
                Passport
              </motion.div>
              <img src={passportIcon} alt="" className={`nav-icon ${device}`} />
            </Link>
          </div> 
         <div
            className={`nav-item ${
              activePage === "ForgetMeNot" ? "active" : null
            } ${device}`}
          >
            <div
              className={`torch ${
                activePage === "ForgetMeNot" ? "active-hovered" : null
              } ${device}`}
            >
              <div className={`light ${device}`}></div>
              <div className={`triangle ${device}`}></div>
            </div>
            <Link
              to={"/forgetmenot"}
              className={`nav-link ${device}`}
            >
              <motion.div
                variants={navItemNameMoveRight}
                className={`nav-link-name ${device}`}
              >
                Forgetmenot
              </motion.div>
              <img src={forgetMeNotIcon} alt="" className={`nav-icon ${device}`} />
            </Link>
          </div> 
          <div
            className={`nav-item ${
              activePage === "Sponsors" ? "active" : null
            } ${device}`}
          >
            <div
              className={`torch ${
                activePage === "Sponsors" ? "active-hovered" : null
              } ${device}`}
            >
              <div className={`light ${device}`}></div>
              <div className={`triangle ${device}`}></div>
            </div>
            <Link
              to={"/sponsors"}
              className={`nav-link ${device}`}
            >
              <motion.div
                variants={navItemNameMoveRight}
                className={`nav-link-name ${device}`}
              >
                Sponsors
              </motion.div>
              <img src={sponsors} alt="" className={`nav-icon ${device}`} />
            </Link>
          </div> 
          <div
            className={`nav-item ${
              activePage === "Emulate" ? "active" : null
            } ${device}`}
          >
            <div
              className={`torch ${
                activePage === "Emulate" ? "active-hovered" : null
              } ${device}`}
            >
              <div className={`light ${device}`}></div>
              <div className={`triangle ${device}`}></div>
            </div>
            <Link
              to={"https://emulate.shaastra.org"}
              target="_blank"
              className={`nav-link ${device}`}
            >
              <motion.div
                variants={navItemNameMoveRight}
                className={`nav-link-name ${device}`}
              >
                Emulate
              </motion.div>
              <img src={emulateLogo} alt="" className={`nav-icon ${device}`} />
            </Link>
          </div>
          <div
            className={`nav-item ${
              activePage === "Summit" ? "active" : null
            } ${device}`}
          >
            <div
              className={`torch ${
                activePage === "Summit" ? "active-hovered" : null
              } ${device}`}
            >
              <div className={`light ${device}`}></div>
              <div className={`triangle ${device}`}></div>
            </div>
            <Link
              to={"https://summit.shaastra.org"}
              target="_blank"
              className={`nav-link ${device}`}
            >
              <motion.div
                variants={navItemNameMoveRight}
                className={`nav-link-name ${device}`}
              >
                Summit
              </motion.div>
              <img src={summitLogo} alt="" className={`nav-icon ${device}`} />
            </Link>
          </div>
          <div
            className={`nav-item ${
              activePage === "TIF" ? "active" : null
            } ${device}`}
          >
            <div
              className={`torch ${
                activePage === "TIF" ? "active-hovered" : null
              } ${device}`}
            >
              <div className={`light ${device}`}></div>
              <div className={`triangle ${device}`}></div>
            </div>
            <Link
              to={"https://tif.shaastra.org"}
              target="_blank"
              className={`nav-link ${device}`}
            >
              <motion.div
                variants={navItemNameMoveRight}
                className={`nav-link-name ${device}`}
              >
                TIF
              </motion.div>
              <img src={tifIcon} alt="" className={`nav-icon ${device}`} />
            </Link>
          </div>
          <div
            className={`nav-item ${
              activePage === "Encrypton" ? "active" : null
            } ${device}`}
          >
            <div
              className={`torch ${
                activePage === "Encrypton" ? "active-hovered" : null
              } ${device}`}
            >
              <div className={`light ${device}`}></div>
              <div className={`triangle ${device}`}></div>
            </div>
            <Link
              to={"https://encryptcon.shaastra.org"}
              target="_blank"
              className={`nav-link ${device}`}
            >
              <motion.div
                variants={navItemNameMoveRight}
                className={`nav-link-name ${device}`}
              >
                Encryptcon
              </motion.div>
              <img src={encIcon} alt="" className={`nav-icon ${device}`} />
            </Link>
          </div>
          <div
            className={`nav-item ${
              activePage === "Sales" ? "active" : null
            } ${device}`}
          >
            <div
              className={`torch ${
                activePage === "Sales" ? "active-hovered" : null
              } ${device}`}
            >
              <div className={`light ${device}`}></div>
              <div className={`triangle ${device}`}></div>
            </div>
            <Link
              to={"/sales"}
              className={`nav-link ${device}`}
            >
              <motion.div
                variants={navItemNameMoveRight}
                className={`nav-link-name ${device}`}
              >
                Sales
              </motion.div>
              <img src={cartIcon} alt="" className={`nav-icon ${device}`} />
              {/* <div className={`nav-icon ${device}`}>JMT</div> */}
            </Link>
          </div>

          {user.shaastraID === "TESTID" ? null : (
            <div
              className={`nav-item ${
                activePage === "Logout" ? "active" : null
              } ${device}`}
            >
              <div
                className={`torch ${
                  activePage === "Logout" ? "active-hovered" : null
                } ${device}`}
              >
                <div className={`light ${device}`}></div>
                <div className={`triangle ${device}`}></div>
              </div>
              <div
                onClick={async () => {
                  await logoutUserMutation()
                  window.location.reload()
                }}
                className={`nav-link ${device}`}
              >
                <motion.div
                  variants={navItemNameMoveRight}
                  className={`nav-link-name ${device}`}
                >
                  Logout
                </motion.div>
                <img src={logoutIcon} alt="" className={`nav-icon ${device}`} />
              </div>
            </div>
          )}
        </motion.div>
      </div>
    </motion.div>
  );
};
export default Navbar;
