import { useContext, useEffect, useState } from "react";
import { useGetEventsQuery } from "../generated/graphql";
import { AuthContext } from "./authProvider";
import { EventType } from "../types/event";

export function useEventList() {
  const [eventList, setEventList] = useState<EventType[]>([]);

  const { user } = useContext(AuthContext);
  const { data, loading, error } = useGetEventsQuery();
  //   const {
  //     data: userData,
  //     loading: userLoading,
  //     error: userError,
  //   } = useGetMyWorkshopIdsQuery();

  useEffect(() => {
    // console.log("hey")
    if (data) {
      // console.log("hey")
      setEventList(
        data.getEvents.map((event, index) => {
          const poster =
            "https://drive.google.com/uc?export=view&id=" +
            event.poster.slice(32, event.poster.indexOf("/view"));
      
          const eventDate = new Date(event.eventDate);
          return {
            ...event,
            poster,
            eventDate,
          };
        })
      );
    }
  }, [data]);

  // useEffect(() => {
  //   if(workshopList) {
  //     console.table(workshopList.map(item => ({name: item.title, num: item.workshopDate})))
  //   }
  // }, [workshopList]);

  useEffect(() => {
    if (error) console.log(error);
  }, [error]);

  return { eventList };
}