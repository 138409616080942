import React, { useEffect, useState } from "react";
import "./workshop.scss";
import Navbar from "../../../ui-elements/navbar/navbar";
import WorkshopCard from "./Card/workshopCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import banner from "../../../assets/images/combosBanner.png";
import GlitchTitle from "../../../animations/glitchTitle/glitchTitle";
import { ciscoWorkshop , autodeskWorkshop, useWorkshopList } from "../../../utils/workshops";
import WorkshopMobileCard from "./Card/workshopMobileCard";
import { useNavigate } from "react-router-dom";
import Scrollindicator from "../../../ui-elements/scrollindicator/scrollindicator";

type WorkshopProps = {};

const Workshop: React.FC<WorkshopProps> = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);
  const navigate = useNavigate();

  const { workshopList } = useWorkshopList();

  return (
    <>
      <Navbar page="Workshops" />

      <div className={`workshop ${device}`}>
        <GlitchTitle title="Workshops" />
        <div className="hybrid">Our workshops are now hybrid! Attend them in either online or offline mode</div>
        <img src={banner} alt="" className="combo-banner" />
        <div className="btn-wrapper">
          <div
            className="register-btn"
            onClick={() => {
              navigate("/register-workshop", { state: { combo: "2" } });
            }}
          >
            Merry Combo
          </div>
          <div
            className="register-btn"
            onClick={() => {
              navigate("/register-workshop", { state: { combo: "3" } });
            }}
          >
            Phantom Combo
          </div>
        </div>

          <Scrollindicator/>

        <div className={`workshop-cards ${device}`}>
            {device === "mobile" ? 
            <WorkshopMobileCard workshop={ciscoWorkshop} index={parseInt(ciscoWorkshop.id)} /> :
            <WorkshopCard workshop={ciscoWorkshop} index={parseInt(ciscoWorkshop.id)} />
            }
            {device === "mobile" ? 
            <WorkshopMobileCard workshop={autodeskWorkshop} index={parseInt(autodeskWorkshop.id)} /> :
            <WorkshopCard workshop={autodeskWorkshop} index={parseInt(autodeskWorkshop.id)} />
            }
          {workshopList.map((workshop, index) =>
            device === "mobile" ? (
              <WorkshopMobileCard workshop={workshop} index={index} />
            ) : (
              <WorkshopCard workshop={workshop} index={index} />
            )
          )}
        </div>
        {/* <WorkshopDetails /> */}
        {/* <WorkshopDates /> */}
      </div>
    </>
  );
};
export default Workshop;
