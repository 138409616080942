import React, { useContext, useState } from "react";
import "./eventCard.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { motion } from "framer-motion";
import feeImg from "../../../../assets/images/workshop-fees.svg";
import rsImg from "../../../../assets/images/workshop-rs.svg";
import locationImg from "../../../../assets/images/workshop-location.svg";
import btnArrowImg from "../../../../assets/images/workshop-btn-arrow.svg";
import tick from "../../../../assets/icons/tick.svg";
import { useNavigate } from "react-router-dom";
import { EventType } from "../../../../types/event";
import workshopDummyImage from "../../../../assets/images/workshopDummy.png";
import { AuthContext } from "../../../../utils/authProvider";
import { EventSJType } from "../../../../types/eventsj";

type EventCardProps = {
  event: EventType | EventSJType;
  index: number;
};

const EventCard: React.FC<EventCardProps> = ({ event, index }) => {
  const { device } = useSelector((state: RootState) => state.windowSize);
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const cardHover = {
    initial: {
      width: "20%",
      height: "20%",
    },
    animate: {
      width: "100%",
      height: "100%",
      transition: { duration: 0.5 },
      borderRadius: 0,
    },
    exit: {
      width: "20%",
      height: "20%",
    },
  };

  return (
    <div className="workshop-card-wrapper">
      <motion.div
        className={`workshop-card ${device}`}
        initial="initial"
        whileHover="animate"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        exit="exit"
      >
        <motion.div className={`card-img-container ${device}`}>
          <img
            src={event.poster ? event.poster : workshopDummyImage}
            alt=""
            className={`card-img ${device}`}
          />
          {/* {event.registered && (
            <div title="Registered" className="card-top-right-tick">
              <img src={tick} alt=""></img>
            </div>
          )} */}
        </motion.div>
        <motion.div
          className={`rounded-corner ${device}`}
          variants={cardHover}
        ></motion.div>
        <motion.div
          className={`card-details ${device}`}
          initial={{ opacity: 0 }}
          whileHover={{ opacity: 1 }}
          transition={{ duration: "0.5" }}
        >
          {/* <div className={`workshop-name ${device}`}>{workshop.title}</div> */}
          {/* <div className={`workshop-details ${device}`}>
                    {workshop.description.length>150 ? workshop.description.substring(0, 150): workshop.description}
                </div> */}
          <div className={`fee-location-container ${device}`}>
            <div className={`fee-container ${device}`}>
              <img
                src={feeImg}
                alt=""
                className={`fee-locationImg ${device}`}
              />
              <div className={`registration ${device}`}>
                {/* <div className={`title1-text ${device}`}>
                                REGISTRATION FEE
                            </div> */}
                <div className={`title2-text`}>
                  <span>
                    <img src={rsImg} alt="" className={`rsImg ${device}`} />
                    {event.amount}
                  </span>
                </div>
              </div>
            </div>
            <div className={`location-container ${device}`}>
              <img
                src={locationImg}
                alt=""
                className={`fee-locationImg ${device}`}
              />
              <div className={`location ${device}`}>
                {/* <div className={`title1-text ${device}`}>LOCATION</div> */}
                <div className={`title2-text`}>{event.venue}</div>
              </div>
            </div>
          </div>
          <button
            className={`workshop-btn ${device}`}
            onClick={() =>  'registered' in event ? navigate(`/sj-events/${event.id.slice(-5)}`) : navigate(`/events/${event.id.slice(-5)}`)}
          >
            MORE DETAILS &nbsp;
            <span>
              <motion.img
                src={btnArrowImg}
                alt=""
                className={`btnArrow ${device}`}
              />
            </span>
          </button>
        </motion.div>
      </motion.div>
      <div className={`card-title ${device}`}>{event.title}</div>
    </div>
  );
};
export default EventCard;
