import React, { useState, useEffect } from "react";
import './scrollindicator.scss'
import downArrow from '../../assets/icons/downArrow2.svg'

type ScrollindicatorProps = {};

const Scrollindicator: React.FC<ScrollindicatorProps> = () => {


  const [scrollPosition, setPosition] = useState({ scrollX: 0, scrollY: 0 });

  useEffect(() => {
    function updatePosition() {
      setPosition({ scrollX: window.scrollX, scrollY: window.scrollY });
    }

    window.addEventListener("scroll", updatePosition);
    updatePosition();

    return () => window.removeEventListener("scroll", updatePosition);
  }, []);
  
  return (
   <div>
     {scrollPosition.scrollY === 0 ? 
    <div className="scroll-indicator bounce">
    <img className="arrow fade" alt="" src={downArrow}/>
</div>
    : <></>}
   </div>
  );
};
export default Scrollindicator;
