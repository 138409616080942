import React, { useContext, useEffect, useState } from "react";
import './stats.scss'
import { useSelector } from "react-redux";
import { AuthContext } from "../../../utils/authProvider";
import { RootState } from "../../../redux/reducers";
import { useCheckUserWorkshopMutation, useGetCiscoAutoQuery, useGetUserWorkshopsAmountByCombosQuery, useGetUserWorkshopsCountByCombosQuery, useGetUserWorkshopsPaidQuery } from "../../../generated/graphql";
import GlitchTitle from "../../../animations/glitchTitle/glitchTitle";
import { CSVLink } from "react-csv";
import MessagePopup from "../../../ui-elements/messagePopup/messagePoup";


type StatsProps = {};

const Stats: React.FC<StatsProps> = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);

  const { data: data1 } = useGetUserWorkshopsAmountByCombosQuery({variables: {noOfWorkshops: 1}})
  const { data: data2 } = useGetUserWorkshopsAmountByCombosQuery({variables: {noOfWorkshops: 2}})
  const { data: data3 } = useGetUserWorkshopsAmountByCombosQuery({variables: {noOfWorkshops: 3}})
  
  const { data: datac1 } = useGetUserWorkshopsCountByCombosQuery({variables: {noOfWorkshops: 1}})
  const { data: datac2 } = useGetUserWorkshopsCountByCombosQuery({variables: {noOfWorkshops: 2}})
  const { data: datac3 } = useGetUserWorkshopsCountByCombosQuery({variables: {noOfWorkshops: 3}})

  const { data: dataca } = useGetCiscoAutoQuery()

  const { data: merchData } = useGetUserWorkshopsPaidQuery()

  const [checkUserWorkshopMutation, { data, loading, error }] = useCheckUserWorkshopMutation()

  const [shaastraId, setShaastraId] = useState("");

  const handleClick = async () => {
    await checkUserWorkshopMutation({variables: {shaastraId}})
  }

  console.log(merchData?.getUserWorkshopsPaid)

  return (
  <div className="stats-page">
    { (error) && <MessagePopup
          message={"Error"}
          position={"top-center"}
          category={"warning"}
          delay={5}
        /> }
    { (data && !data?.checkUserWorkshop) && <MessagePopup
          message={"Workshop Payment not found"}
          position={"top-center"}
          category={"warning"}
          delay={5}
        /> }
    { (data && data?.checkUserWorkshop) && <MessagePopup
          message={"Workshop Payment found"}
          position={"top-center"}
          category={"success"}
          delay={5}
        /> }
    <GlitchTitle title="Workshop Stats" />
    <table>
    <tr><td className="stat">Number of Single Workshops Sold </td><td>{datac1?.getUserWorkshopsCountByCombos}</td></tr>
    <tr><td className="stat">Amount from Single Workshops Sold </td><td>Rs. {data1?.getUserWorkshopsAmountByCombos}</td></tr>
    <tr><td className="stat">Number of 2 Combos Sold </td><td>{datac2?.getUserWorkshopsCountByCombos}</td></tr>
    <tr><td className="stat">Amount from 2 Combos Sold </td><td>Rs. {data2?.getUserWorkshopsAmountByCombos}</td></tr>
    <tr><td className="stat">Number of 3 Combos Sold </td><td>{datac3?.getUserWorkshopsCountByCombos}</td></tr>
    <tr><td className="stat">Amount from 3 Combos Sold </td><td>Rs. {data3?.getUserWorkshopsAmountByCombos}</td></tr>
    <tr><td className="stat">Total Amount </td><td>Rs. {(data3 && data2 && data1) && data3?.getUserWorkshopsAmountByCombos + data2?.getUserWorkshopsAmountByCombos + data1?.getUserWorkshopsAmountByCombos}</td></tr>
    <tr><td className="stat">Cisco Registrations Number</td><td>{dataca?.getCiscoAuto.cisco.length}</td></tr>
    <tr><td className="stat">Autodesk Registrations Number</td><td>{dataca?.getCiscoAuto.autodesk.length}</td></tr>
    </table>

    <CSVLink filename={`Merch.csv`} data={merchData?.getUserWorkshopsPaid ? merchData.getUserWorkshopsPaid.map(item => ({...item, ...item.user})) : []}>Download Combo Merch Data</CSVLink>
    <CSVLink filename={`Cisco.csv`} data={dataca?.getCiscoAuto.cisco ? dataca?.getCiscoAuto.cisco.map(item => ({...item, ...item.user})) : []}>Download Cisco Data</CSVLink>
    <CSVLink filename={`Autodesk.csv`} data={dataca?.getCiscoAuto.autodesk ? dataca?.getCiscoAuto.autodesk.map(item => ({...item, ...item.user})) : []}>Download Autodesk Data</CSVLink>

<div className="workshop-check">

    <input onChange={(e) => setShaastraId(e.target.value)} placeholder="Enter ShaastraID"></input>
    <button onClick={handleClick} >Submit</button>
</div>
    
  </div>
  )
};
export default Stats;
