import { useContext, useEffect, useState } from "react";
import { AutodeskType, WorkshopType } from "../types/workshop";
import sample from "../assets/images/event-workshop.png";
import {
  useGetMyWorkshopIdsQuery,
  useGetWorkshopsQuery,
} from "../generated/graphql";
import { AuthContext } from "./authProvider";

export function useWorkshopList() {
  const [workshopList, setWorkshopList] = useState<WorkshopType[]>([]);

  const { user } = useContext(AuthContext);
  const { data, loading, error } = useGetWorkshopsQuery();
  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch
  } = useGetMyWorkshopIdsQuery();

  useEffect(() => {
    // console.log("hey")
    if (data) {
      // console.log("hey")
      setWorkshopList(
        data.getWorkshops.map((workshop, index) => {
          const poster =
            "https://drive.google.com/uc?export=view&id=" +
            workshop.poster.slice(32, workshop.poster.indexOf("/view"));
          const cost = workshop.basket === "1" ? 499 : workshop.basket === "2" ? 599 : 1999;
          const workshopDate = new Date(workshop.workshopDate)
          return {
            ...workshop,
            cost,
            registered: userData?.getMyWorkshopIds.includes(workshop.id),
            poster,
            workshopDate
          };
        })
      );
    }
  }, [data, userData]);

  // useEffect(() => {
  //   if(workshopList) {
  //     console.table(workshopList.map(item => ({name: item.title, num: item.workshopDate})))
  //   }
  // }, [workshopList]);

  useEffect(() => {
    if (error) console.log(error);
    if (userError) console.log(userError);
  }, [error, userError]);

  return { workshopList, refetch };
}

export const ciscoWorkshop: WorkshopType = {
  id: "100",
  title: "Cisco's Technology Networking Ecosystem",
  description: "We invite you to dive deep with us into the world's oceans as we unravel the harmonious integration of wired and wireless technologies that shape the future of internet and bring the possibilities of secure connectivity. Here's a transformative journey of 5G and optical into the exciting technological landscape of tomorrow. Available only on purchase of a combo. This workshop will only be held offline",
  venue: "IIT Madras",
  venueLink: "https://www.google.com/maps/place/Indian+Institute+Of+Technology%E2%80%93Madras+(IIT%E2%80%93Madras)/@12.9907198,80.2326229,17.92z/data=!4m15!1m8!3m7!1s0x3a526781f3ce0c6b:0x41368df84c6db350!2sIndian+Institute+Of+Technology,+Chennai,+Tamil+Nadu!3b1!8m2!3d12.9915639!4d80.2336857!16s%2Fg%2F12j6gq6n3!3m5!1s0x3a5267f29aa9a61f:0x24ef264085e6a094!8m2!3d12.9914929!4d80.2336907!16zL20vMGd5eHdk?entry=ttu",
  poster: "https://drive.google.com/uc?export=view&id=1BwyX7mIiNKhVpdF21ZTSXnBVL0CZuLM9",
  cost: 0,
  basket: "3",
  contentDoc: "https://drive.google.com/file/d/1fflNH-ayEE9BgvtYkRg8NmbIIrfkCKYv/view?usp=sharing",
  contactNumber: "6003072065",
  workshopDate: new Date("1/6/2024")
}

export const autodeskWorkshop: AutodeskType =  {
  id: "101",
  title: "Generative design for manufacturing applications",
  description: "This advanced computation delivers outcomes that work in the real world. Let's explore generative design workflows that drive additive manufacturing, part consolidation, performance, weight reduction, and fluid flow. This workshop will only be held offline",
  venue: "IIT Madras",
  venueLink: "https://www.google.com/maps/place/Indian+Institute+Of+Technology%E2%80%93Madras+(IIT%E2%80%93Madras)/@12.9907198,80.2326229,17.92z/data=!4m15!1m8!3m7!1s0x3a526781f3ce0c6b:0x41368df84c6db350!2sIndian+Institute+Of+Technology,+Chennai,+Tamil+Nadu!3b1!8m2!3d12.9915639!4d80.2336857!16s%2Fg%2F12j6gq6n3!3m5!1s0x3a5267f29aa9a61f:0x24ef264085e6a094!8m2!3d12.9914929!4d80.2336907!16zL20vMGd5eHdk?entry=ttu",
  poster: "https://drive.google.com/uc?export=view&id=1ZYiTs-IbGBdtNLsqSOQmpp11cGlWLKU8",
  cost: 0,
  basket: "3",
  contentDoc: "https://docs.google.com/document/d/11F6IJbKku64bLSxwLmh2TBiLHJS60EzrNcfhjegqCf8/edit#heading=h.sw869uvedgv5",
  contactNumber: "9381172054",
  workshopDate: [new Date("1/4/2024"), new Date("1/5/2024")]
}