import React, { useState, useEffect } from "react";
import "./dropdown.scss";
import downArrow from "../../assets/icons/downArrow.svg";

type DropdownProps = {
  options: Option[];
  placeholder: string;
  onSelect: (e: any) => void;
  theme?: "light" | "dark";
  error?: boolean
  errorMsg?: string
};

type Option = {
  icon?: string;
  value: string;
  valueStore?: string
};

const Dropdown: React.FC<DropdownProps> = ({
  placeholder,
  options,
  onSelect,
  theme,
  error,
  errorMsg
}) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [selectedPlaceholder, setSelectedPlaceholder] =
    useState<string>(placeholder);

  const clickOutside = (e: any) => {
    // if(e.target.classList.contains("dropdown")){
    //     setShowMenu(false)
    // }
    if (e.target.className !== "placeholder") {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", clickOutside);

    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, []);

  return (
    <div className="dropdown">
      <div
        className={`placeholder-container ${theme}`}
        onClick={() => setShowMenu(true)}
      >
        <div className={`placeholder`}>{selectedPlaceholder}</div>
        <img alt="" height={"18px"} src={downArrow} />
      </div>
      <div className={`menu ${showMenu ? "active" : ""} ${theme}`}>
        {options.map((option, index) => (
          <div
            key={index}
            className="option-bar"
            onClick={() => {
              onSelect(option.valueStore !== undefined ? option.valueStore : option.value);
              setSelectedPlaceholder(option.value);
            }}
          >
            {option.icon ? <img alt="icon" src={option.icon} /> : null}
            <div>{option.value}</div>
          </div>
        ))}
      </div>
      {error && errorMsg ? 
                            <div className='error-msg'>{errorMsg}</div> : null
                        }
    </div>
  );
};
export default Dropdown;
