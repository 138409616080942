import React, { useState } from 'react';
import './paymentWarning.scss'
import { RootState } from '../../redux/reducers';
import { useSelector } from 'react-redux';

type PaymentWarningProps = {
};

const PaymentWarning:React.FC<PaymentWarningProps> = () => {

    const { device } = useSelector((state: RootState) => state.windowSize);
    
    return (
        <div className={`warning-message ${device}`}>
            Please make sure not to exit the browser or go back before payment confirmation message is received from the website
        </div>
    )
}
export default PaymentWarning;