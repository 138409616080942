import React, { useContext, useEffect, useState } from 'react'
import Dropdown from '../../../ui-elements/dropdown/dropdown';
import './passport.scss'
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { loadScript } from '../../../utils/razorpayHelper';
import { CreatePassportMutation, UserRole, useCapturePassportPaymentMutation, useCreatePassportMutation, useGetMyPassportQuery, useGetPassportsQuery } from '../../../generated/graphql';
import { AuthContext } from '../../../utils/authProvider';
import GlitchTitle from '../../../animations/glitchTitle/glitchTitle';
import Navbar from '../../../ui-elements/navbar/navbar';
import MessagePopup from '../../../ui-elements/messagePopup/messagePoup';
import { useNavigate } from 'react-router-dom';
import { useWorkshopList } from '../../../utils/workshops';
import PaymentWarning from '../../../ui-elements/paymentWarning/paymentWarning';
import { CSVLink } from 'react-csv';

const Passport = () => {
  const [passportType, setPremium] = useState<string>("");
  const [passportTypeError, setPassportTypeError] = useState<boolean>()
  
  const [areYou, setAreYou] = useState(false);

  const [registered, setRegistered] = useState(false);

  const { workshopList } = useWorkshopList()

  useEffect(() => {
    if(workshopList) {
      if(workshopList.some(item => item.registered)) setRegistered(true)
      
    }
  }, [workshopList]);

  const navigate = useNavigate()
  
  const { device } = useSelector((state: RootState) => state.windowSize);

    const { user } = useContext(AuthContext);

    const [
    captureAccommodationPaymentMutation,
    { data: captureData, loading: captureLoading, error: captureError },
  ] = useCapturePassportPaymentMutation();

  const [createPassportMutation, { data: createData, loading: createLoading, error: createError }] = useCreatePassportMutation({
    async onCompleted(data) {
      if(data.createPassport) {
        await loadRazorpay(data)
      }
    }
  })

  const { data, loading, error } = useGetMyPassportQuery()

  const isIITM = user.email.slice(-16) === "smail.iitm.ac.in"
  const isIITMVerified = isIITM && user.emailVerified
  const isIITMNotVerified = isIITM && !user.emailVerified

  const { data: passportData, loading: passportLoading, error: passportError } = useGetPassportsQuery()

  

  const loadRazorpay = async (data: CreatePassportMutation) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load");
      return;
    }
    let amount = 699;
    if( data.createPassport.passportType === "Premium") amount = 599;
    else if( data.createPassport.passportType === "Standard") amount = 399;
    else if( data.createPassport.passportType === "Standard (discounted for Workshops)") amount = 299;
    else if( data.createPassport.passportType === "Platinum (discounted for IITM Student)") amount = 299;
    else if( data.createPassport.passportType === "Premium (discounted for IITM Student)") amount = 199;
    
    const options = {
      key: "rzp_live_FYFoqAtKLutwZf",
      amount: amount * 100,
      currency: "INR",
      name: "Shaastra Passport Registration",
      image: "../../assets/logos/ShaastraLogoBlack.png", //TODO: Add the shaastra link here
      order_id: data.createPassport.orderId,

      handler: async function (res: any) {
        try {
          await captureAccommodationPaymentMutation({
            variables: {
              data: {
                orderId: res.razorpay_order_id,
                paymentId: res.razorpay_payment_id,
                paymentSignature: res.razorpay_signature,
              },
            },
          });
        } catch (e) {
          console.log(e);
        }
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.mobile,
      },
      notes: {
        type: "Shaastra Passport Registration",
      },
    };

    const rzp1 = new (window as any).Razorpay(options);
    rzp1.open();
  };

  const onSubmit = async () => {
    if(!passportType) {
      setPassportTypeError(true)
      return
    }
    try {
      await createPassportMutation({variables: {data: {passportType}}})
    } catch(err) {
      console.log(err)
    }
  }


  const [goToOTP, setGoToOTP] = useState(false);

  useEffect(() => {
    if(user.shaastraID !== "TESTID" && !user.emailVerified) {
      setGoToOTP(true)
      setTimeout(() => {
        setGoToOTP(false)
      }, 5000);
    }
  }, [user]);
  
  return (
    <div className='passport' >
      { goToOTP && <MessagePopup
          onClick={() => navigate('/verify')}
          message={"Verify your email here"}
          position={"top-center"}
          category={"warning"}
        /> }
      {captureError && (
        <MessagePopup
          message={"Eror in Payment Capture"}
          position={"top-center"}
          category={"warning"}
        />
      )}
      {createError && (
        <MessagePopup
          message={"Error in Registering Passport"}
          position={"top-center"}
          category={"warning"}
        />
      )}
      {(createLoading || captureLoading) && (
        <MessagePopup
          message={"Loading..."}
          position={"top-center"}
          category={"info"}
        />
      )}
      {createData && (
        <MessagePopup
          message={"Passport Created. You can pay now"}
          position={"top-center"}
          category={"success"}
          delay={3}
        />
      )}
      {captureData && (
        <MessagePopup
          message={"Payment Confirmed"}
          position={"top-center"}
          category={"success"}
        />
      )}
      <Navbar page='Passport' />
      <GlitchTitle title="Passport" />
      <div
        className="conditions"
        onClick={() => {
          window.open(
            "https://drive.google.com/file/d/1OEvg6jkAS9uTDKs8nShWGZaV1gYlqKBg/view?usp=sharing"
          );
        }}
      >
        Please go through the policies and guidelines before buying the passport
        <u>Click here</u>
      </div>
      
      {user.shaastraID === "TESTID" ? 
      <>
      <div className='message login' onClick={() => navigate("/login")}>Login to continue</div>
      {areYou ? <>
        <div className='message workshop-link' onClick={() => navigate("/login")}>Login with smail and verify your account to avail the discounted Passport</div>
      </>
       : <div className='message workshop-link' onClick={() => setAreYou(true)}>Are you an IITM Student?</div>}
      </> :
      data?.getMyPassport.paid ? <div className='message'>You have registered for a {data.getMyPassport.passportType} Passport with id: {data.getMyPassport.passportId}, you can show this page of the website and collect a physical passport from the passport desk in Shaastra</div> :
      <>
      {!registered && !isIITMVerified &&
      <div
        className="message workshop-link"
        onClick={() => {
          navigate('/workshops')
        }}
      >
        Buy workshops to avail discounted passport
      </div>
      }
      {isIITMNotVerified ?
      <div
        className="message workshop-link"
        onClick={() => {
          navigate('/verify')
        }}
      >
        Verify email to avail the discounted passport
      </div> : isIITMVerified ? <div className='message'>You have access to free Standard Passport</div> : null
      }
      <div className={`dropdown-wrapper ${device}`}>
        <div className={`dropdown-label`}>Select Passport Type*</div>
        <Dropdown
          onSelect={(value) => {
            setPremium(value);
            setPassportTypeError(false)
          }}
          options={isIITMVerified ? [
            {value: "Premium (discounted for IITM Student)"},
            {value: "Platinum (discounted for IITM Student)"}
          ] :[
            { value: `Standard${registered ? " (discounted for Workshops)" : ""}` },
            { value: `Premium` },
            { value: `Platinum` },
          ]}
          placeholder={"Select Passport Type"}
          errorMsg={"Select a Passport Type"}
          error={passportTypeError}
        />
      <PaymentWarning />
        <button className="SubmitBtn" onClick={onSubmit}>
          Submit
        </button>

        {user.role === UserRole.Admin && 
        <>
        <CSVLink filename={`Passport.csv`} data={passportData?.getPassports ? passportData.getPassports.map(item => ({...item, name: item.user?.name, email: item.user?.email, mobile: item.user?.mobile, shaastraID: item.user?.shaastraID})) : []}>Download Passport Data</CSVLink>
        </>}
    </div>
      </>
      }
    </div>
  )
}

export default Passport
