import React from "react";
import "./recognitions.scss";
import unesco from '../../../../assets/images/recognitions/unesco_white-removebg-preview.png'
import startupind from '../../../../assets/images/recognitions/startupind.png'
import iso_certificate from '../../../../assets/images/recognitions/iso_certificate.png'
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";

type RecognitionsProps = {};

const Recognitions: React.FC<RecognitionsProps> = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);
  return (
    <div className={`recognitions ${device}`}>
      <div className="section">
        <div className="heading">Our Patrons</div>

        <div className="recognition-items">
          <div className="recognition-item">
            <img alt="" src={unesco} className="unesco" />
            <div className="item-desc">For promotion of technical knowledge</div>
          </div>

          <div className="recognition-item">
            <img alt="" src={startupind} className="startup-ind" />
            <div className="item-desc">For promotion of Startup Industry</div>
          </div>
        </div>

      </div>

      <div className="section">
        <div className="heading">ISO Certification</div>

        <div className="recognition-items">
            <div className="recognition-item">
                <img alt="" src={iso_certificate} className="iso"/>
                <div className="item-desc">ISO 9001:2015 certified since 2004</div>
            </div>
        </div>
      </div>
    </div>
  );
};
export default Recognitions;
