import React from "react";
import "./exhiContainer.scss";
import { useParams } from "react-router-dom";
import { currentExhibitions, pastExhibitions } from "../../utils/Helpers";
// import { ExhibitionItem } from "../../types/exhibitions";
import { RootState } from "../../redux/reducers";
import { useSelector } from "react-redux/es/hooks/useSelector";

type ExhiContainerProps = {
  // title: string
  // image:string
  // desc:string
};
const ExhiContainer: React.FC<ExhiContainerProps> = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);

  const { index, past } = useParams();
  console.log(useParams());
  const { name, image, desc } =
    past === "past"
      ? pastExhibitions[parseInt(index!)]
      : currentExhibitions[parseInt(index!)];
  return (
    <div className="dwrapper">
      <div className={`area ${device}`}>
        <ul className={`circles ${device}`}>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <div className={`dcontainer ${device}`}>
        <div className={`containerTitle ${device}`}>{name}</div>
        <div className={`mcontainer ${device}`}>
          <img className={`containerImg ${device}`} src={image} alt=""/>
          <div className={`aboutContainer ${device}`}>{desc}</div>
        </div>
      </div>
    </div>
  );
};

export default ExhiContainer;
