import { useContext, useEffect, useState } from "react";
import {
    useGetEventSJsQuery,
  useGetMyEventSjIdsQuery,
} from "../generated/graphql";
import { AuthContext } from "./authProvider";
import { EventSJType } from "../types/eventsj";

export function useEventSJList() {
  const [eventsjList, setEventsjList] = useState<EventSJType[]>([]);

  const { user } = useContext(AuthContext);
  const { data, loading, error } = useGetEventSJsQuery();
  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch
  } = useGetMyEventSjIdsQuery();

  useEffect(() => {
    // console.log("hey")
    if (data) {
      // console.log("hey")
      setEventsjList(
        data.getEventSJs.map((eventsj, index) => {
          const poster =
            "https://drive.google.com/uc?export=view&id=" +
            eventsj.poster.slice(32, eventsj.poster.indexOf("/view"));
          const eventDate = new Date(eventsj.eventDate)
          return {
            ...eventsj,
            registered: userData?.getMyEventSJIds.includes(eventsj.id),
            poster,
            eventDate
          };
        })
      );
    }
  }, [data, userData]);

  // useEffect(() => {
  //   if(workshopList) {
  //     console.table(workshopList.map(item => ({name: item.title, num: item.workshopDate})))
  //   }
  // }, [workshopList]);

  useEffect(() => {
    if (error) console.log(error);
    if (userError) console.log(userError);
  }, [error, userError]);

  return { eventsjList, refetch };
}