import React from "react";
import "./footer.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import shaastraLogo from "../../assets/logos/25yrsLogoSilver.png";
import shaastraApp from "../../assets/images/ShaastraAppQR.png";
import youtubeIcon from "../../assets/images/youtubeIcon.png";
import instagramIcon from "../../assets/images/insta.png";
import linkedinIcon from "../../assets/images/linkedin.png";
import twitter from "../../assets/images/x.png";
import rightarrow from "../../assets/images/ph_arrow-right-light.svg";
import Email_icon from "../../assets/images/Email_icon.svg";
import { useLocation } from "react-router-dom";

type FooterProps = {};

const Footer: React.FC<FooterProps> = () => {
  const location = useLocation();
  const { device } = useSelector((state: RootState) => state.windowSize);

  return (
    <div
      className={`main-section ${device} ${
        location.pathname === "/login" || location.pathname === "/register"
          ? "no"
          : ""
      }`}
    >
      <div className={`footer-sec ${device}`}>
        <div className={`first-column ${device}`}>
          <img
            className={`shaastra_logo_img ${device}`}
            src={shaastraLogo}
            alt="Shaastra_Image"
          />
          <div className="shaastra_app">
          <img
            className={`shaastra_app_img ${device}`}
            src={shaastraApp}
            alt="Shaastra_App"
            />
            <div className="shaastra_app_link" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.shaastra.shaastra_app")}>
              <u>
            Download our Shaastra App now
              </u>
            </div>
          </div>
          <div className={`false-connect-us-row ${device}`}>
            <hr className={` hline ${device}`}></hr>
            <div className={`footer-header ${device}`}>Connect with Us</div>
            <div className={`newsletter-des ${device}`}>
              <div className={`icons ${device}`}>
                <a href="#">
                  <img
                    className={`icons-img ${device}`}
                    src={youtubeIcon}
                    alt="Facebook"
                  />
                </a>
                <a href="#">
                  <img
                    className={`icons-img ${device}`}
                    src={instagramIcon}
                    alt="Instagram"
                  />
                </a>
                <a href="#">
                  <img
                    className={`icons-img ${device}`}
                    src={twitter}
                    alt="twitter"
                  />
                </a>
                <a href="#">
                  <img
                    className={`icons-img ${device}`}
                    src={linkedinIcon}
                    alt="Linkedin"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={`mid-section ${device}`}>
          <div className={`logo-des ${device}`}>
            <div className={`logo-des-title ${device}`}>Address</div>
            <div className={`logo-des-para ${device}`}>
              IITM Students Activities Trust Shaastra,
              <br />
              1st Floor, O/o Dean Of Students Office,
              <br />
              IIT Madras, Guindy, Chennai,
              <br />
              Tamil Nadu, India - 600036
            </div>
          </div>

          <div className={`office-row ${device}`}>
            <div className={`footer-header ${device}`}>Contact us</div>
            <div className={`office-des ${device}`}>
              <div className={`details ${device}`}>
                {/* 8825601711 <br />
                7305232246 <br /> */}
              </div>
              <a href="mailto:outreach@shaastra.org" style={{ color: "grey" }}>
                outreach@shaastra.org
              </a>
              {/* <div className={`num ${device}`}>+91-9999999999</div> */}
            </div>

            <div className={`complaints-row ${device}`}>
              <div className={`complaints-des-para ${device}`}>
                To lodge a complaint
                <br />
                click below,
              </div>
              <div className="complaints-btn">
                <a href="https://forms.gle/PR3hW1k7bK7ery1E9" target="_blank" style={{ color: "black" }}>
                  Complaints
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={`connect-us-row ${device}`}>
          <hr className={` hline ${device}`}></hr>
          <div className={`footer-header ${device}`}>Connect with Us</div>
          <div className={`newsletter-des ${device}`}>
            <div className={`icons ${device}`}>
              <a href="https://www.instagram.com/shaastra_iitm/?hl=en">
                <img
                  className={`icons-img ${device}`}
                  src={instagramIcon}
                  alt="Instagram"
                />
              </a>
              <a href="https://www.linkedin.com/company/shaastra-iit-madras/">
                <img
                  className={`icons-img ${device}`}
                  src={linkedinIcon}
                  alt="Linkedin"
                />
              </a>
              <a href="https://www.youtube.com/@iitmshaastra">
                <img
                  className={`icons-img ${device}`}
                  src={youtubeIcon}
                  alt="Youtube"
                />
              </a>
              <a href="https://twitter.com/shaastraiitm?lang=en">
                <img
                  className={`icons-img ${device}`}
                  src={twitter}
                  alt="twitter"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`copyright ${device}`}>
        <hr className="hline"></hr>
        <div className={`txt-copyright ${device}`}>
          © Developed by Shaastra Webops
        </div>
      </div>
    </div>
  );
};

export default Footer;
