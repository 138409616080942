import React from "react";
import "./eventMobileCard.scss";
import { EventType } from "../../../../types/event";
import locationImg from "../../../../assets/images/workshop-location.svg";
import feeImg from "../../../../assets/images/workshop-fees.svg";
import rsImg from "../../../../assets/images/workshop-rs.svg";
import btnArrowImg from '../../../../assets/images/workshop-btn-arrow.svg'
import { motion } from "framer-motion";
import { useNavigate } from "react-router";
import { EventSJType } from "../../../../types/eventsj";


type EventMobileCardProps = {
  event: EventType | EventSJType;
  index: number;
};

const EventMobileCard: React.FC<EventMobileCardProps> = ({
  event,
  index,
}) => {

  const navigate = useNavigate();
  
  return (
    <div className="workshop-mobile">
      <img className="poster" alt="" src={event.poster} />
      <div className="details">
        <div className="title">{event.title}</div>
        <div className={`fee-location-container`}>
          <div className={`fee-container`}>
            <img src={feeImg} alt="" className={`fee-locationImg`} />
            <div className={`registration`}>
              <div className={`title1-text`}>Prize Pool</div>
              <div className={`title2-text`}>
                <span>
                  <img src={rsImg} alt="" className={`rsImg`} />
                </span>
                {event.amount}
              </div>
            </div>
          </div>
          <div className={`location-container`}>
            <img src={locationImg} alt="" className={`fee-locationImg`} />
            <div className={`location`}>
              {/* <div className={`title1-text`}>LOCATION</div> */}
              <div className={`title2-text`}>{event.venue}</div>
            </div>
          </div>
        </div>

        <button
          className={`workshop-btn`}
          onClick={() =>  'registered' in event ? navigate(`/sj-events/${event.id.slice(-5)}`) : navigate(`/events/${event.id.slice(-5)}`)}
        >
          MORE DETAILS &nbsp;
          <span>
            <motion.img
              src={btnArrowImg}
              alt=""
              className={`btnArrow`}
            />
          </span>
        </button>
      </div>
    </div>
  );
};
export default EventMobileCard;
