import React, { useContext, useEffect, useState } from "react";
import "./Openhouse.scss";
import oh1 from "../../../assets/images/Open House/CoEZET Photo - Senior Project Officer coezet.jpg";
import oh2 from "../../../assets/images/Open House/IMG_4423 - Venkata Balaji Te.jpg";
import mapIcon from "../../../assets/icons/map.svg";
import locationIcon from "../../../assets/icons/locationIcon.svg";
import timeIcon from "../../../assets/icons/timeIcon.svg";

import GlitchTitle from "../../../animations/glitchTitle/glitchTitle";
import Navbar from "../../../ui-elements/navbar/navbar";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useNavigate } from "react-router-dom";
import { OHData } from "../../../types/openhouse";
import { AuthContext } from "../../../utils/authProvider";

const OpenHouse: React.FC = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);

  const navigate = useNavigate();

  const [deets, setDeets] = useState<OHData[]>([]);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbxZ4m2XxbYgWeS-oz-RsNQJVUqV2Sab6o4FQF3OyB4kS3cLnZYG94rEPA9cr1Uiwfv4/exec?path=Details"
    )
      .then((res) => res.json())
      .then((data) => {
        setDeets(data);
      });
  }, []);

  const [search, setSearch] = useState("");

  return (
    <div className={`openhouse_page ${device}`}>
      <Navbar page="Open House" />
      <div className="title-wrapper">
        <GlitchTitle title="Institute Open House" />
      </div>

      {/* <div className={`details ${device}`}>
        IITM for All, hosted by Shaastra, the annual techno entertainment fest
        of IITM, is a quest through the institute's ongoing research and lab
        facilities. This event, scheduled from the 2nd to the 3rd of March, is
        promised to be an enriching experience where the institution's offerings
        can be explored, engagement with faculty members can occur, and the
        impactful research within IITM's walls can be learned about.
      </div>
      <br />
      <div className={`details ${device}`}>
        This year, intriguing and descriptive offline lab tours will be
        conducted to the audience as a part of IITM for All. Covering each
        department and its wide range of labs, it allows researchers on the
        campus to showcase their fascinating work to the students. Whether
        you're a prospective student, a community member, or a professional
        interested in our research initiatives, attendance at this event is
        promised to be informative and inspiring. It is to be emphasised that
        attending IITM for All is entirely free for all guests. There are no
        registration fees or charges associated with this event.
      </div> */}
      <div className={`details ${device}`}>
        Are you excited to see IIT Madras? Please look for the emails that has been sent to the registered participants. Log in and book your slots
      </div>


      {user.shaastraID === "TESTID" && 
      <div className="oh-button-wrapper">
        <div
          className={`oh-button ${device}`}
          onClick={() => navigate("/login")}
        >
          Login to see Open House Registration status
        </div>
      </div>
      }

      {user.disabled ? 
      <div className={`oh-reg-data-wrapper disabled ${device}`} >
        <div>Upon reviewing the uploaded identity proofs for registered attendees, it has come to our attention that the proof provided by you did not meet the necessary validity criteria.</div>
        <div>As a result, your Shaastra ID has been disabled, and entry to the IITM Open House event is prohibited.</div>
        </div> : 
      
      user.personalId ?
      <div className={`oh-reg-data-wrapper ${device}`} onClick={() => navigate('/open-house/slots')}>
        <div className="oh-reg-data">You have been successfully registered for Institute Open House.
        {!user.noOfPeople ? 
        <div>You have registered for 1 person.</div> :
        user.noOfPeople === 1 ? 
        <div>You may be accompanied by 1 person.</div> :
        <div>You may be accompanied by {user.noOfPeople} people.</div>

      }
      <div>Book your slots now!!</div> 
      {/* {user.userSlots?.id ? 
      <div>Your slots have been booked</div>
      } */}
        </div>
      </div> : null
        }

        <div className="map-search-wrapper">

          <button className="map-button" onClick={() => window.open('https://drive.google.com/file/d/1RQPLaEsFtl0-fJNVeET7sLvmmO7g6fw-/view?usp=sharing', '_blank')}><img src={mapIcon}></img>Checkout our Institute Map</button>
          
          <input className={`search ${device}`} onChange={(e) => setSearch(e.target.value)} placeholder={"Search Event"}></input>
        </div>
      

      {deets.filter(item => {
            return item.title.toLowerCase().includes(search.toLowerCase())
        }
            ).map((item, index) => {
        return (
          <div key={index} className={`${index % 2 === 0 ? "left" : "right"} ${device}`}>
            <div className="oh-image-wrapper">
              {/* <div className="oh-image-border"> */}
              <img src={item.image} alt="" className={`oh-image ${device}`} />
              {/* </div> */}
            </div>
            <div className="description">
              <div className="oh-title">{item.title}</div>
              <div className={`time-venue ${device}`}>
                <div className="time">
                  <img src={timeIcon} alt="Time Icon" height={"30px"} />
                  <div className="times">
                    <div>{item.time}</div>
                    <div>{item.date}</div>
                  </div>
                </div>
                <div
                  className="venue"
                  onClick={() => window.open(item.venueLink || "")}
                >
                  <img src={locationIcon} alt="Venue Icon" height={"30px"} />
                  <span>{item.venue}</span>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      
    </div>
  );
};

export default OpenHouse;
