import React from "react";
import "./envisage.scss"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";

type EnvisageSectionProps = {
    title: string
    desc: string
    projectImages: CardType[]
    time: string
    venue: string
};


type CardType = {
    image?: string,
    heading: string,
    para: string,
}

const EnvisageSection: React.FC<EnvisageSectionProps> = ({ title, desc, projectImages, time, venue }) => {

    const { device } = useSelector((state: RootState) => state.windowSize);
  
        
    return (
        <div className="envisage-section" id="envisage-section">

            <div className={`title ${device}`} id="𝑮𝒂𝒎𝒆 𝒅𝒆𝒗">{title}</div>
            <div className={`desc ${device}`}>{desc}</div>


            <div className={`card-wrapper ${device}`} id="card">
              <Swiper
                    slidesPerView={device === "laptop" ? 1: 1}
                    spaceBetween={40}
                    loop={true}
                    
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className={`mySwiperEnvisage ${device}`}
                >

                    {projectImages.map((carditem) => (
                        <SwiperSlide className="my-swiperslide">
                            <div className={`card ${device} ${carditem.image ?'': 'no-image'}`}>
                                <div className={`img-wrapper ${device} ${carditem.image ?'': 'no-image'}`}>
                                    <img src={carditem.image} alt="" className="img" />
                                </div>
                                
                                <div className={`card-info ${device} ${carditem.image ?'':'no-image'}`}>
                            
                                    <div className={`envisage-heading ${device}`} id="envisage-heading">
                                        {carditem.heading}
                                    </div>
                                    <div className={`envisage-para ${device}`} id="envisage-para">
                                        {carditem.para}
                                    </div>
                                </div>

                            </div>
                           
                           
                        </SwiperSlide>
                    ))}
                </Swiper>


            </div>

        </div>

    )

}




export default EnvisageSection;