import React from "react";

import "./exhiCard.scss";
import { ExhibitionItem } from "../../types/exhibitions";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/reducers";
import { useSelector } from "react-redux/es/hooks/useSelector";

type ExhiCardProps = {
  exhibition: ExhibitionItem;
  index: number;
  past?: boolean;
};
const ExhiCard: React.FC<ExhiCardProps> = ({ exhibition, index, past }) => {
  const { device } = useSelector((state: RootState) => state.windowSize);

  const navigate = useNavigate();

  return (
    <div className={`exhiContainer ${device}`}>
      <img className={`boxImg ${device}`} src={exhibition.image} alt=""/>
      {exhibition.year ? (
        <div className={`boxHeading ${device}`}>{exhibition.year}</div>
      ) : null}
      <div className={`boxTitle ${device}`}>{exhibition.name}</div>
      <div className={`boxPara ${device}`}>{exhibition.desc}</div>
      <div
        className={`viewDetailsButton ${device}`}
        onClick={() =>
          navigate("/exhibitions/" + (past ? "past/" : "current/") + index)
        }
      >
        View Details
      </div>
    </div>
  );
};

export default ExhiCard;
