import React, { useContext, useEffect, useState } from "react";
import "./planetarium.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import {
  UserRole,
  useCreateUserSlotMutation,
  useGetMyUserSlotQuery,
  useGetSlotsQuery,
  useGetUserSlotsQuery,
} from "../../../generated/graphql";
import { AuthContext } from "../../../utils/authProvider";
import GlitchTitle from "../../../animations/glitchTitle/glitchTitle";
import Navbar from "../../../ui-elements/navbar/navbar";
import MessagePopup from "../../../ui-elements/messagePopup/messagePoup";
import { useNavigate } from "react-router-dom";
import InputFields from "../../../ui-elements/inputfields/input/input";
import { CSVLink } from "react-csv";
import { create } from "domain";

const OHSlots = () => {
  const [errorBool, setErrorBool] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [slotId, setSlotId] = useState("");

  const navigate = useNavigate();

  const { device } = useSelector((state: RootState) => state.windowSize);

  const { data: usersData, loading: usersLoading, error: usersError } = useGetUserSlotsQuery()

  const { user } = useContext(AuthContext);


  const [
    createUserSlotMutation,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateUserSlotMutation();

  useEffect(() => {
    if(createData?.createUserSlot) {
      setTimeout(() => {
        navigate('/open-house')
      }, 3000);
    }
  }, [createData]);

  const { data, loading, error } = useGetSlotsQuery();

  const { data: myData, loading: myLoading, error: myError } = useGetMyUserSlotQuery()

  const onSubmit = async () => {
    if(user.shaastraID === "TESTID") {
      navigate('/login')
      return
    }
    if (!slotId) {
      setErrorMsg("Select a slot")
      setErrorBool(true);
      setTimeout(() => {
        setErrorBool(false)
      }, 3000);
      return;
    }
    const slot = data?.getSlots.find(slot => slot.id === slotId)
    if (user.noOfPeople === undefined || user.noOfPeople === null || (user.noOfPeople + 1 + (slot?.noOfPeople || 0) > (() => slot?.date === "Mar 2" ? 400 : 1000)())) {
      setErrorMsg("Slot Over Booked")
      setErrorBool(true);
      setTimeout(() => {
        setErrorBool(false)
      }, 3000);
      return;
    }

    // console.log(user.noOfPeople, "here")

    try {
      await createUserSlotMutation({
        variables: { data: { noOfPeople: user.noOfPeople + 1, slotId } },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="planetarium">
      {errorBool && (
        <MessagePopup
          message={errorMsg}
          position={"top-center"}
          category={"warning"}
        />
      )}
      {createError && (
        <MessagePopup
          message={"Error in Registering Slot"}
          position={"top-center"}
          category={"warning"}
        />
      )}
      {(createLoading) && (
        <MessagePopup
          message={"Loading..."}
          position={"top-center"}
          category={"info"}
        />
      )}
      {createData && (
        <MessagePopup
          message={"Slot Registered."}
          position={"top-center"}
          category={"success"}
          delay={3}
        />
      )}
      <Navbar page="Open House" />
      <GlitchTitle title="Open House Slots" />

      {myData?.getMyUserSlot &&
      <div className={`registered-data ${device}`}>
        <div className="text">
      You have registered for:
        </div>
        <div className="wrap-data">{user.noOfPeople} People at {myData.getMyUserSlot.slot.timeRange} slot on {myData.getMyUserSlot.slot.date}</div>
      </div>
      }

      
      {/* {!myData?.getMyUserSlot &&  */}
      {!myData?.getMyUserSlot && 
      <>
<div className="planet-desc">The number near to the slots specify the remaining seats</div>
      <div className={`slot-day-wrapper ${device}`}>
        <div className="slot-day">
          {data?.getSlots.filter(slot => slot.date === "Mar 2").map((slot) => {
            return (
              <div className={`slot-wrapper ${device}`}>
              <div
                className={`slot-box ${slot.id === slotId ? "selected" : ""}`}
                onClick={() => setSlotId(slot.id)}
              >
                {slot.date}: {slot.timeRange}
              </div>
              <div className="slot-number">{(() => slot.date === "Mar 2" ? 400 : 1000)() - slot.noOfPeople}</div>
              </div>
            );
          })}
        </div>
        <div className="slot-day">
          {data?.getSlots.filter(slot => slot.date === "Mar 3").map((slot) => {
            return (
              <div className={`slot-wrapper ${device}`}>
              <div
                className={`slot-box ${slot.id === slotId ? "selected" : ""}`}
                onClick={() => setSlotId(slot.id)}
              >
                {slot.date}: {slot.timeRange}
              </div>
              <div className="slot-number">{(() => slot.date === "Mar 2" ? 400 : 1000)() - slot.noOfPeople}</div>
              </div>
            );
          })}
        </div>
      </div>
    <button className="SubmitBtn" onClick={onSubmit}>
    {user.shaastraID === "TESTID" ? "Login Before you continue" : "Submit"}
  </button>
      </>
}
      {/* } */}

  {
    user.role === UserRole.Admin &&
  <CSVLink filename={`OH_slots.csv`} data={data?.getSlots ? data.getSlots : []}>Download OH Slots Data</CSVLink>
  }
    </div>
  );
};

export default OHSlots;
