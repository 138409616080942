import React, { useState, useEffect } from "react";
import { RootState } from '../../redux/reducers';
import "./countdown.scss";
import { useSelector } from "react-redux";

type CountdownProps = {
  
}

const Countdown:React.FC<CountdownProps> = () => {


  const { device } = useSelector((state: RootState) => state.windowSize);
  
  
  const calculateTimeLeft = () => {
    let difference = +new Date(`1/3/2024 8:00`) - +new Date();
    let timeLeft = { days: 0, hrs: 0, mins: 0, secs: 0 };


    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hrs: Math.floor((difference / (1000 * 60 * 60)) % 24),
        mins: Math.floor((difference / 1000 / 60) % 60),
        secs: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };
  const [time, setTime] = useState({
    days: 0,
    hrs: 0,
    mins: 0,
    secs: 0,
  });

  useEffect(() => {
    setInterval(() => setTime(calculateTimeLeft()), 1000);
  }, []);

  return (
    <div
      className={`theme-body ${device}`}
    >
      {/* <div className='tap-phrase'>
            Tap to see more!
        </div>          */}
      <h1 className={`countdown-heading ${device}`}>
        {/* <span aria-hidden="true">Time until contact</span> */}
        in
        {/* <span aria-hidden="true">Time until contact</span> */}
      </h1>

      <div className="countdown">
        <div className={`card ${device}`}>
          <div className={`card-top glitch ${device}`} id="days">
            {time.days}
          </div>
          <div className="card-bottom">
            <p>DAYS</p>
          </div>
        </div>
        <span></span>
        <div className={`card ${device}`}>
          <div className={`card-top glitch ${device}`} id="hours">
            {time.hrs}
          </div>
          <div className="card-bottom">
            <p>HOURS</p>
          </div>
        </div>
        <span></span>
        <div className={`card ${device}`}>
          <div className={`card-top glitch ${device}`} id="mins">
            {time.mins}
          </div>
          <div className="card-bottom">
            <p>MINS</p>
          </div>
        </div>
        <span></span>
        <div className={`card ${device}`}>
          <div className={`card-top glitch ${device}`} id="secs">
            {time.secs}
          </div>
          <div className="card-bottom">
            <p>SECS</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
