import React, { useContext, useEffect, useState } from "react";
import "./stats.scss";
import { useSelector } from "react-redux";
import { AuthContext } from "../../../utils/authProvider";
import { RootState } from "../../../redux/reducers";
import GlitchTitle from "../../../animations/glitchTitle/glitchTitle";
import { CSVLink } from "react-csv";
import { GetPassportsQuery, GetPassportsQueryResult, useGetPassportsQuery } from "../../../generated/graphql";

type PassportStatsProps = {};

const PassportStats: React.FC<PassportStatsProps> = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);

const [passportData, setPassportData] = useState<GetPassportsQuery>();

  const {
    data,
    refetch
  } = useGetPassportsQuery();

  useEffect(() => {
    if(data) {
        setPassportData(data)
    }
  }, [data]);

  const onSubmit = async () => {
    const {data: tempData} = await refetch()
    setPassportData(tempData)
  }


  const [search, setSearch] = useState("");

  return (
    <div className="stats-page">
      <GlitchTitle title="Passport Stats" />

<div className="inputs">

      <input onChange={(e) => setSearch(e.target.value)} placeholder="Enter shaastraID"></input>
      <button onClick={onSubmit} >Refetch New Passport</button>
</div>

      <table>
        <tr>
          <td>ShaastraID</td>
          <td>Passport Type</td>
          <td>Name</td>
          <td>Email</td>
          <td>Mobile</td>
          <td>PassportID</td>
          <td>Amount</td>
        </tr>
        {passportData?.getPassports.filter(item => {
            return item.user?.shaastraID.toLowerCase().includes(search.toLowerCase())
        }
            ).map((item) => {
          return (
            <tr>
              <td>{item.user?.shaastraID}</td>
              <td>{item.passportType}</td>
              <td>{item.user?.name}</td>
              <td>{item.user?.email}</td>
              <td>{item.user?.mobile}</td>
              <td>{item.passportId}</td>
              <td>{item.amount}</td>
            </tr>
          );
        })}

        {/* <tr><td className="stat">Number of Single Workshops Sold </td><td>{datac1?.getUserWorkshopsCountByCombos}</td></tr>
    <tr><td className="stat">Amount from Single Workshops Sold </td><td>Rs. {data1?.getUserWorkshopsAmountByCombos}</td></tr>
    <tr><td className="stat">Number of 2 Combos Sold </td><td>{datac2?.getUserWorkshopsCountByCombos}</td></tr>
    <tr><td className="stat">Amount from 2 Combos Sold </td><td>Rs. {data2?.getUserWorkshopsAmountByCombos}</td></tr>
    <tr><td className="stat">Number of 3 Combos Sold </td><td>{datac3?.getUserWorkshopsCountByCombos}</td></tr>
    <tr><td className="stat">Amount from 3 Combos Sold </td><td>Rs. {data3?.getUserWorkshopsAmountByCombos}</td></tr>
    <tr><td className="stat">Total Amount </td><td>Rs. {(data3 && data2 && data1) && data3?.getUserWorkshopsAmountByCombos + data2?.getUserWorkshopsAmountByCombos + data1?.getUserWorkshopsAmountByCombos}</td></tr>
    <tr><td className="stat">Cisco Registrations Number</td><td>{dataca?.getCiscoAuto.cisco.length}</td></tr>
    <tr><td className="stat">Autodesk Registrations Number</td><td>{dataca?.getCiscoAuto.autodesk.length}</td></tr> */}
      </table>
    </div>
  );
};
export default PassportStats;
