import React from 'react';
import './sales.scss'
import Navbar from '../../../ui-elements/navbar/navbar';

type SalesProps = {
    
};

const Sales:React.FC<SalesProps> = () => {
    
    return (
        <div className='sales-page'>
            <Navbar page='Sales'/>
            <div className='sales-content'>Sales for Shaastra 2024 are closed</div>
        </div>
    )
}
export default Sales;