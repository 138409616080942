import React, { useContext, useEffect, useState } from "react";
import "./workshopDetails.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { useNavigate, useParams } from "react-router-dom";
// import workshop_poster from "../../../../assets/images/event-workshop.png";
import btnArrowImg from "../../../../assets/images/workshop-btn-arrow.svg";
import rsImg from "../../../../assets/images/workshop-rs.svg";
import WorkshopRules from "../Rules/workshopRules";
import Navbar from "../../../../ui-elements/navbar/navbar";
import { ciscoWorkshop, useWorkshopList } from "../../../../utils/workshops";
import { AuthContext } from "../../../../utils/authProvider";
import timeIcon from "../../../../assets/icons/timeIcon.svg";
import locationIcon from "../../../../assets/icons/locationIcon.svg";
import fileIcon from "../../../../assets/icons/fileIcon.svg";
import phoneIcon from "../../../../assets/icons/phoneIcon.svg";
import downArrow from "../../../../assets/icons/downArrow.svg";
import Faq from "./faq/faq";
import { FAQ } from "../../../../types/faq";
import {
  UserRole,
  useGetUserWorkshopsByIdQuery,
  useGetUserWorkshopsCountByIdQuery,
} from "../../../../generated/graphql";
import { WorkshopType } from "../../../../types/workshop";
import { CSVLink } from "react-csv";

type WorkshopDetailsProps = {};

type UsersDataType = {
  user: {
    email: string;
    name: string;
    mobile: string;
  };
  merch: string
};

const WorkshopDetails: React.FC<WorkshopDetailsProps> = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);

  const [noOfRegs, setNoOfRegs] = useState(0);
  const [usersData, setUsersData] = useState<UsersDataType[]>([]);

  const { id } = useParams();

  const { user } = useContext(AuthContext);

  const { workshopList } = useWorkshopList();

  let workshop: WorkshopType | undefined;
  if (workshopList) {
    workshop = workshopList.find((ws) => ws.id.slice(-5) === id);
  }
  if (!workshop) workshop = ciscoWorkshop;

  const { refetch } = useGetUserWorkshopsCountByIdQuery({
    variables: { getUserWorkshopsCountByIdId: "" },
    skip: true,
  });
  const { refetch: usersRefetch, data } = useGetUserWorkshopsByIdQuery({
    variables: { getUserWorkshopsByIdId: "" },
    skip: true,
  });

  const registerable = new Date() < new Date(`2024-01-0${(workshop.workshopDate?.getDate() || 3) - 1}T16:30:00.000Z`)

  useEffect(() => {
    if (id !== "100" && workshop) {
      const func = async () => {
        const { data } = await refetch({
          getUserWorkshopsCountByIdId: workshop?.id || "",
        });
        setNoOfRegs(data ? data.getUserWorkshopsCountById : 0);

        const { data: usersDataX } = await usersRefetch({
          getUserWorkshopsByIdId: workshop?.id || "",
        });
        setUsersData(usersDataX.getUserWorkshopsById);
      };
      try {
        func();
      } catch (err) {
        console.log(err);
      }
    }
  }, [workshop]);

  const navigate = useNavigate();

  const [showFAQs, setShowFAQs] = useState<boolean>(false);

  const faqs: FAQ[] = [
    {
      ques: "Will the workshop be offline or online?",
      ans: "Both the workshop sessions will be held offline at IIT Madras campus",
    },
    {
      ques: "Will I receive a certificate after the workshop?",
      ans: "Yes, all registered participants will receive a certificate from IIT Madras on completion of both sessions of the workshop",
    },
    {
      ques: "Do I need to carry a laptop for the workshop?",
      ans: "Kindly check the content doc of the respective workshop to know about any prerequisites",
    },
    {
      ques: "How do I register for multiple workshops?",
      ans: "Kindly check the guidelines to know how to avail our combo offers",
    },
  ];

  return (
    <>
      <Navbar page="Workshops" />

      <div className={`details-container ${device}`}>
        {workshop ? (
          <div className={`wrapper ${device}`}>
            <div className={`left-container ${device}`}>
              <div className={`about-workshop ${device}`}>
                <div className={`title ${device}`}>{workshop!.title}</div>
                <div className={`desc ${device}`}>{workshop!.description}</div>
              </div>
              <div className="phone-doc-wrapper">
                {workshop.contentDoc && (
                  <div
                    className="phone-doc content-doc"
                    onClick={() => window.open(workshop?.contentDoc || "")}
                  >
                    <img src={fileIcon} alt="Time Icon" height={"20px"} />
                    <span>Content Doc</span>
                  </div>
                )}
                {workshop.contactNumber && (
                  <div className="phone-doc">
                    <img src={phoneIcon} alt="Time Icon" height={"20px"} />
                    <div>
                      <span>{workshop.contactName}</span>
                      <br />
                      <span>{workshop.contactNumber}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="event-details">
                <div className="date-month">
                  <div className="month">Jan</div>
                  <div className="date">{workshop.workshopDate?.getDate()}</div>
                </div>
                <div className="time-venue-wrapper">
                  <div className="closing">
                    Registration closes on Jan{" "}
                    {(workshop.workshopDate?.getDate() || 3) - 1}, 10:00 PM
                  </div>
                  <div className={`time-venue ${device}`}>
                    <div className="time">
                      <img src={timeIcon} alt="Time Icon" height={"30px"} />
                      <div className="times">
                        <div>9:00 AM - 12:00 PM</div>
                        <div>
                          {workshop.eveningStartTime === 1
                            ? "1:00 PM - 4:00 PM"
                            : "2:00 PM - 5:00 PM"}
                        </div>
                      </div>
                    </div>
                    <div
                      className="venue"
                      onClick={() => window.open(workshop?.venueLink || "")}
                    >
                      <img
                        src={locationIcon}
                        alt="Venue Icon"
                        height={"30px"}
                      />
                      <span>{workshop.venue}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`price-container ${device}`}>
                <div className={`price-text ${device}`}>Price: </div>
                <div className={`rs-text`}>
                  <img src={rsImg} alt="" className={`rsImg ${device}`} />
                  &nbsp;{workshop!.cost}
                </div>
              </div>
              {user.shaastraID !== "TESTID" && user.role === UserRole.Admin && (
                <div>
                  Number of participants: {noOfRegs} ||{" "}
                  <CSVLink
                    filename={`${workshop.title}.csv`}
                    data={usersData ? usersData.map(uw => ({...uw, ...uw.user})) : []}
                  >
                    Download {workshop.title} Data
                  </CSVLink>
                </div>
              )}
            </div>
            <div className={`right-container ${device}`}>
              <img
                src={workshop.poster}
                alt=""
                className={`posterImg ${device}`}
              />
              <button
                disabled={workshop.registered}
                className={`register-btn ${device} ${
                  (workshop.registered || !registerable) ? "registered" : ""
                }`}
                onClick={() => {
                  if(registerable) {
                    if(user.shaastraID === "TESTID") {
                      navigate('/login')
                    } else {
                      navigate('/register-workshop')
                    }
                  }
                }}
              >
                {workshop.registered ? "Registered" : registerable ? "Register" : "Registrations Closed"} &nbsp;
                {(workshop.registered || !registerable) || (
                  <span>
                    <img
                      src={btnArrowImg}
                      alt=""
                      className={`btnArrow ${device}`}
                    />
                  </span>
                )}
              </button>
            </div>
          </div>
        ) : null}
      </div>
      {workshop?.rules ? (
        <div className={`rules-container ${device}`}>
          <div className={`wrapper ${device}`}>
            <div className={`rules-text ${device}`}>Rules</div>
            <WorkshopRules rules={workshop!.rules} />
          </div>
        </div>
      ) : null}

      <div className="faqs-wrapper">
        <div className="faqs-container">
          <div
            className="FAQ"
            onClick={() => {
              setShowFAQs(!showFAQs);
            }}
          >
            FAQs
            <img
              className={`arrow ${showFAQs ? "up" : ""}`}
              src={downArrow}
              alt=""
            />
          </div>

          {showFAQs ? (
            <div className="faqs">
              {faqs.map((faq, index) => (
                <Faq index={index} faq={faq} faqs={faqs} />
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default WorkshopDetails;
