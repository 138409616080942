import { ExhibitionItem } from "../types/exhibitions";

//Past exhibition image imports
import exhi1 from "../assets/images/Exhibitions/i3.png";
import exhi2 from "../assets/images/Exhibitions/i6.png";
import exhi3 from "../assets/images/Exhibitions/i4.png";
import exhi4 from "../assets/images/Exhibitions/i7.png";
import exhi5 from "../assets/images/Exhibitions/i8.png";

//Current exhibition image imports
import agriCMSD from "../assets/images/Exhibitions/currentExhi/AgriCMSD.png";
import airdonex from "../assets/images/Exhibitions/currentExhi/airdonex.png";
import scientificthinking from "../assets/images/Exhibitions/currentExhi/scientificthinking.png";
import airosspace from "../assets/images/Exhibitions/currentExhi/airosspace.png";
import beebox from "../assets/images/Exhibitions/currentExhi/beebox.png";
import bioadd from "../assets/images/Exhibitions/currentExhi/bioadd.png";
import uwr from "../assets/images/Exhibitions/currentExhi/uwr.jpg";
import farmonaut from "../assets/images/Exhibitions/currentExhi/farmonaut.jpg";
import inconn from "../assets/images/Exhibitions/currentExhi/inconn.jpeg";
import karthikesh from "../assets/images/Exhibitions/currentExhi/karthikesh.png";
import mechimedtech from "../assets/images/Exhibitions/currentExhi/Mechimedtech.png";
import meritusai from "../assets/images/Exhibitions/currentExhi/meritusai.png";
import nauticalwings from "../assets/images/Exhibitions/currentExhi/nauticalwings.jpg";
import playtolearn from "../assets/images/Exhibitions/currentExhi/playtolearn.jpg";
import rapteebike from "../assets/images/Exhibitions/currentExhi/rapteebike.png";
import satori from "../assets/images/Exhibitions/currentExhi/satori.jpg";
import thaalam from "../assets/images/Exhibitions/currentExhi/thaalam.png";
import tiano from "../assets/images/Exhibitions/currentExhi/tiano.png";
import trimble from "../assets/images/Exhibitions/currentExhi/trimble.jpeg";
import vayujal from "../assets/images/Exhibitions/currentExhi/vayujal.png";
import vividobots from "../assets/images/Exhibitions/currentExhi/vividobots.jpg";

export const pastExhibitions: ExhibitionItem[] = [
  {
    name: "Defence Expo",
    desc: "The Defence Expo was an exhibition of the products of nascent defence technology start-ups,established manufacturers,Defence PSUs and the Indian Armed Forces.It was conducted over the course of the 4 days of Shaastra 2020. The Expo provided exposure to Defence Technology for the audience.",
    year: "2020",
    image: exhi4,
  },
  {
    name: "International Expo",
    desc: "Shaastra for the first time ever, presents International Exhibitions, in association with the US Consulate General of Chennai. The International Exhibits was on three broad perspectives - Academics, Research and Business.",
    year: "2019",
    image: exhi5,
  },

  {
    name: "Bike Expo",
    desc: "Shaastra 2018 unveiled the spectacular technological advancements in motorcycle history with its Bike Expo.",
    year: "2018",
    image: exhi2,
  },

  {
    name: "Defence and Aerospace models from ISRO",
    desc: "ISRO - GSLV and PSLV models were exhibited at Shaastra Exhibitions.",
    year: "2018",
    image: exhi4,
  },

  {
    name: "NAO: Autonomous Humanoid Robot",
    desc: "Manav: India's first 3D printed humanoid robot was developed by Diwakar Vaish of A-SET training institutes.",
    year: "2017",
    image: exhi3,
  },

  {
    name: "Vintage Car Exhibitions",
    desc: "Shaastra 2016 unveiled the spectacular technological advancements in automobile history with its very own Vintage Car Exhibition in collaboration with the Madras Heritage Motoring Club. Presented automobiles such as Austin 8, 1952 Topolino, Morris 1000, Morris 8, which have been the marvels of technology bringing about a revolution in the automobile.",
    year: "2016",
    image: exhi1,
  },
];

export const currentExhibitions: ExhibitionItem[] = [
  {
    name: "Tiaano",
    desc: "Tiaano is a company with over two decades of manufacturing and design expertise, they have been providing solutions in Chlor-alkali & Desalination application. Cathodic Protection (Impressed Current) - ICCPapplication. Semi-conductors, Anodizing & Metal Finishing application. Steel processing & pickling application etc.",
    image: tiano,
  },
  {
    name: "Inconn",
    desc: "InConn specializes in optimizing asset performance by enabling them to connect with their assets and make intelligent data-driven decisions on asset intelligence & management,",
    image: inconn,
  },
  {
    name: "Unique World Robotics",
    desc: "Unique World Robotics is a STEM.org accredited global educational powerhouse, headquartered in Dubai, with a dynamic presence in India and the UAE. We envision to build a sustainable ecosystem that empowers the next generation of students and enthusiasts worldwide in Robotics, AI & future technologies. We achieve this through a comprehensive range of internationally certified upskilling programs, internships, volunteer opportunities, thrilling competitions and hackathons, groundbreaking research initiatives, and the cultivation of patents and innovative projects. We offer individuals, institutions, and corporations the tools they need to thrive in the rapidly evolving world of future technology like Robotics, AI, Metaverse, and other critical STEM disciplines.",
    image: uwr,
  },
  {
    name: "Farmonaut",
    desc: "Farmonaut is an ISO 27001 certified company who provide satellite based crop health monitoring services as well as field mapping services to farmers and their business partners.",
    image: farmonaut,
  },
  {
    name: "Trimble",
    desc: "Trimble Inc. is an American software, hardware, and services technology company. Trimble supports global industries in building & construction, agriculture, geospatial, natural resources and utilities, governments, transportation and others.",
    image: trimble,
  },
  {
    name: "Let's Play to Learn",
    desc: "LetsPlayToLearn, they work with corporates and educational institutions to harness the magic of games to make learning simple, fun and engaging. Board/card games, digital hypercasual games, workshop activity games are some of their games.",
    image: playtolearn,
  },
  {
    name: "Vayujal",
    desc: "VayuJal Technologies Pvt. Ltd. is an Indian company that aims to alleviate the global water shortage crisis through the development and manufacturing of Atmospheric Water Generators (AWGs)",
    image: vayujal,
  },
  {
    name: "Nautical Wings",
    desc: "Nautical Wings is India's first Electric Propulsion Technology company. Their goal is to contribute to sustainable avaition by developing electric propulsion systems for aircrafts and unmanned systems.",
    image: nauticalwings,
  },
  {
    name: "Beebox",
    desc: "Beebox studios is a startup incubated by IITM incubation cell. They provide realistic high quality interactive 3D experience possible any time & any where for applications beyond entertainment.",
    image: beebox,
  },
  {
    name: "SatoriXR",
    desc: "The Satori platform and products derived from it allows deeper understanding and visualization of phenomena, products and principles using immersive 3D/XR/Digital Twin technologies.",
    image: satori,
  },
  {
    name: "Airdonex",
    desc: "Airlance is an autonomous medical delivery drone designed and developed by Airdonex Technologies Private Limited to provide fast and reliable medical supply transportation to remote or underserved areas. Airlance operates using cutting-edge technology and a suite of sensors to navigate and deliver essential medical supplies, such as blood, vaccines, organs and medications, with the utmost care and efficiency.",
    image: airdonex,
  },
  {
    name: "Scientific Thinking ",
    desc: "Scientific Thinking is an instagram page with over 150k followers which is run by Mr. Akhil Raghu who has a passion of collecting robots, drones, science toys and conducts interactive sessions where robots.",
    image: scientificthinking,
  },
  {
    name: "Meritus AI",
    desc: "Meritus AI provides students with powerful and tranformational educational experiences related to coding ,AI, Robotics etc. Their prototype is a CV pro which is an AI kit to build a self driving car. ",
    image: meritusai,
  },
  {
    name: "Raptee Energy",
    desc: "Raptee Energy is a chennai based startup company of electric vehicles driven by innovative spirit to redefine electric mobility. They will be showcasing their product on all 5 days of Shaastra 2024.",
    image: rapteebike,
  },
  {
    name: "Airos Space",
    desc: "Airosspace R&D is a team of young engineers and designers, with deep expertise and background in drone technology, computer science, electronics, avionics, robotics, Drone technology, GIS, and Data Science",
    image: airosspace,
  },
  {
    name: "Karthikesh Robotics",
    desc: "KarthiKesh Robotics are committed to offering affordable industrial robots, tailored robotic solutions, answers to the issue statement, and much more. We're persistently upgrading ourselves to deliver higher-end robots to our customers. We utilize cuttingedge technology and equipment to manufacture the robots.",
    image: karthikesh,
  },
  {
    name: "Thaalam",
    desc: "Thaalam is a brand committed to making a difference in the lives of individuals with hearing impairments. We believe that everyone deserves the opportunity to experience the joy and power of music. Our mission is to create high-quality, accessible musical instruments specifically designed for the hearing-impaired community.",
    image: thaalam,
  },
  {
    name: "Bio Add",
    desc: "Bio-Add is a MSME registered startup focusing on Bio Products manufacturing. The motto is to equip the society with hazardless products that would not cause any hazards to both humans and the environment.",
    image: bioadd,
  },
  {
    name: "Agri CMSD",
    desc: "AgriCMSD incorporates technology in agriculture, which heightened cost efficiency through precision farming and automation, reducing resource wastage. Simultaneously, farmers gain essential knowledge by interpreting real-time data, fostering informed decision-making. This symbiosis of technology and agriculture is pivotal for sustainable practices and improved productivity",
    image: agriCMSD,
  },
  {
    name: "Mechimed Technologies",
    desc: "Mechimed Technologies Vision is to Reduce the cost of medical equipment and make it available to everyone.Mechimed Technologies aims to Provide economical, compact and highly accurate diagnostic devices that can be used in everyday life.",
    image: mechimedtech,
  },
  {
    name: "Vividobots",
    desc: "Vivodobots is a company working on revolutionary approach to painting the exteriors of tall buildings, incorporating a versatile range of paints to enhance both the structures and lives of people involved",
    image: vividobots,
  },
];
