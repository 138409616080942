import React from "react";
import "./workshopMobileCard.scss";
import { AutodeskType, WorkshopType } from "../../../../types/workshop";
import locationImg from "../../../../assets/images/workshop-location.svg";
import feeImg from "../../../../assets/images/workshop-fees.svg";
import rsImg from "../../../../assets/images/workshop-rs.svg";
import btnArrowImg from '../../../../assets/images/workshop-btn-arrow.svg'
import { motion } from "framer-motion";
import { useNavigate } from "react-router";

type WorkshopMobileCardProps = {
  workshop: WorkshopType | AutodeskType;
  index: number;
};

const WorkshopMobileCard: React.FC<WorkshopMobileCardProps> = ({
  workshop,
  index,
}) => {

  const navigate = useNavigate();
  
  return (
    <div className="workshop-mobile">
      <img className="poster" alt="" src={workshop.poster} />
      <div className="details">
        <div className="title">{workshop.title}</div>
        <div className={`fee-location-container`}>
          <div className={`fee-container`}>
            <img src={feeImg} alt="" className={`fee-locationImg`} />
            <div className={`registration`}>
              <div className={`title1-text`}>Registeration Fees</div>
              <div className={`title2-text`}>
                <span>
                  <img src={rsImg} alt="" className={`rsImg`} />
                </span>
                {workshop.cost}
              </div>
            </div>
          </div>
          <div className={`location-container`}>
            <img src={locationImg} alt="" className={`fee-locationImg`} />
            <div className={`location`}>
              {/* <div className={`title1-text`}>LOCATION</div> */}
              <div className={`title2-text`}>{workshop.venue}</div>
            </div>
          </div>
        </div>

        <button
          className={`workshop-btn`}
          onClick={() => navigate(`/workshops/${workshop.id.slice(-5)}`)}
        >
          MORE DETAILS &nbsp;
          <span>
            <motion.img
              src={btnArrowImg}
              alt=""
              className={`btnArrow`}
            />
          </span>
        </button>
      </div>
    </div>
  );
};
export default WorkshopMobileCard;
