import React, { useEffect, useState } from 'react';
import './about.scss'
// import img from "../../../assets/images/dummy.png"
import { RootState } from '../../../../redux/reducers';
import { useSelector } from 'react-redux';

type AboutProps = {
    
};

const About:React.FC<AboutProps> = () => {

    const { device } = useSelector((state: RootState) => state.windowSize);
    
    const actualText = "Shaastra is the annual technical festival of the Indian Institute of Technology Madras (IITM), Chennai, India. The Sanskrit word ‘Shaastra’ means science and the festival accordingly consists of various engineering, science, and technology competitions, summits, lectures, video conferences, exhibitions, demonstrations and workshops. The festival is traditionally held over four days and four nights from 3rd to 7th January. It has so far seen twenty-four editions, having started in its current avatar in the year 2000. Shaastra is entirely student-managed and is the first such event in the world to be ISO 9001:2015 certified."

    const possibleChars = "0123456789!@#$%^&*()-=_+";
    const [text, setText] = useState<string>(actualText);
    const randomizeChar = () => {
        const ind = Math.floor(Math.random() * actualText.length);
        const randChar = possibleChars.charAt(Math.floor(Math.random() * possibleChars.length));
        const timeout = 500 + Math.floor(Math.random() * 1000)

        setText(curr => {
            const tempC = curr.split("")
            tempC[ind] = randChar
            return tempC.join("")
        })

        setTimeout(() => {
            setText(curr => {
                const tempC = curr.split("")
                tempC[ind] = actualText[ind] 
                return tempC.join("")
            })
        }, timeout);
    }



    useEffect(() => {
        const gID = setInterval(() => {
            randomizeChar()
        }, 250);

        return () => {
            clearInterval(gID);
            // clearInterval(tID);
        };
    }, []);
    
    return(
        <div className={`aboutpage ${device}`}>
            <div className={`about-heading ${device}`}>About</div>
            <div className={`about-para ${device}`}>{text}</div>
        </div>
        
    )
}
export default About;