import React, { useEffect } from "react";
import "./industry.scss"
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-fade";
import "swiper/css/effect-cube";
import "swiper/css/effect-creative";

import {
  Autoplay,
  Navigation,
  EffectCoverflow,
  EffectFade,
  EffectCube,
  EffectCreative,
} from "swiper/modules";


import img1 from "../../../assets/images/poster/Airbus.png"
import img2 from "../../../assets/images/poster/Appian.png"
import img3 from "../../../assets/images/poster/Boeing(post1).png"
import img4 from "../../../assets/images/poster/Boeing(post2).png"
import img5 from "../../../assets/images/poster/Caterpillar.png"
import img6 from "../../../assets/images/poster/KLA.png"
import img7 from "../../../assets/images/poster/LatentView.png"
import img8 from "../../../assets/images/poster/LnT.png"

import img9 from "../../../assets/images/glimps/1.jpg"
import img10 from "../../../assets/images/glimps/2.jpg"
import img11 from "../../../assets/images/glimps/3.jpg"
import img12 from "../../../assets/images/glimps/4.jpg"









import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import Navbar from "../../../ui-elements/navbar/navbar";
// import { RootState } from "../../redux/reducers";
const slides = [
    { image: img1 },
    {
      image: img2,
    },
    { image: img3 },
    { image: img4 },
    { image: img5 },
    { image: img6 },
    { image: img7 },
    { image: img8 }
  ];
  const slides2 = [
    { image: img9 },
    {
      image: img10,
    },
    { image: img11 },
    { image: img12 },
    
  ];
const Industry = () => {

  const { device } = useSelector((state: RootState) => state.windowSize);

    return ( <div className={`industry ${device}`}>
      <Navbar page="Industry"/>
        <div className="heading">
        INDUSTRY INSIGHTS
        </div>
        <div className="subHeading">A Corporate Lecture Series
</div>
<div className="para">Shaastra is thrilled to present the second edition of Industry Insights, 
a lecture series aimed at connecting students with leading contributors in industry. 
Featuring companies like Caterpillar, Boeing, Bajaj and others, Industry Insights hosts 
professionals in diverse fields to share their thoughts on cutting edge technology and on how companies 
tackle the issues of today's world. Sessions are scheduled from 4th to 7th January 2024.
  
  
</div>
<div className="register">
                <a href=" https://forms.gle/EaeeTMg3JSMagE1PA" target="_blank" style={{ color: "black", textDecoration:"none"}}>
                  Register
                </a>
              </div>
           <div className="Pcourosel">
            
          
           <Swiper
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        loop={true}
        
        
        
        autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          modules={[EffectCreative,Autoplay]} 
        className="mySwiper"
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            key={index}
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
           
            <img src={slide.image} className="img" />
          </SwiperSlide>
        ))}
      </Swiper>
<div className="partners">Our Industry Insights Partners</div>
<div className="partnersList">Caterpillar | Boeing | Bajaj | Appian | L&T Edutech | Airbus | KLA | PWC | Latentview
</div>
<div className="glimbs">GLIMPSES FROM PREVIOUS INDUSTRY INSIGHTS
</div>
      <Swiper
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        loop={true}
       
        autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          modules={[EffectCreative,Autoplay]} 
     
        className="mySwiper"
      >
        {slides2.map((slide, index) => (
          <SwiperSlide
            key={index}
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
           
            <img src={slide.image} className="img" />
          </SwiperSlide>
        ))}
      </Swiper>
      
      
            </div>   

    </div> );
}
 
export default Industry;