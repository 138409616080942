import React, { ChangeEvent, useEffect, useState } from 'react';
import './forgotPass.scss'
import InputFields from '../../../ui-elements/inputfields/input/input';
// import Button from '../../../ui/buttons/buttons';
// import CALogo from '../../../assets/logos/CA.svg'
import shaastraLogo from '../../../assets/logos/ShaastraLogoWhite.png'
import { useNavigate } from 'react-router-dom';
import ValidateEmail from '../../../regexExpressions/emailValidation';
import MessagePopup from '../../../ui-elements/messagePopup/messagePoup';
import client from '../../../apolloClient';
import { useForgotPasswordCheckingOtpMutation, useForgotPasswordGetEmailMutation, useForgotpasswordHashingPasswordMutation } from '../../../generated/graphql';
// import Hidden from '../../../assets/icons/hidden.svg'
// import showEye from '../../../assets/icons/eye.svg' 


type OtpProps = {
};

const Otp:React.FC<OtpProps> = () => {

    const [otp, setOtp] = useState<string>()
    const [otpError, setOtpError] = useState<boolean>()
    
    const [email, setEmail] = useState<string>()
    const [emailError, setEmailError] = useState<boolean>()

    const [createPass, setCreatePass] = useState<string>('')
    const [confirmPass, setConfirmPass] = useState<string>('')
    const [emptyError, setEmptyError] = useState<boolean>(false)

    const [showOTP, setShowOTP] = useState<boolean>(false)
    const [otpSent, setOtpSent] = useState<boolean>(false);

    const [forgotPasswordGetEmailMutation, { data: sendEmailData, loading: sendEmailLoading, error: sendEmailError }] = useForgotPasswordGetEmailMutation()
    const [forgotPasswordCheckingOtpMutation, { loading: OTPLoading, error: OTPError, data: OTPData }] = useForgotPasswordCheckingOtpMutation()
    const [forgotpasswordHashingPasswordMutation, { data: resetData, loading: resetLoading, error: resetError }] = useForgotpasswordHashingPasswordMutation()

    const navigate = useNavigate()

    const onResendOTP = () => {
        setShowOTP(false)
    }

    const onSubmitEmail = async () => {
        console.log("here 1")
        if(!email || !ValidateEmail(email)){
            setEmailError(true)
            setTimeout(()=>{setEmailError(false)},4000)
            return
        } else{
            try {
                await forgotPasswordGetEmailMutation({variables: {data: {email}}})
            } catch(err) {
                console.log(err)
            }
        }
    }
    
    useEffect(() => {
        if(sendEmailData?.forgotPasswordGetEmail) {
            setShowOTP(true)
            setOtpSent(true)
            setTimeout(() => {
                setOtpSent(false)
            }, 3000);
        }
    }, [sendEmailData]);

    const onSubmitOTP = async () => {
        console.log("here 2")
        if(otp === '' || otp === null || otp === undefined){
            setOtpError(true)
            return
        } else if(!email){
            setEmailError(true)
            setTimeout(()=>{setEmailError(false)},4000)
            return
        } else{
            try {
                // console.log(otp, email)
                await forgotPasswordCheckingOtpMutation({variables:{data: {otp, email }}})
            } catch(err) {
                console.log(err)
            }
        }
    }

    const [otpWrong, setOtpWrong] = useState<boolean>(false);

    useEffect(() => {
        if(!OTPData?.forgotPasswordCheckingOtp){
            setOtpWrong(true)
            setTimeout(() => {
                setOtpWrong(false)
            }, 3000);
        }
    }, [OTPData]);
    
    // const [createPassSuffixIcon, setCreatePassSuffixIcon] = useState<string>(showEye)
    // const [confirmPassSuffixIcon, setConfirmPassSuffixIcon] = useState<string>(showEye)

    const onShowCreatePassword = () => {
        var passInput = document.getElementById('createPass') as HTMLInputElement
        if(passInput.type === 'password'){
            passInput.type = 'text'
            // setCreatePassSuffixIcon(Hidden)
        }else{
            passInput.type = 'password'
            // setCreatePassSuffixIcon(showEye)
        }
    }

    const onShowConfirmPassword = () => {
        console.log("here 3")
        var passInput = document.getElementById('confirmPass') as HTMLInputElement
        if(passInput.type === 'password'){
            passInput.type = 'text'
            // setConfirmPassSuffixIcon(Hidden)
        }else{
            passInput.type = 'password'
            // setConfirmPassSuffixIcon(showEye)
        }
    }

    const onSubmitPassword = async () => {
        if(!confirmPass || !createPass || createPass !== confirmPass || !email) {
            setEmptyError(true)
            setTimeout(()=>{setEmptyError(false)},4000)
            return 
        }
        try {
            await forgotpasswordHashingPasswordMutation({variables: {data: {confirmPassword: createPass, email }}})
        } catch(err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if(resetData?.forgotpasswordHashingPassword.token) {
            setTimeout(() => {
                navigate('/')
            }, 3000);
        }
    }, [resetData]);
    
    return (
        <div className='page'>
            {(sendEmailLoading || OTPLoading) && <MessagePopup message='Loading...' position='top-center' category='info'/>}
            {otpSent && <MessagePopup message='OTP sent' position='top-center' category='success'/>}
            {OTPData?.forgotPasswordCheckingOtp && <MessagePopup message='Email verified.' position='top-center' category='success' delay={3}/>}
            {resetData && <MessagePopup message='Password Reset.' position='top-center' category='success' delay={3}/>}
            {sendEmailError && <MessagePopup message="Error in sending OTP" position='top-center' category='warning' delay={3}/>}
            {OTPError && <MessagePopup message="Error in verifying OTP" position='top-center' category='warning' delay={3}/>}
            {OTPData && otpWrong && <MessagePopup message="OTP is incorrect" position='top-center' category='warning' delay={3}/>}
            { emptyError && <MessagePopup message={"Please fill all the required fields properly"} category='warning' position='bottom-center'/>}


            {/* TODO: Complete the page */}
            
            <div className='page-content'>
                <div className='page-heading'>{"Enter your registered email address"}</div>

                <div className='form-container'>
                    <div className='container'>
                        {!showOTP ? 
                            <div className='form'>
                                <div className='otp-input'>
                                    <InputFields
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            setEmail(e.target.value)
                                            setEmailError(false)
                                        }}
                                        placeholder='Enter the Email here'
                                        type='email'
                                        error={emailError}
                                        errorMsg="Please enter a valid email address"
                                        required
                                        label='Email'
                                        autoFocus={true}
                                    />
                                </div>
                                <div className='submit-btn' onClick={onSubmitEmail}>Submit</div>
                            </div>
                            : OTPData?.forgotPasswordCheckingOtp ?
                            <div className='form'>
                                <div className='otp-input'>
                                    <InputFields 
                                        label='Enter Passowrd'
                                        id='createPass'
                                        theme='dark' 
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {setCreatePass(e.target.value)}} 
                                        placeholder='Enter a new password' 
                                        required 
                                        // suffixIcon={createPassSuffixIcon}
                                        onSuffixClick={onShowCreatePassword} 
                                        type='password'
                                    />
                                    <InputFields 
                                        label='Confirm Passowrd'
                                        id='confirmPass'
                                        theme='dark' 
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {setConfirmPass(e.target.value)}} 
                                        placeholder='Confirm your password' 
                                        required 
                                        // suffixIcon={confirmPassSuffixIcon}
                                        onSuffixClick={onShowConfirmPassword}  
                                        type='password'
                                    />
                                </div>
                                <div className='submit-btn' onClick={onSubmitPassword}>Submit</div>
                            </div>
                            :
                            <div className='form'>
                                <div className='otp-input'>
                                    <InputFields
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            setOtp(e.target.value)
                                            setOtpError(false)
                                        }}
                                        placeholder='Enter the OTP here'
                                        type='number'
                                        error={otpError}
                                        errorMsg='Please enter the OTP'
                                        required
                                        label='OTP'
                                        autoFocus={true}
                                    />
                                </div>

                                <div className='resend-otp'>
                                    <div className='resend-otp-text' onClick={onResendOTP}>Resend OTP</div>
                                </div>

                                <div className='submit-btn' onClick={onSubmitOTP}>Submit</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Otp;