import React, { useContext, useEffect, useState } from "react";
import "./eventDetails.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { useNavigate, useParams } from "react-router-dom";
// import workshop_poster from "../../../../assets/images/event-workshop.png";
import btnArrowImg from "../../../../assets/images/workshop-btn-arrow.svg";
import rsImg from "../../../../assets/images/workshop-rs.svg";
import WorkshopRules from "../Rules/workshopRules";
import Navbar from "../../../../ui-elements/navbar/navbar";
import { useEventList } from "../../../../utils/events";
import { AuthContext } from "../../../../utils/authProvider";
import timeIcon from "../../../../assets/icons/timeIcon.svg";
import locationIcon from "../../../../assets/icons/locationIcon.svg";
import fileIcon from "../../../../assets/icons/fileIcon.svg";
import phoneIcon from "../../../../assets/icons/phoneIcon.svg";
import downArrow from "../../../../assets/icons/downArrow.svg";
import Faq from "./faq/faq";
import { FAQ } from "../../../../types/faq";
import {
  UserRole,
  useCreateEventMutation,
  useCreateEventSjMutation,
  useGetUserWorkshopsCountByIdQuery,
} from "../../../../generated/graphql";
import MessagePopup from "../../../../ui-elements/messagePopup/messagePoup";

type AddEventProps = {};

const AddEvent: React.FC<AddEventProps> = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [contentDoc, setContentDoc] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [eventDate, setEventDate] = useState(new Date());
  const [venueLink, setVenueLink] = useState("");
  const [venue, setVenue] = useState("");
  const [amount, setAmount] = useState("");
  const [poster, setPoster] = useState("");
  const [unstopLink, setUnstopLink] = useState("");

  const [sj, setSj] = useState(false);

  const [empty, setEmpty] = useState(false);

  const [createEventMutation, { data, loading, error }] = useCreateEventMutation()
  const [createEventSjMutation, { data: dataSJ, loading: loadingSJ, error: errorSJ }] = useCreateEventSjMutation()

  useEffect(() => {
    if(data?.createEvent) {
      setTitle("")
      setDescription("")
      setContentDoc("")
      setContactName("")
      setContactNumber("")
      setEventDate(new Date())
      setVenueLink("")
      setVenue("")
      setAmount("")
      setPoster("")
      setUnstopLink("")
    }
  }, [data]);

  useEffect(() => {
    if(dataSJ?.createEventSJ) {
      setTitle("")
      setDescription("")
      setContentDoc("")
      setContactName("")
      setContactNumber("")
      setEventDate(new Date())
      setVenueLink("")
      setVenue("")
      setAmount("")
      setPoster("")
      setUnstopLink("")
    }
  }, [dataSJ]);

  const onSubmit = async () => {

    if(!title) {
      setEmpty(true)
      setTimeout(() => {
        setEmpty(false)
      }, 3000);
      return
    }
    if(!description) {
      setEmpty(true)
      setTimeout(() => {
        setEmpty(false)
      }, 3000);
      return
    }
    if(!contactNumber) {
      setEmpty(true)
      setTimeout(() => {
        setEmpty(false)
      }, 3000);
      return
    }
    if(!eventDate) {
      setEmpty(true)
      setTimeout(() => {
        setEmpty(false)
      }, 3000);
      return
    }
    if(!venueLink) {
      setEmpty(true)
      setTimeout(() => {
        setEmpty(false)
      }, 3000);
      return
    }
    if(!venue) {
      setEmpty(true)
      setTimeout(() => {
        setEmpty(false)
      }, 3000);
      return
    }
    if(!amount) {
      setEmpty(true)
      setTimeout(() => {
        setEmpty(false)
      }, 3000);
      return
    }
    if(!poster) {
      setEmpty(true)
      setTimeout(() => {
        setEmpty(false)
      }, 3000);
      return
    }
    if(!sj && !unstopLink) {
      setEmpty(true)
      setTimeout(() => {
        setEmpty(false)
      }, 3000);
      return
    }

    try {
      if(sj) {
        await createEventSjMutation({
          variables: {
            data: {
              title,
              description,
              contentDoc,
              contactName,
              contactNumber,
              eventDate: eventDate.toISOString(),
              venueLink,
              venue,
              amount,
              poster,
            }
          }
        })
      } else {
        await createEventMutation({
          variables: {
            data: {
              title,
              description,
              contentDoc,
              contactName,
              contactNumber,
              eventDate: eventDate.toISOString(),
              venueLink,
              venue,
              amount,
              poster,
              unstopLink,
            }
          }
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
    {
      (data?.createEvent || dataSJ?.createEventSJ) && 
    <MessagePopup
          message={"Event Created"}
          position={"top-center"}
          category={"success"}
        />
    }
        {empty && 
    <MessagePopup
          message={"Enter All Fields"}
          position={"top-center"}
          category={"warning"}
        />
        }
        {(loading || loadingSJ) && 
    <MessagePopup
          message={"Loading..."}
          position={"top-center"}
          category={"info"}
        />
        }
        {(error || errorSJ) && 
    <MessagePopup
          message={"Error in creating"}
          position={"top-center"}
          category={"warning"}
        />
        }
      <Navbar page="Workshops" />

      <div className={`details-container ${device}`}>
          <div className={`wrapper ${device}`}>
            <div className={`left-container ${device}`}>
              <div>
                <span>SJ Event?</span><input  type="checkbox" onChange={(e) => setSj(e.target.checked)} placeholder="SJ Event" /> 
              </div>
                <input value={title} type="text" onChange={(e) => setTitle(e.target.value)} placeholder="Enter Title" />
                <input value={description} type="text" onChange={(e) => setDescription(e.target.value)} placeholder="Enter Description" />
                <input value={contentDoc} type="text" onChange={(e) => setContentDoc(e.target.value)} placeholder="Enter Problem Statement Link" />
                <input value={contactName} type="text" onChange={(e) => setContactName(e.target.value)} placeholder="Enter Contact Name" />
                <input value={contactNumber} type="text" onChange={(e) => setContactNumber(e.target.value)} placeholder="Enter Contact Number" />
                <input type="datetime-local" onChange={(e) => setEventDate(new Date(e.target.value))} placeholder="Enter Event Date" />
                <input value={venueLink} type="text" onChange={(e) => setVenueLink(e.target.value)} placeholder="Enter Venue Link" />
                <input value={venue} type="text" onChange={(e) => setVenue(e.target.value)} placeholder="Enter Venue" />
                <input value={amount} type="text" onChange={(e) => setAmount(e.target.value)} placeholder="Enter Amount" />
                <input value={poster} type="text" onChange={(e) => setPoster(e.target.value)} placeholder="Enter image link" />
                {!sj && <input value={unstopLink} type="text" onChange={(e) => setUnstopLink(e.target.value)} placeholder="Enter Unstop Link" />}
                <button onClick={onSubmit}>Submit</button>
                {empty && <span style={{color: "red"}}>Fill all the fields</span>}
              <div className={`about-workshop ${device}`}>
                <div className={`title ${device}`}>{title}</div>
                <div className={`desc ${device}`}>{description}</div>
              </div>
              <div className="phone-doc-wrapper">
                {contentDoc && (
                  <div
                    className="phone-doc content-doc"
                    onClick={() => window.open(contentDoc || "")}
                  >
                    <img src={fileIcon} alt="Time Icon" height={"20px"} />
                    <span>Problem Statement</span>
                  </div>
                )}
                {contactNumber && (
                  <div className="phone-doc">
                    <img src={phoneIcon} alt="Time Icon" height={"20px"} />
                    <div>
                    <span>{contactName}</span><br />
                    <span>{contactNumber}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="event-details">
                <div className="date-month">
                  <div className="month">{eventDate.toLocaleString('default', {month: 'short'})}</div>
                  <div className="date">{eventDate?.getDate()}</div>
                </div>
                <div className="time-venue-wrapper">
                  <div className={`time-venue ${device}`}>
                    <div className="time">
                      <img src={timeIcon} alt="Time Icon" height={"30px"} />
                      <div className="times">
                        <div>{eventDate.toLocaleTimeString('en-IN', {hour: 'numeric', minute: '2-digit'})}</div>
                        {/* <div>
                          {workshop.eveningStartTime === 1
                            ? "1:00 PM - 4:00 PM"
                            : "2:00 PM - 5:00 PM"}
                        </div> */}
                      </div>
                    </div>
                    <div
                      className="venue"
                      onClick={() => window.open(venueLink)}
                    >
                      <img
                        src={locationIcon}
                        alt="Venue Icon"
                        height={"30px"}
                      />
                      <span>{venue}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`price-container ${device}`}>
                <div className={`price-text ${device}`}>Prize Pool: </div>
                <div className={`rs-text`}>
                  <img src={rsImg} alt="" className={`rsImg ${device}`} />
                  &nbsp;{amount}
                </div>
              </div>
            </div>
            <div className={`right-container ${device}`}>
              <img
                src={"https://drive.google.com/uc?export=view&id=" +
                poster.slice(32, poster.indexOf("/view"))}
                alt=""
                className={`posterImg ${device}`}
              />
              <button
                className={`register-btn ${device} `}
                onClick={() => {
                   window.open(unstopLink, "_blank");
                }}
              >
                Register&nbsp;
                <span>
                  <img
                    src={btnArrowImg}
                    alt=""
                    className={`btnArrow ${device}`}
                  />
                </span>
              </button>
            </div>
          </div>
      </div>

    </>
  );
};
export default AddEvent;
