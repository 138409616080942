import React, { useContext, useEffect, useState } from "react";
import "./home.scss";
import About from "./about/about";
import Parallax from "./parallax/parallax";
import Navbar from "../../../ui-elements/navbar/navbar";
import Countdown from "../../../ui-elements/countdown/Countdown";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import ShaastraHeading from "../../../animations/shaastraHeading/shaastraHeading";
import sotVideo from "../../../assets/videos/theme.mp4";
import { AuthContext } from "../../../utils/authProvider";
import MessagePopup from "../../../ui-elements/messagePopup/messagePoup";
import { useNavigate } from "react-router-dom";
import Recognitions from "./recognitions/recognitions";

//schedule images
import day1 from "../../../assets/images/schedule/day1.png";
import day2 from '../../../assets/images/schedule/day2.png'
import day3 from '../../../assets/images/schedule/day3.png'
import day4 from '../../../assets/images/schedule/day4.png'
import day5 from '../../../assets/images/schedule/day5.png'

type HomeProps = {};

const Home: React.FC<HomeProps> = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);

  const [goToOTP, setGoToOTP] = useState(false);

  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  const [activeDay, setActiveDay] = useState<string>("Day1");

  var date = new Date().getDate();
  console.log("date: ", date);

  useEffect(() => {
    if (date === 3) {
      setActiveDay("Day1");
    } else if (date === 4) {
      setActiveDay("Day2");
    } else if (date === 5) {
      setActiveDay("Day3");
    } else if (date === 6) {
      setActiveDay("Day4");
    } else if (date === 7) {
      setActiveDay("Day5");
    }
  }, [date]);

  useEffect(() => {
    if (user.shaastraID !== "TESTID" && !user.emailVerified) {
      setGoToOTP(true);
      setTimeout(() => {
        setGoToOTP(false);
      }, 5000);
    }
  }, [user]);

  return (
    <div className="homepage">
      {goToOTP && (
        <MessagePopup
          onClick={() => navigate("/verify")}
          message={"Verify your email here"}
          position={"top-center"}
          category={"warning"}
        />
      )}
      <Navbar page={"Home"} />
      <div className="theme-reveal">
        <ShaastraHeading />

        <div className={`home-title glitch ${device}`}>Presents to you</div>

        <video
          className="theme-animation"
          src={sotVideo}
          muted
          autoPlay={true}
          loop={true}
        ></video>
        <Countdown />
      </div>
{/* 
      <div className="schedule">
        <div className={`schedule-heading ${device}`}>Daywise Schedule</div>
        <div className={`schedule-btns ${device}`}>
          <div
            className={`day-btn ${
              activeDay === "Day1" ? "active" : ""
            } ${device}`}
            onClick={() => {
              setActiveDay("Day1");
            }}
          >
            Day 1
          </div>
          <div
            className={`day-btn ${
              activeDay === "Day2" ? "active" : ""
            } ${device}`}
            onClick={() => {
              setActiveDay("Day2");
            }}
          >
            Day 2
          </div>
          <div
            className={`day-btn ${
              activeDay === "Day3" ? "active" : ""
            } ${device}`}
            onClick={() => {
              setActiveDay("Day3");
            }}
          >
            Day 3
          </div>
          <div
            className={`day-btn ${
              activeDay === "Day4" ? "active" : ""
            } ${device}`}
            onClick={() => {
              setActiveDay("Day4");
            }}
          >
            Day 4
          </div>
          <div
            className={`day-btn ${
              activeDay === "Day5" ? "active" : ""
            } ${device}`}
            onClick={() => {
              setActiveDay("Day5");
            }}
          >
            Day 5
          </div>
        </div>

        <img alt="" className={`day-img ${device}`} src={activeDay === "Day1" ? day1 : activeDay === "Day2" ? day2 : activeDay === "Day3" ? day3 : activeDay === "Day4" ? day4 : activeDay === "Day5" ? day5 : ""} />
      </div> */}

      {/* <Parallax /> */}
      <About />
      <Recognitions />
    </div>
  );
};
export default Home;
