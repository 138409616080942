/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./login.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import InputFields from "../../../ui-elements/inputfields/input/input";
import ValidateEmail from "../../../regexExpressions/emailValidation";
import ValidateMobile from "../../../regexExpressions/mobileValidation";
import {
  useLoginUserMutation,
  useSignUpUserMutation,
} from "../../../generated/graphql";
import countries from "../../../utils/country";
import hostels from "../../../utils/hostel";
import { useLocation, useNavigate } from "react-router-dom";
import MessagePopup from "../../../ui-elements/messagePopup/messagePoup";
import client from "../../../apolloClient";
import Navbar from "../../../ui-elements/navbar/navbar";
import { upload } from "../../../utils/fileUpload";

type LoginProps = {};

const Login: React.FC<LoginProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<boolean>(false);

  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<string>(
    "Please enter your email"
  );

  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<boolean>(false);

  const [confirmPass, setConfirmPass] = useState<string>("");
  const [confirmPassError, setConfirmPassError] = useState<boolean>(false);
  const [confirmPassErrorMsg, setConfirmPassErrorMsg] = useState<string>(
    "Please enter your passeword"
  );

  const [phn, setPhn] = useState<string>("");
  const [phnError, setPhnError] = useState<boolean>(false);
  const [phnErrorMsg, setPhnErrorMsg] = useState<string>(
    "Please enter your number"
  );

  const [gender, setGender] = useState<string>("");
  const [genderError, setGenderError] = useState<boolean>(false);

  const [college, setCollege] = useState<string>("");
  const [collegeError, setCollegeError] = useState<boolean>(false);

  const [type, setType] = useState<string>("");
  const [typeError, setTypeError] = useState<boolean>(false);

  const [country, setCountry] = useState<string>("");
  const [countryError, setCountryError] = useState<boolean>(false);

  const [isIITM, setIsIITM] = useState<boolean>(false);
  const [isIITMError, setIsIITMError] = useState<boolean>(false);

  const [clas, setClas] = useState<string>("");
  const [clasError, setClasError] = useState<boolean>(false);

  const [hostel, setHostel] = useState<string>("");
  const [hostelError, setHostelError] = useState<boolean>(false);

  const [ErrorMsg, setErrorMsg] = useState<string>("Error in registering..");
  const [signUpUser, { loading, error, data }] = useSignUpUserMutation();
  const [
    loginUser,
    { loading: loginLoading, error: loginError, data: loginData },
  ] = useLoginUserMutation();
  const [showRegister, setShowRegister] = useState(location.pathname === "register");


  const [personalIdFile, setPersonalIdFile] = useState<File | null>();
  const [personalIdFileError, setPersonalIdFileError] = useState(false);
  const [personalIdFileErrorMsg, setPersonalIdFileErrorMsg] = useState("Please upload the verification document");

  const [personalId, setPersonalId] = useState("");

  const [noOfPeople, setNoOfPeople] = useState<number>();
  const [noOfPeopleError, setNoOfPeopleError] = useState(false);
  
  const [mobileShowRegister, setMobileShowRegister] = useState(false);
  const { device } = useSelector((state: RootState) => state.windowSize);
  const changeGender = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setGender(event.target.value);
  };
  const changeIsIITM = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    if (event.target.value === "T") {
      setIsIITM(true);
      setCollege("IITM");
    } else setIsIITM(false);
  };
  const changeClas = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setClas(event.target.value);
  };
  const changeHostel = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setHostel(event.target.value);
  };
  const changeType = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setType(event.target.value);
  };
  const changeCountry = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCountry(event.target.value);
  };
  const changeCollege = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCollege(event.target.value);
  };
  const changeConfirmPass = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setConfirmPass(event.target.value);
  };
  const changePhn = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPhn(event.target.value);
  };
  const changeEmail = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setEmail(event.target.value);
  };
  const changePassword = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPassword(event.target.value);
  };
  const changeName = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setName(event.target.value);
  };

  const handleLogin = async () => {
    setEmailError(false);
    setPasswordError(false);
    if (email === "" || email === null || email === undefined) {
      setEmailError(true);
      return;
    }
    if (!ValidateEmail(email)) {
      setEmailErrorMsg("Please enter a valid email address");
      setEmailError(true);

      return;
    }
    if (password === "" || password === null || password === undefined) {
      setPasswordError(true);
      return;
    }
    try {
      await loginUser({
        variables: {
          data: {
            email,
            password: password,
          },
        },
      });
    } catch (err: any) {
      console.error(err);
      if (err.message === "Account not found") {
        setErrorMsg("Account not found");
      } else if (err.message === "Invalid Credential") {
        setErrorMsg("Invalid Credential");
      } else {
        setErrorMsg("Error in logging in..");
      }
    }
  };
  // const handleRegister = async () => {
  //   setNameError(false);
  //   setPhnError(false);
  //   setConfirmPassError(false);
  //   setCollegeError(false);
  //   setTypeError(false);
  //   setGenderError(false);
  //   setIsIITMError(false);
  //   setCountryError(false);
  //   setEmailError(false);
  //   setPasswordError(false);
  //   setHostelError(false);
  //   setClasError(false);
  //   setTypeError(false);
  //   setClasError(false);

  //   if (name === "" || name === null || name === undefined) {
  //     setNameError(true);
  //     return;
  //   }
  //   if (email === "" || email === null || email === undefined) {
  //     setEmailError(true);
  //     return;
  //   }

  //   if (!ValidateEmail(email)) {
  //     setEmailErrorMsg("Please enter a valid email address");
  //     setEmailError(true);

  //     return;
  //   }
  //   if (password === "" || password === null || password === undefined) {
  //     setPasswordError(true);
  //     return;
  //   }
  //   if (
  //     confirmPass === "" ||
  //     confirmPass === null ||
  //     confirmPass === undefined
  //   ) {
  //     setConfirmPassError(true);
  //     return;
  //   }
  //   if (password !== confirmPass) {
  //     setConfirmPassErrorMsg("passwords are not the same");
  //     setConfirmPassError(true);
  //     return;
  //   }
  //   if (phn === "" || phn === null || phn === undefined) {
  //     setPhnError(true);
  //     return;
  //   }
  //   if (!ValidateMobile(phn)) {
  //     setPhnErrorMsg("Please enter a valid phone number");
  //     setPhnError(true);

  //     return;
  //   }
  //   if (gender === "" || gender === null || gender === undefined) {
  //     setGenderError(true);
  //     return;
  //   }
  //   if (type === "" || type === null || type === undefined) {
  //     setTypeError(true);
  //     return;
  //   }
  //   if (isIITM === null || isIITM === undefined) {
  //     setIsIITMError(true);

  //     return;
  //   }
  //   // console.log("here")
  //   if (
  //     type === "school" &&
  //     (clas === null || clas === undefined || clas === "")
  //   ) {
  //     setClasError(true);
  //     return;
  //   }
  //   // console.log("iamhere")

  //   if (college === "" || college === null || college === undefined) {
  //     setCollegeError(true);

  //     return;
  //   }
  //   if (isIITM && (hostel === "" || hostel === null || hostel === undefined)) {
  //     setHostelError(true);
  //     return;
  //   }

  //   if (country === "" || country === null || country === undefined) {
  //     setCountryError(true);
  //     return;
  //   }

  //   if(!isIITM) {
  //     if (!personalIdFile) {
  //       setPersonalIdFileError(true);
  //       setPersonalIdFileErrorMsg("Please upload the verification document")
  //       return;
  //     }
  //     const extension = personalId.split(".")[personalId.split(".").length - 1].toLowerCase();
  //     const allowed = ["jpeg", "jpg", "png", "pdf"]
  
  //     if (!allowed.includes(extension)) {
  //       setPersonalIdFileError(true);
  //       setPersonalIdFileErrorMsg("Please upload the verification document of file type mentioned above")
  //       return;
  //     }
  //   }

  
  //   if (noOfPeople === null || noOfPeople === undefined) {
  //     setNoOfPeopleError(true);
  //     return;
  //   }


  //   // console.log("error check done");
  //   // console.log(isIITM, personalId)
  //   try {
  //     if(personalIdFile) await upload("oh-registration/" + phn, personalIdFile);
  //     await signUpUser({
  //       variables: {
  //         data: {
  //           name,
  //           email,
  //           password: confirmPass,
  //           mobile: phn,
  //           organization: college,
  //           gender,
  //           country,
  //           type,
  //           isIITM,
  //           Class: clas,
  //           hostel,
  //           personalId: personalIdFile ? `https://shaastra-24.blr1.digitaloceanspaces.com/oh-registration/${phn}` : "IITM",
  //           noOfPeople,
  //         },
  //       },
  //     });

  //     // navigate("/")
  //   } catch (e: any) {
  //     console.log(e);
  //     if (e.message === "User already created. Login to continue") {
  //       setErrorMsg("User already created. Login to continue");
  //     } else setErrorMsg("Error in Registering. Try again");
  //   }
  // };
  useEffect(() => {
    if (data) {
      client.resetStore();
      navigate("/");
    }
  }, [data]);

  useEffect(() => {
    if (loginData?.loginUser) {
      client.resetStore();
      navigate('/')
    }
  }, [loginData]);
  
  return device === "laptop" ? (
    <div className="login-page-wrapper">
      <div className="navbar-wrapper">
        <Navbar page="Login" />
      </div>
      <div
        className={`container ${
          showRegister ? "left-panel-active" : "right-panel-active"
        }`}
      >
        {(loading || loginLoading) && (
          <MessagePopup
            message={"Loading..."}
            position={"top-center"}
            category={"info"}
          />
        )}
        {error && (
          <MessagePopup
            message={ErrorMsg}
            position={"top-center"}
            category={"warning"}
            delay={3}
          />
        )}
        {loginError && (
          <MessagePopup
            message={ErrorMsg}
            position={"top-center"}
            category={"warning"}
            delay={3}
          />
        )}
        <div className="login-or-register">
          <div className={`login-container ${showRegister ? "register" : ""}`}>
            <InputFields
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                changeEmail(e)
              }
              type="text"
              label="E-mail"
              error={emailError}
              errorMsg={emailErrorMsg}
              required
            ></InputFields>

            <InputFields
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                changePassword(e)
              }
              type="password"
              error={passwordError}
              errorMsg="Please enter your password"
              label="Password"
              required
            ></InputFields>

            {/* TODO: implement forgot password */}
            
            {/* <div className="login-forgot-password">
              <h4 onClick={()=>{navigate('/forgotPass')}}>Forgot your password</h4>
            </div> */}
            <button onClick={handleLogin}> LOGIN</button>
          </div>
          {/* <div className="register-container">
            <div className={`details ${device}`}>
              <InputFields
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changeName(e)
                }
                type="text"
                page="register"
                label="Name"
                error={nameError}
                errorMsg="Please enter your name"
                required
              ></InputFields>
              <InputFields
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changeEmail(e)
                }
                type="E-mail"
                page="register"
                label="Email"
                error={emailError}
                errorMsg={emailErrorMsg}
                required
              ></InputFields>

              <InputFields
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changePassword(e)
                }
                type="password"
                page="register"
                label="Password"
                error={passwordError}
                errorMsg="please enter your password"
                required
              ></InputFields>
              <InputFields
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changeConfirmPass(e)
                }
                type="password"
                page="register"
                label="Confirm Password"
                error={confirmPassError}
                errorMsg={confirmPassErrorMsg}
                required
              ></InputFields>
              <InputFields
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changePhn(e)
                }
                type="text"
                page="register"
                label="Phone No"
                error={phnError}
                errorMsg={phnErrorMsg}
                required
              ></InputFields>

              <div className={`form-dropdown-box`}>
                <div className={`form-dropdown-label ${device}`}>Gender*</div>
                <select
                  className={`form-dropdown ${device}`}
                  onChange={changeGender}
                >
                  <option value="" className={`form-dropdown-option ${device}`}>
                    Select
                  </option>
                  <option
                    value="M"
                    className={`form-dropdown-option ${device}`}
                  >
                    Male
                  </option>
                  <option
                    value="F"
                    className={`form-dropdown-option ${device}`}
                  >
                    Female
                  </option>
                  <option
                    value="O"
                    className={`form-dropdown-option ${device}`}
                  >
                    Other/Prefer not to say
                  </option>
                </select>
                {genderError ? (
                  <div className={`dropdown-error ${device}`}>
                    Please select gender
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={`form-dropdown-box`}>
                <div className={`form-dropdown-label ${device}`}>Type*</div>
                <select
                  onChange={changeType}
                  className={`form-dropdown ${device}`}
                >
                  <option value="" className={`form-dropdown-option ${device}`}>
                    Select
                  </option>

                  <option
                    value="school"
                    className={`form-dropdown-option ${device}`}
                  >
                    School
                  </option>
                  <option
                    value="college"
                    className={`form-dropdown-option ${device}`}
                  >
                    College
                  </option>
                  <option
                    value="professional"
                    className={`form-dropdown-option ${device}`}
                  >
                    Professional
                  </option>
                </select>
                {typeError ? (
                  <div className={`dropdown-error ${device}`}>
                    Please select type
                  </div>
                ) : (
                  ""
                )}
              </div>
              {type === "school" ? (
                <InputFields
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    changeClas(e)
                  }
                  type="text"
                  page="register"
                  label="Class"
                  error={clasError}
                  errorMsg="please enter your class"
                  required
                ></InputFields>
              ) : (
                <div className={`form-dropdown-box`}>
                  <div className={`form-dropdown-label ${device}`}>
                    IITM Student*
                  </div>
                  <select
                    onChange={changeIsIITM}
                    className={`form-dropdown ${device}`}
                  >
                    <option
                      value="F"
                      className={`form-dropdown-option ${device}`}
                    >
                      No
                    </option>
                    {type === "college" && (
                      <option
                        value="T"
                        className={`form-dropdown-option ${device}`}
                      >
                        Yes
                      </option>
                    )}
                  </select>
                </div>
              )}
              {type !== "college" ? (
                <InputFields
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    changeCollege(e)
                  }
                  type="text"
                  page="register"
                  label={type === "school" ? "School" : "Organization"}
                  error={collegeError}
                  errorMsg={
                    type === "school"
                      ? "please enter name of school"
                      : "please enter name of organization"
                  }
                  required
                ></InputFields>
              ) : isIITM ? (
                <div className="form-dropdown-box">
                  <div className={`form-dropdown-label ${device}`}>Hostel*</div>
                  <select
                    onChange={changeHostel}
                    className={`form-dropdown ${device}`}
                  >
                    <option
                      value=""
                      className={`form-dropdown-option ${device}`}
                    >
                      Select
                    </option>
                    {hostels.map((hostel) => (
                      <option
                        value={hostel}
                        className={`form-dropdown-option ${device}`}
                      >
                        {hostel}
                      </option>
                    ))}
                  </select>
                  {hostelError ? (
                    <div className={`dropdown-error ${device}`}>
                      Please select hostel
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <InputFields
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    changeCollege(e)
                  }
                  type="text"
                  page="register"
                  label="College"
                  error={collegeError}
                  errorMsg="please enter name of college"
                  required
                ></InputFields>
              )}

              <div className={`form-dropdown-box`}>
                <div className={`form-dropdown-label ${device}`}>Country*</div>
                <select
                  onChange={changeCountry}
                  className={`form-dropdown ${device}`}
                >
                  <option value="" className={`form-dropdown-option ${device}`}>
                    Select
                  </option>
                  {countries.map((country) => (
                    <option
                      value={country}
                      className={`form-dropdown-option ${device}`}
                    >
                      {country}
                    </option>
                  ))}
                </select>
                {countryError ? (
                  <div className={`dropdown-error ${device}`}>
                    Please select country
                  </div>
                ) : (
                  ""
                )}
              </div>
              {!isIITM && 
              <InputFields
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPersonalIdFile(e.target.files![0]);
                  setPersonalIdFileError(false)
                  setPersonalId(e.target.files![0].name || "");
                }}
                type="file"
                page="register"
                errorMsg={personalIdFileErrorMsg}
                error={personalIdFileError}
                label="Photo ID Proof (.pdf, .jpg, .jpeg, .png)"
                required
                placeholder="Upload your verification document here"
                theme='light'
              />
              }

        

              <InputFields
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNoOfPeople(parseInt(e.target.value));
                  setNoOfPeopleError(false)
                }}
                type="number"
                page="register"
                errorMsg="Please enter number of people accompanying"
                error={noOfPeopleError}
                label="Number of People Accompanying"
                required
                placeholder="Enter number of people accompanying"
              />
            </div>
            <button onClick={handleRegister}>REGISTER</button>
          </div> */}
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1 className="overlay-heading">Already registered ?</h1>
                <h3>Login here</h3>
                <button
                  className="login-from-register"
                  id="lfr"
                  onClick={() => setShowRegister(false)}
                >
                  LOGIN
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <h1 className="overlay-heading">Book your slots now!</h1>
                {/* <h3>Register here</h3>
                <button
                  className="register-from-login"
                  id="rfl"
                  onClick={() => setShowRegister(true)}
                >
                  REGISTER
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`mobile-container ${
        mobileShowRegister ? "register-active" : "login-active"
      }`}
    >
      {(loading || loginLoading) && (
        <MessagePopup
          message={"Loading..."}
          position={"top-center"}
          category={"info"}
        />
      )}
      {error && (
        <MessagePopup
          message={ErrorMsg}
          position={"top-center"}
          category={"warning"}
          delay={3}
        />
      )}
      {loginError && (
        <MessagePopup
          message={ErrorMsg}
          position={"top-center"}
          category={"warning"}
          delay={3}
        />
      )}
      <div className="mobile-login-or-register">
        <div
          className={`mobile-login-container ${
            mobileShowRegister ? "register-active" : "login-active"
          }`}
        >
          <h2 className="overlay-heading">Book your slots now!</h2>
          <div className="mobile-login-heading">LOGIN</div>
          <InputFields
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              changeEmail(e)
            }
            type="text"
            label="E-mail"
            error={emailError}
            errorMsg={emailErrorMsg}
            required
          ></InputFields>

          <InputFields
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              changePassword(e)
            }
            type="password"
            error={passwordError}
            errorMsg="please enter your password"
            label="Password"
            required
          ></InputFields>
          <div className="login-line">
            <button className="mobile-login" onClick={handleLogin}>
              {" "}
              LOGIN
            </button>
            {/* <div className="mobile-login-forgot-password">
              <h4>forgot your password ?</h4>
            </div> */}
          </div>
          {/* <div className="to-register">
            <h4>
              Didn't register?{" "}
              <span
                className="mobile-register-from-login"
                id="rfl"
                onClick={() => setMobileShowRegister(true)}
              >
                Register
              </span>
            </h4>
          </div> */}
        </div>
        {/* <div
          className={`mobile-register-container ${
            mobileShowRegister ? "register-active" : "login-active"
          }`}
        >
          <h2>Register</h2>

          <div className="register-container">
            <div className={`details ${device}`}>
              <InputFields
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changeName(e)
                }
                type="text"
                page="register"
                label="Name"
                error={nameError}
                errorMsg="please enter your name"
                required
              ></InputFields>
              <InputFields
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changeEmail(e)
                }
                type="E-mail"
                page="register"
                label="Email"
                error={emailError}
                errorMsg={emailErrorMsg}
                required
              ></InputFields>

              <InputFields
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changePassword(e)
                }
                type="password"
                page="register"
                label="Password"
                error={passwordError}
                errorMsg="please enter your password"
                required
              ></InputFields>
              <InputFields
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changeConfirmPass(e)
                }
                type="password"
                page="register"
                label="Confirm Password"
                error={confirmPassError}
                errorMsg={confirmPassErrorMsg}
                required
              ></InputFields>
              <InputFields
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changePhn(e)
                }
                type="text"
                page="register"
                label="Phone No"
                error={phnError}
                errorMsg={phnErrorMsg}
                required
              ></InputFields>

              <div className={`form-dropdown-box`}>
                <div className={`form-dropdown-label ${device}`}>Gender*</div>
                <select
                  className={`form-dropdown ${device}`}
                  onChange={changeGender}
                >
                  <option value="" className={`form-dropdown-option ${device}`}>
                    Select
                  </option>
                  <option
                    value="M"
                    className={`form-dropdown-option ${device}`}
                  >
                    Male
                  </option>
                  <option
                    value="F"
                    className={`form-dropdown-option ${device}`}
                  >
                    Female
                  </option>
                  <option
                    value="O"
                    className={`form-dropdown-option ${device}`}
                  >
                    Other/Prefer not to say
                  </option>
                </select>
                {genderError ? (
                  <div className={`dropdown-error ${device}`}>
                    please select gender
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={`form-dropdown-box`}>
                <div className={`form-dropdown-label ${device}`}>Type*</div>
                <select
                  onChange={changeType}
                  className={`form-dropdown ${device}`}
                >
                  <option value="" className={`form-dropdown-option ${device}`}>
                    Select
                  </option>

                  <option
                    value="school"
                    className={`form-dropdown-option ${device}`}
                  >
                    School
                  </option>
                  <option
                    value="college"
                    className={`form-dropdown-option ${device}`}
                  >
                    College
                  </option>
                  <option
                    value="professional"
                    className={`form-dropdown-option ${device}`}
                  >
                    Professional
                  </option>
                </select>
                {typeError ? (
                  <div className={`dropdown-error ${device}`}>
                    please select type
                  </div>
                ) : (
                  ""
                )}
              </div>
              {type === "school" ? (
                <InputFields
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    changeClas(e)
                  }
                  type="text"
                  page="register"
                  label="Class"
                  error={clasError}
                  errorMsg="please enter your class"
                  required
                ></InputFields>
              ) : (
                <div className={`form-dropdown-box`}>
                  <div className={`form-dropdown-label ${device}`}>
                    IITM Student*
                  </div>
                  <select
                    onChange={changeIsIITM}
                    className={`form-dropdown ${device}`}
                  >
                    <option
                      value="F"
                      className={`form-dropdown-option ${device}`}
                    >
                      No
                    </option>
                    {type === "college" && (
                      <option
                        value="T"
                        className={`form-dropdown-option ${device}`}
                      >
                        Yes
                      </option>
                    )}
                  </select>
                </div>
              )}
              {type !== "college" ? (
                <InputFields
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    changeCollege(e)
                  }
                  type="text"
                  page="register"
                  label={type === "school" ? "School" : "Organization"}
                  error={collegeError}
                  errorMsg={
                    type === "school"
                      ? "please enter name of school"
                      : "please enter name of organization"
                  }
                  required
                ></InputFields>
              ) : isIITM ? (
                <div className="form-dropdown-box">
                  <div className={`form-dropdown-label ${device}`}>Hostel*</div>
                  <select
                    onChange={changeHostel}
                    className={`form-dropdown ${device}`}
                  >
                    <option
                      value=""
                      className={`form-dropdown-option ${device}`}
                    >
                      Select
                    </option>
                    {hostels.map((hostel) => (
                      <option
                        value={hostel}
                        className={`form-dropdown-option ${device}`}
                      >
                        {hostel}
                      </option>
                    ))}
                  </select>
                  {hostelError ? (
                    <div className={`dropdown-error ${device}`}>
                      please select hostel
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <InputFields
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    changeCollege(e)
                  }
                  type="text"
                  page="register"
                  label="College"
                  error={collegeError}
                  errorMsg="please enter name of college"
                  required
                ></InputFields>
              )}

              <div className={`form-dropdown-box`}>
                <div className={`form-dropdown-label ${device}`}>Country*</div>
                <select
                  onChange={changeCountry}
                  className={`form-dropdown ${device}`}
                >
                  <option value="" className={`form-dropdown-option ${device}`}>
                    Select
                  </option>
                  {countries.map((country) => (
                    <option
                      value={country}
                      className={`form-dropdown-option ${device}`}
                    >
                      {country}
                    </option>
                  ))}
                </select>
                {countryError ? (
                  <div className={`dropdown-error ${device}`}>
                    please select country
                  </div>
                ) : (
                  ""
                )}
              </div>
                  {!isIITM && 
              <InputFields
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPersonalIdFile(e.target.files![0]);
                  setPersonalIdFileError(false)
                  setPersonalId(e.target.files![0].name || "");
                }}
                type="file"
                page="register"
                errorMsg={personalIdFileErrorMsg}
                error={personalIdFileError}
                label="Photo ID Proof (.pdf, .jpg, .jpeg, .png)"
                required
                placeholder="Upload your verification document here"
                theme='light'
              />
                  }

        

              <InputFields
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNoOfPeople(parseInt(e.target.value));
                  setNoOfPeopleError(false)
                }}
                type="number"
                page="register"
                errorMsg="Please enter number of people accompanying"
                error={noOfPeopleError}
                label="Number of People Accompanying"
                required
                placeholder="Enter number of people accompanying"
              />
            </div>

            <button className="mobile-register" onClick={handleRegister}>
              REGISTER
            </button>
            <div className="to-login">
              <h4>
                Already registered?{" "}
                <span
                  className="mobile-login-from-register"
                  id="lfr"
                  onClick={() => setMobileShowRegister(false)}
                >
                  Login
                </span>
              </h4>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default Login;
