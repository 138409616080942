import React, { useContext, useEffect, useState } from "react";
import "./otp.scss";
import InputFields from "../../../ui-elements/inputfields/input/input";
import { AuthContext } from "../../../utils/authProvider";
import { useSendOtpVerifyEmailMutation, useVerifyEmailMutation } from "../../../generated/graphql";
import MessagePopup from "../../../ui-elements/messagePopup/messagePoup";
import { useNavigate } from "react-router-dom";
import client from "../../../apolloClient";
import { RootState } from "../../../redux/reducers";
import { useSelector } from "react-redux";
import TypeWriter from "../../../ui-elements/typeWriter/typeWriter";

type OtpProps = {};

const Otp: React.FC<OtpProps> = () => {
  const [otp, setOtp] = useState<string>("");

  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState(false);
  const [category, setCategory] = useState<"warning" | "success">("warning");

  const { user } = useContext(AuthContext);
  const { device } = useSelector((state: RootState) => state.windowSize);


  const navigate = useNavigate()

  const [sendOtpVerifyEmailMutation, { data: sendData, loading: sendLoading, error: sendError }] = useSendOtpVerifyEmailMutation()
  const [verifyEmailMutation, { data: verifyData, loading: verifyLoading, error: verifyError }] = useVerifyEmailMutation()

  const onSendEmail = async () => {
    if(!sendData?.sendOtpVerifyEmail) {
      await sendOtpVerifyEmailMutation()
    } else {
      setErrorMsg("Mail already sent")
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000);
    }
  }

  const onSubmit = async () => {
    if(sendData?.sendOtpVerifyEmail) {
      await verifyEmailMutation({variables: {data: otp}})
    } else {
      setErrorMsg("Mail not sent")
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000);
    }
  };

  useEffect(() => {
    if(verifyData?.verifyEmail) {
      setCategory("success")
      setError(true)
      setErrorMsg('Email verified successfully')
      client.resetStore()
      setTimeout(() => {
        navigate('/')
      }, 3000);
    }
  }, [verifyData]);

  return (
    <div className="otp-page-wrapper">
      {error &&
      <MessagePopup
          message={errorMsg}
          position={"top-center"}
          category={category}
        />
      }
      {(sendLoading || verifyLoading) &&
      <MessagePopup
          message={"Loading..."}
          position={"top-center"}
          category={"info"}
        />
      }
      <div className={`otp-container ${device}`}>
      <TypeWriter text="Email Verification" />
        <div className="otp-submit" onClick={onSendEmail}>
          {sendData?.sendOtpVerifyEmail ? "Mail sent" : `Send mail to ${user.email.slice(0,3) + "*".repeat(user.email.length - 6) + user.email.slice(-3)}`}
        </div>
        <InputFields
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setOtp(e.target.value.toString());
          }}
          placeholder="Enter the OTP sent here"
          required
          type="number"
          errorMsg="Please enter the OTP to verify your email id"
        />

        <div className="otp-submit" onClick={onSubmit}>
          Submit
        </div>
      </div>
    </div>
  );
};
export default Otp;
