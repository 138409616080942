import { useState } from "react";
import "./Banner.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { SpotlightData } from "../../types/spotlightData";

type BannerProps = {
  spotlightData: SpotlightData;
};

const Banner: React.FC<BannerProps> = ({ spotlightData }) => {
  const { device } = useSelector((state: RootState) => state.windowSize);

  // const contentLimit = {(device) ? 100 : 150}

  const [tempContent, setTempContent] = useState(
    spotlightData.designation.length > 150
      ? spotlightData.designation.substring(0, 150)
      : spotlightData.designation
  );
  const [open, setOpen] = useState(false);

  const Expand = () => {
    if (!open) {
      setTempContent(spotlightData.designation);
      setOpen(true);
    } else {
      setOpen(false);
      setTempContent(spotlightData.designation.substring(0, 150));
    }
  };

  return (
    <div>
      <div className={`banner ${device}`} onClick={Expand}>
        {/* <div className="inner-banner"> */}

        <div className={`banner-head ${device}`}>{spotlightData.name}</div>
        <div className={`banner-content ${device}`}>
          {tempContent}
          {!open && spotlightData.designation.length > 150 && (
            <>
              {" "}
              ...<span className="read-more">Read more</span>
            </>
          )}
        </div>
        {spotlightData.date ? (
          <div className="date">
            <u>Date:</u>
            {` ${spotlightData.date}`}
          </div>
        ) : null}
        {spotlightData.time ? (
          <div className="time">
            <u>Time:</u>
            {` ${spotlightData.time}`}
          </div>
        ) : null}
        {spotlightData.venue ? (
          <div className="venue">
            <u>Venue:</u>
            {` ${spotlightData.venue}`}
          </div>
        ) : null}
        <img
          className={`speaker-img ${device}`}
          src={spotlightData.spotlightImage}
          alt=""
        />

        {/* </div> */}
      </div>
    </div>
  );
};

export default Banner;
