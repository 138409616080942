/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import "./sponsCard.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
type SponsCardProps = {
  imgString: string;
  height: number;
  label?: string;
  link?: string;
};

const SponsCard: React.FC<SponsCardProps> = ({
  imgString,
  height,
  link,
  label,
}) => {
  const { device } = useSelector((state: RootState) => state.windowSize);
  return (
    <div className="spons-card">
      <div className="title"></div>
      <a href={link} target="_blank">
        <img
          src={imgString}
          style={{
            height: height
          }}
          className={`${imgString} ${device}`}
          alt="Description"
        />
      </a>
      {/* <div className={para ${device}}> */}
      <b className={`para ${device}`}>{label}</b>
      {/* </div> */}
    </div>
  );
};

export default SponsCard;
