import React from "react";
import "./sponsors.scss";
import SponsCard from "../../../ui-elements/cards/sponsCard/sponsCard";

// Caterpillar - Diamond Sponsor
// Bajaj - Gold Sponsor
// Altair - Silver Sponsor
// L&T Edutech - Co-Sponsor
// Appian - Co-Sponsor
// Boeing - Innovation Sponsor
// Cisco - Powered by Sponsor
// Temenos - Encryptcon Title Sponsor
// IITM Pravartak Technologies Foundation - Tech and Innovation Fair Title sponsor
// Coca Cola - Beverage Partner
// SIDBI - Emulate Title Sponsor
// Arcesium - Technology Sponsor
import caterpillar from "../../../assets/images/sponsors/caterpillar_diamond.png";
import chetak from "../../../assets/images/sponsors/chetak_gold.png";
import bajaj from "../../../assets/images/sponsors/bajaj_gold.png";
import altair from "../../../assets/images/sponsors/atlair_silver.png";
import lntedu from "../../../assets/images/sponsors/L_Tedutech_co.png";
import appian from "../../../assets/images/sponsors/appian_co.png";
import boeing from "../../../assets/images/sponsors/boeing_innovation.png";
import cisco from "../../../assets/images/sponsors/cisco_powered.png";
import temenos from "../../../assets/images/sponsors/temenos_encryptcon_title.png";
import pravartak from "../../../assets/images/sponsors/pravartak_tif_title.png";
import cococola from "../../../assets/images/sponsors/cococola_beverage.png";
import sidbi from "../../../assets/images/sponsors/sidbi_emulate_title.jpeg";
import arcesium from "../../../assets/images/sponsors/arcesium_technology.png";

// Event Sponsors
// Airbus
// KLA
// PwC
// Flipkart
// LatentView Analytics
// Whirlpool
// TVS Motors
// CAMS
// Surana & Surana
// Bharat Electronics Ltd.
// NIOT
// Cubelelo
import airbus from "../../../assets/images/sponsors/airbus_event.png";
import kla from "../../../assets/images/sponsors/KLA_event.png";
import pwc from "../../../assets/images/sponsors/pwc_event.jpeg";
import flipkart from "../../../assets/images/sponsors/flipkart_event.png";
import latent from "../../../assets/images/sponsors/latentview_event.png";
import whirlpool from "../../../assets/images/sponsors/whirlpool.jpg";
import tvs from "../../../assets/images/sponsors/tvs_event.png";
import cams from "../../../assets/images/sponsors/cams_event.png";
import surana from "../../../assets/images/sponsors/surana_surana_event.png";
import bharat from "../../../assets/images/sponsors/bharatelectronics_event.png";
import niot from "../../../assets/images/sponsors/niot_event.png";
import cubelelo from "../../../assets/images/sponsors/cubelelo_event.png";

// Workshop Partners
// Autodesk
// MathWorks
// Meritus AI
import autodesk from "../../../assets/images/sponsors/autodesk_workshop.png";
import mathworks from "../../../assets/images/sponsors/mathworks_workshop.png";
import meritus from "../../../assets/images/sponsors/meritus_workshop.png";

// Supporting Partners
// ACR - Prize Pool Sponsor
// CODE - Learning Partner (For website, instead of CODE, both NPTEL and IITM B.S. Degree need to be present)
// RoboCap League - Robotics Sponsor
// Shaastra magazine
// Bosch
// R2D2 - AT Makeathon Sponsor
// Pravaha Foundation
// Raptee
import acr from "../../../assets/images/sponsors/acr_supporting_prizepool.png";
import nptel from "../../../assets/images/sponsors/nptel_learning.png";
import iitmbs from "../../../assets/images/sponsors/IITMBS_learning.png";
import robocap from "../../../assets/images/sponsors/robocap.png";
import shaastra from "../../../assets/images/sponsors/shaastra_supporting.png";
import bosch from "../../../assets/images/sponsors/bosch_supporting.jpeg";
import r2d2 from "../../../assets/images/sponsors/r2d2_supporting_makeathon.png";
import pravaha from "../../../assets/images/sponsors/pravaha_supporting.png";
import raptee from "../../../assets/images/sponsors/raptee_supporting.png";

// Campus Ambassador Program Partners
// Seekho
// Languify
// Elearnmarkets

import seekho from "../../../assets/images/sponsors/seekho_ca_partner.png";
import languify from "../../../assets/images/sponsors/languify_ca_partner.png";
import elearn from "../../../assets/images/sponsors/stockedge_ca.png";

// Kind Partners
// Karbonwise - Sustainability partner
// Palmonas - Jewellery Sponsor
// Bril - Stationery Partner
// Suryan FM - Radio Partner
// EaseMyTrip - Travel Partner
// DLink - Network Partner
// DCX3D - 3D Printing Sponsor
// Plum BodyLovin’ - Bath and Body Partner
// Unibic - Cookie Sponsor
// Vilvah Store - Skin Care Partner
// Times Techies by TOI
// Wedtree - Gratitude Partner
// ACT Fibernet
// Bobble Fanstore - Gifting Partner
// Flying Machine - Merchandise partner
// Skechers - Athleisure partner
// Specsmakers - Eye-wear partner
// Beecabs - Transport Partner
// Gozap
// Layyon - merch partner
// Unstop -  Outreach partner (Not for passport, only website mention)
//Codechef
import karbonwise from "../../../assets/images/sponsors/karbonwise_kind_sustainability.jpeg";
import palmonas from "../../../assets/images/sponsors/palmonas_kind_jwellery.png";
import bril from "../../../assets/images/sponsors/bril_kind_stationery.png";
import suryanfm from "../../../assets/images/sponsors/suryanfm_kind_radio.jpeg";
import easemytrip from "../../../assets/images/sponsors/easemytrip_kind_travel.png";
import dlink from "../../../assets/images/sponsors/d-link_kind_network.png";
import dcx3d from "../../../assets/images/sponsors/dcx3d_kind_3d_printing.png";
import plum from "../../../assets/images/sponsors/plum.png";
import unibic from "../../../assets/images/sponsors/unibic_kind_cookie.png";
import vilvah from "../../../assets/images/sponsors/vilvah_kind_skin_care.png";
import times from "../../../assets/images/sponsors/times_kind.png";
import wedtree from "../../../assets/images/sponsors/wedtree_kind.png";
import codechef from "../../../assets/images/sponsors/codechef.png";
import bobble from "../../../assets/images/sponsors/bobble_kind_gifting.png";
import tatoo from "../../../assets/images/sponsors/tatoo.png";
// no logo for flying machine
// skechers no logo
import specs from "../../../assets/images/sponsors/specsmakers_kind_eyewear.png";
import beecabs from "../../../assets/images/sponsors/beecabs_kind_travel.png";
import gozap from "../../../assets/images/sponsors/gozap_kind.png";
import layyon from "../../../assets/images/sponsors/layyon_kind_merch.png";
import unstop from "../../../assets/images/sponsors/unstop_kind_outreach.png";

// Accommodation partner
// Treebo
// Holiday Inn
// treeboo no logo
import holiday from "../../../assets/images/sponsors/holidayinn_accommodation.png";
import treebo from "../../../assets/images/sponsors/treebo_accommodation.png";

// Online outreach Partners (Instagram Pages)
// Chennai Vibes (Not for passport, only website mention)
// Official_teck20k (Not for passport, only website mention)
// Local Madras (Not for passport, only website mention)
import chennai_vibes from "../../../assets/images/sponsors/chennaivibes_online_outreach.png";
import localmadras from "../../../assets/images/sponsors/localmadras_online_outreach.jpeg";
import official from "../../../assets/images/sponsors/official_tech20_outreach.jpeg";
import ourcity from "../../../assets/images/sponsors/ourcity.jpg";

import GlitchTitle from "../../../animations/glitchTitle/glitchTitle";
import Navbar from "../../../ui-elements/navbar/navbar";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";

type SponsorsProps = {};

const Sponsors: React.FC<SponsorsProps> = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);

  return (
    <div className="sponsors">
      <Navbar page="Sponsors" />
      <GlitchTitle title="SPONSORS" />
      <h1 className={`diamond-title ${device}`}>Diamond Sponsor</h1>
      <div className="diamond">
        <SponsCard
          imgString={caterpillar}
          height={device === "mobile" ? 120 : 175}
          link="https://www.caterpillar.com/"
        />
      </div>
      <h1 className={`big ${device}`}>Gold Sponsor</h1>
      <div className={`gold ${device}`}>
        <SponsCard
          imgString={chetak}
          height={device === "mobile" ? 35 : 50}
          link="https://www.chetak.com/"
        />
        <SponsCard
          imgString={bajaj}
          height={device === "mobile" ? 100 : 120}
          link="https://www.chetak.com/"
        />
      </div>
      <h1 className={`big ${device}`}>Silver Sponsor</h1>
      <div className="silver">
        <SponsCard
          imgString={altair}
          height={device === "mobile" ? 45 : 70}
          link="https://altair.com/academic-program"
        />
      </div>
      <h1 className={`big ${device}`}>Co-Sponsors</h1>
      <div className={`co-sponsor ${device}`}>
        <SponsCard
          imgString={lntedu}
          height={device === "mobile" ? 75 : 100}
          link="https://lntedutech.com/"
        />
        <SponsCard
          imgString={appian}
          height={device === "mobile" ? 75 : 100}
          link="https://appian.com/"
        />
      </div>

      <h1 className={`small ${device}`}>Innovation Sponsor</h1>
      <div className="innovation">
        <SponsCard
          imgString={boeing}
          height={device === "mobile" ? 70 : 100}
          link="https://www.boeing.com/"
        />
      </div>
      <h1 className={`small ${device}`}>Powered By Sponsor</h1>
      <div className="powered">
        <SponsCard
          imgString={cisco}
          height={device === "mobile" ? 80 : 100}
          link="https://www.cisco.com/"
        />
      </div>
      <h1 className={`small ${device}`}>Encryptcon Title Sponsor</h1>
      <div className="encryptcon">
        <SponsCard
          imgString={temenos}
          height={device === "mobile" ? 40 : 60}
          link="https://www.temenos.com/"
        />
      </div>
      <h1 className={`small ${device}`}>TIF Title Sponsor</h1>
      <div className="tif-title">
        <SponsCard
          link="https://pravartak.org.in/"
          imgString={pravartak}
          height={device === "mobile" ? 75 : 100}
        />
      </div>
      <h1 className={`small ${device}`}>Beverage Partner</h1>
      <div className="beverage">
        <SponsCard
          imgString={cococola}
          height={device === "mobile" ? 75 : 100}
          link="https://www.coca-cola.com/in/en"
        />
      </div>
      <h1 className={`small ${device}`}>Emulate Title Sponsor</h1>
      <div className="emulate-title">
        <SponsCard
          imgString={sidbi}
          height={device === "mobile" ? 75 : 100}
          link="https://www.sidbi.in/en"
        />
      </div>
      <h1 className={`small ${device}`}>Technology Sponsor</h1>
      <div className="technology">
        <SponsCard
          imgString={arcesium}
          height={device === "mobile" ? 65 : 100}
          link="https://www.arcesium.com/"
        />
      </div>
      <h1 className={`big ${device}`}>Event Sponsors</h1>
      <div className={`event-sponsors ${device}`}>
        <SponsCard
          imgString={airbus}
          height={device === "mobile" ? 35 : 50}
          link="https://www.airbus.com/en/our-worldwide-presence/airbus-in-india"
        />
        <SponsCard
          imgString={kla}
          height={device === "mobile" ? 75 : 100}
          link="https://www.kla.com/"
        />
        <SponsCard
          imgString={pwc}
          height={device === "mobile" ? 75 : 100}
          link="https://www.pwc.in/"
        />
        <SponsCard
          imgString={flipkart}
          height={device === "mobile" ? 75 : 100}
          link="https://www.flipkart.com/"
        />
        <SponsCard
          imgString={latent}
          height={device === "mobile" ? 75 : 100}
          link="https://www.latentview.com/"
        />
        <SponsCard
          imgString={whirlpool}
          height={device === "mobile" ? 60 : 75}
          link="https://www.whirlpoolcorp.com/"
        />
        <SponsCard
          imgString={tvs}
          height={device === "mobile" ? 65 : 80}
          link="https://www.tvsmotor.com/"
        />
        <SponsCard imgString={cams} height={device === "mobile" ? 65 : 80} />
        <SponsCard
          imgString={surana}
          height={device === "mobile" ? 80 : 100}
          link="https://suranaandsurana.com/"
        />
        <SponsCard
          imgString={bharat}
          height={device === "mobile" ? 75 : 100}
          link="https://bel-india.in/"
        />
        <SponsCard
          imgString={niot}
          height={device === "mobile" ? 75 : 100}
          link="https://www.niot.res.in/"
        />
        <SponsCard
          imgString={cubelelo}
          height={device === "mobile" ? 45 : 70}
          link="http://cubelelo.com/"
        />
      </div>
      <h1 className={`big ${device}`}>Workshop Partners</h1>
      <div className={`workshop-partners ${device}`}>
        <SponsCard
          imgString={autodesk}
          height={device === "mobile" ? 40 : 60}
          link="https://www.autodesk.com/careers/overview"
        />
        <SponsCard
          imgString={mathworks}
          height={device === "mobile" ? 40 : 60}
          link="https://in.mathworks.com/"
        />
        <SponsCard
          imgString={meritus}
          height={device === "mobile" ? 40 : 60}
          link="https://merituseducation.com/"
        />
      </div>
      <h1 className={`big ${device}`}>Supporting Partners</h1>
      <div className={`supporting-partners ${device}`}>
        <SponsCard
          imgString={acr}
          label="Prize Pool Sponsor"
          height={device === "mobile" ? 75 : 100}
          link="http://www.acr.iitm.ac.in/"
        />
        <SponsCard
          imgString={nptel}
          height={device === "mobile" ? 75 : 100}
          link="https://nptel.ac.in/"
          label="Learning Partner"
        />
        <SponsCard
          imgString={iitmbs}
          height={device === "mobile" ? 75 : 100}
          label="Learning Partner"
          link="http://study.iitm.ac.in/"
        />
        <SponsCard
          imgString={robocap}
          height={device === "mobile" ? 75 : 100}
          label="Robotics Partner"
          link="http://www.robocapleague.com"
        />
        <SponsCard
          imgString={shaastra}
          height={device === "mobile" ? 75 : 100}
          link="https://shaastramag.iitm.ac.in/current-issue"
        />
        <SponsCard imgString={bosch} height={80} label="Stall Sponsor" />
        <SponsCard
          imgString={r2d2}
          height={150}
          label="AT Makeathon Sponsor"
          link="https://r2d2.iitm.ac.in/"
        />
        <SponsCard
          imgString={pravaha}
          height={150}
          label="Prize Sponsor"
          link="http://www.pravaha.in"
        />
        <SponsCard
          imgString={raptee}
          height={130}
          link="https://www.raptee.com/"
        />
      </div>
      <h1 className={`big ${device}`}>Campus Ambassador Partners</h1>
      <div className={`ca-partners ${device}`}>
        <SponsCard
          imgString={seekho}
          height={device === "mobile" ? 40 : 50}
          link="https://www.seekho.ai/"
        />
        <SponsCard
          imgString={languify}
          height={device === "mobile" ? 55 : 65}
          link="https://www.languify.in/"
        />
        <SponsCard
          imgString={elearn}
          height={device === "mobile" ? 55 : 65}
          link="https://www.elearnmarkets.com/"
        />
      </div>
      <h1 className={`big ${device}`}>Kind Partners</h1>
      <div className={`kind-partners ${device}`}>
        <SponsCard
          imgString={karbonwise}
          height={40}
          label="Sustainability Partner"
          link="http://www.karbonwise.com/"
        />
        <SponsCard
          imgString={palmonas}
          height={75}
          label="Jewellery Sponsor"
          link="http://www.palmonas.com"
        />
        <SponsCard
          imgString={bril}
          height={75}
          label="Stationary Partner"
          link="http://www.brilindia.com"
        />
        <SponsCard
          imgString={suryanfm}
          height={80}
          label="Radio Partner"
          link="https://www.suryanfm.in/"
        />
        <SponsCard
          imgString={easemytrip}
          height={80}
          label="Travel Partner"
          link="https://www.easemytrip.com/"
        />
        <SponsCard
          imgString={dlink}
          height={60}
          label="Network Partner"
          link="https://in.dlink.com/en/consumer"
        />
        <SponsCard
          imgString={dcx3d}
          height={90}
          label="3D Printing Sponsor"
          link="https://www.dcx3d.com/"
        />
        <SponsCard
          imgString={plum}
          height={100}
          link="https://plumgoodness.com/"
          label="Bath and Body Partner"
        />
        <SponsCard
          imgString={unibic}
          height={100}
          label="Cookie Sponsor"
          link="https://unibicfoods.com/"
        />
        <SponsCard
          imgString={vilvah}
          height={100}
          label="Skin Care Partner"
          link="https://bit.ly/3v9t14G"
        />
        <SponsCard
          imgString={times}
          height={100}
          label="Media Partner"
          link="https://www.linkedin.com/company/timestechies/"
        />
        <SponsCard
          imgString={wedtree}
          height={80}
          label="Gratitude Partner"
          link="https://www.wedtree.com/collections/return-gifts?utm_source=gads&utm_medium=search&utm_campaign=rgdynamic&gclid=CjwKCAiAhJWsBhAaEiwAmrNyq9j5nuzqU5uyzrjC7zVfShTP25ntz8XYxJi2pDR0cF0u7TRyNs9xnhoCelYQAvD_BwE"
        />
        {/* <SponsCard imgString={flying}  width={150}/> */}
        {/* <SponsCard imgString={skechers}  width={150}/> */}
        <SponsCard
          imgString={specs}
          height={50}
          label="Eye-wear Partner"
          link="https://www.specsmakers.in/"
        />
        <SponsCard
          imgString={beecabs}
          height={40}
          label="Transport Partner"
          link="https://www.beecabs.in/"
        />
        <SponsCard
          imgString={layyon}
          height={70}
          label="Merch Partner"
          link="https://www.layyon.in/"
        />
        <SponsCard
          imgString={unstop}
          height={70}
          label="Outreach Partner"
          link="https://unstop.com/"
        />
        <SponsCard
          imgString={codechef}
          height={100}
          label="Platform Partner"
          link="https://www.codechef.com/"
        />
        <SponsCard
          imgString={tatoo}
          height={device === "mobile" ? 60 : 90}
          label="Art & Entertainment Partner"
          link="https://www.instagram.com/tattoo_tattva?igsh=MXQ3dmo4N2pydDdiMA%3D%3D&utm_source=qr"
        />
      </div>
      <h1 className={`big ${device}`}>Accommodation Partners</h1>
      <div className={`accommodation-partners ${device}`}>
        <SponsCard
          imgString={treebo}
          height={100}
          link="https://www.treebo.com/"
        />
        <SponsCard
          imgString={holiday}
          height={100}
          link="https://www.ihg.com/holidayinn/hotels/us/en/reservation"
        />
      </div>
      <h1 className={`big ${device}`}>Online Outreach Partners</h1>
      <div className={`outreach-partners ${device}`}>
        <SponsCard
          imgString={chennai_vibes}
          height={100}
          link="https://www.instagram.com/c__h__e__n__n__a__i/"
        />
        <SponsCard
          imgString={official}
          height={100}
          link="https://www.instagram.com/official_teck20k/"
        />
        <SponsCard
          imgString={localmadras}
          height={100}
          link="https://www.instagram.com/local_madras/"
        />
        <SponsCard
          imgString={ourcity}
          height={100}
          link="https://www.instagram.com/ourcitychennai?igsh=OXh6cWdsYjR5OXRh"
        />
      </div>
    </div>
  );
};

export default Sponsors;
