import React, { useEffect, useState } from "react";
import "./glitchTitle.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";

type GlitchTitleProps = {
  title: string;
  size?: "small" | "big"
};

const GlitchTitle: React.FC<GlitchTitleProps> = ({ title, size }) => {
  const { device } = useSelector((state: RootState) => state.windowSize);

  const characters: string[] = [
    '"∆"',
    '"∂"',
    '"?"',
    '"¶"',
    '"░"',
    '"…"',
    '"`"',
    '"≤"',
    '"!"',
    '"≠"',
  ];
  const [titleLetters, setTitleLetters] = useState<string[]>([]);

  useEffect(() => {
    setTimeout(() => {
      const letters: string[] = title.split("");
      setTitleLetters(letters);
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    title = title.toUpperCase();
  });

  return (
    <div className={`glitch-title ${device} ${size}`}>
      <p data-scroll="in">{title}</p>
      <p
        className="reveal--2 words chars splitting"
        aria-hidden="true"
        data-scroll="in"
        data-splitting=""
        style={
          {
            "--word-total": 1,
            "--char-total": titleLetters.length,
          } as React.CSSProperties
        }
      >
        <span className="whitespace"> </span>
        <span
          className="word"
          data-word={title}
          style={{ "--word-index": 0 } as any}
        >
          {titleLetters.map((char, index) => (
            <span
              className={`char ${device}`}
              data-char={char}
              style={
                {
                  "--char-index": index,
                  "--count":
                    index + Math.floor(Math.random() * characters.length),
                  "--char-0":
                    characters[Math.floor(Math.random() * characters.length)],
                  "--char-1":
                    characters[Math.floor(Math.random() * characters.length)],
                  "--char-2":
                    characters[Math.floor(Math.random() * characters.length)],
                  "--char-3":
                    characters[Math.floor(Math.random() * characters.length)],
                  "--char-4":
                    characters[Math.floor(Math.random() * characters.length)],
                  "--char-5":
                    characters[Math.floor(Math.random() * characters.length)],
                  "--char-6":
                    characters[Math.floor(Math.random() * characters.length)],
                  "--char-7":
                    characters[Math.floor(Math.random() * characters.length)],
                  "--char-8":
                    characters[Math.floor(Math.random() * characters.length)],
                  "--char-9":
                    characters[Math.floor(Math.random() * characters.length)],
                } as any
              }
            >
              {char}
            </span>
          ))}
        </span>
      </p>
    </div>
  );
};

export default GlitchTitle;
