import React, { useState, useEffect, useContext } from "react";
import "./registerWorkshop.scss";
import Navbar from "../../../../ui-elements/navbar/navbar";
import Dropdown from "../../../../ui-elements/dropdown/dropdown";
import { useWorkshopList } from "../../../../utils/workshops";
import {
  CreateUserWorkshopMutation,
  useCaptureUserWorkshopPaymentMutation,
  useCreateUserWorkshopMutation,
} from "../../../../generated/graphql";
import MessagePopup from "../../../../ui-elements/messagePopup/messagePoup";
import { loadScript } from "../../../../utils/razorpayHelper";
import { AuthContext } from "../../../../utils/authProvider";
import { useLocation, useNavigate } from "react-router-dom";
import ShaastraHeading from "../../../../animations/shaastraHeading/shaastraHeading";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import InputFields from "../../../../ui-elements/inputfields/input/input";
import PaymentWarning from "../../../../ui-elements/paymentWarning/paymentWarning";
import TypeWriter from "../../../../ui-elements/typeWriter/typeWriter";

type RegisterWorkshopProps = {};

const RegisterWorkshop: React.FC<RegisterWorkshopProps> = () => {
  const location = useLocation();

  const [amount, setAmount] = useState<number>(0);
  const [noOfWorkshops, setNoOfWorkshops] = useState<string>(
    location.state?.combo ? location.state.combo : "1"
  );
  const [merch, setMerch] = useState<string>("No Merch");
  const [size, setSize] = useState<string>("M");
  const [registeredWorkshops, setRegisteredWorkshops] = useState<string[]>([
    "", "", "",
  ]);
  const [registerError, setRegisterError] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  const [ciscoWorkshop, setCiscoWorkshop] = useState(false);
  const [autodeskWorkshop, setAutodeskWorkshop] = useState(false);

  const { workshopList, refetch } = useWorkshopList();
  const unRegisteredWorkshopList = workshopList.filter(
    (workshop) => !workshop.registered && new Date() < new Date(`2024-01-0${(workshop.workshopDate?.getDate() || 3) - 1}T16:30:00.000Z`)
  );
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  const { device } = useSelector((state: RootState) => state.windowSize);

  const merchOptions: { value: string; amount: number }[] = [
    { value: "No Merch", amount: 0 },
    { value: "T-shirt", amount: 100 },
    { value: "Oversized T-shirt", amount: 200 },
    { value: "Hoodie", amount: 300 },
  ];

  const sizeOptions: { value: string }[] = [
    { value: "XS" },
    { value: "S" },
    { value: "M" },
    { value: "L" },
    { value: "XL" },
    { value: "2XL" },
  ];

  const [createUserWorkshopMutation, { data, loading, error }] =
    useCreateUserWorkshopMutation({
      async onCompleted(data) {
        if (data.createUserWorkshop) {
          await loadRazorpay(data);
        }
      },
    });
  const [
    captureUserWorkshopPaymentMutation,
    { data: captureData, loading: captureLoading, error: captureError },
  ] = useCaptureUserWorkshopPaymentMutation({
    async onCompleted(data) {
      if (data) {
        setRegisterSuccess(true);
        refetch()
        setTimeout(() => {
          navigate("/workshops");
        }, 3000);
      }
    },
  });

  const loadRazorpay = async (data: CreateUserWorkshopMutation) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load");
      return;
    }

    const options = {
      key: "rzp_live_FYFoqAtKLutwZf",
      amount: data.createUserWorkshop.amount * 100,
      currency: "INR",
      name: "Shaastra Workshop Registration",
      image: "../../assets/logos/ShaastraLogoBlack.png", //TODO: Add the shaastra link here
      order_id: data.createUserWorkshop.orderId,

      handler: async function (res: any) {
        try {
          await captureUserWorkshopPaymentMutation({
            variables: {
              data: {
                orderId: res.razorpay_order_id,
                paymentId: res.razorpay_payment_id,
                paymentSignature: res.razorpay_signature,
              },
            },
          });
        } catch (e) {
          console.log(e);
        }
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.mobile,
      },
      notes: {
        type: "Shaastra Workshop Registration",
      },
    };

    const rzp1 = new (window as any).Razorpay(options);
    rzp1.open();
  };

  useEffect(() => {
    if (noOfWorkshops === "1") setMerch("No Merch");

    const arr = registeredWorkshops;
    arr.fill("", parseInt(noOfWorkshops));
    setRegisteredWorkshops(arr);
  }, [noOfWorkshops]);

  useEffect(() => {
    const activeRegs = registeredWorkshops.filter((ws_id) => ws_id !== "");
    let amt = activeRegs
      .map((ws_id, ind) => {
        const ws = unRegisteredWorkshopList.find(ws => ws.id === ws_id)!
        // console.log(ind, ws.basket === "1" ? 500 : ws.basket === "2" ? 600 : 2000)
        return ws.basket === "1" ? 500 : ws.basket === "2" ? 600 : 2000
      }
      )
      .reduce((a, b) => a + b, 0);
    amt -= Math.max((activeRegs.length - 1) * 100, 0);
    amt += merchOptions.find((item) => item.value === merch)!.amount;
    setAmount(Math.max(amt - 1, 0));
  }, [registeredWorkshops, merch]);

  // console.log(registeredWorkshops)

  const handleRegister = async () => {
    for (let i = 0; i < parseInt(noOfWorkshops); i++) {
      if (registeredWorkshops[i] === "") {
        setRegisterError(true);
        setTimeout(() => {
          setRegisterError(false);
        }, 3000);
        return;
      }
    }

    try {
      await createUserWorkshopMutation({
        variables: {
          data: {
            amount,
            merch: merch + ": " + size,
            workshopIds: registeredWorkshops.slice(0, parseInt(noOfWorkshops)),
            ciscoWorkshop,
            autodeskWorkshop,
          },
        },
      });
    } catch (err: any) {
      console.log("error", err);
    }
  };

  return (
    <div className="register-workshop">
      {registerError && (
        <MessagePopup
          message={"Workshop not selected"}
          position={"top-center"}
          category={"warning"}
        />
      )}
      {registerSuccess && (
        <MessagePopup
          message={"Workshop Registration Success"}
          position={"top-center"}
          category={"success"}
        />
      )}
      {(loading || captureLoading) && (
        <MessagePopup
          message={"Loaing..."}
          position={"top-center"}
          category={"info"}
        />
      )}
      <Navbar page="Workshops" />

      <div className="register-details-box">
        {/* <div className="register-heading"></div> */}
        <TypeWriter text="Register for Workshops" />
        <div className={`guide ${device}`}>
          Select the combo and workshop you want to register for from the form below
        </div>
        <div className={`guidelines-text ${device}`} onClick={()=>{navigate("/guidelines")}}>Refer to guidelines before registering <u>Click here</u> </div>
        <div className={`details-wrapper ${device}`}>
          <div className="merch-number-wrapper">
            <div className={`dropdown-wrapper ${device}`}>
              <div className="dropdown-label">
                Select the number of workshops you want to register for
              </div>
              <Dropdown
                placeholder={noOfWorkshops}
                options={[{ value: "1" }, { value: "2" }, { value: "3" }]}
                onSelect={(value) => {
                  setNoOfWorkshops(value);
                }}
              />
            </div>

            {/* <div className="dropdown-wrapper">
              <div className="dropdown-label">
                Select the merch (if) you want to include in the combo
              </div>
              <Dropdown
                onSelect={() => {}}
                options={merchOptions}
                placeholder="Select merch options"
              />
            </div> */}
          </div>

          <div className="workshop-dropdowns">
            {new Array(parseInt(noOfWorkshops)).fill(0).map((item, index) => {
              return (
                <div className={`dropdown-wrapper workshops ${device}`}>
                  <div className="dropdown-label">{`Select ${index + 1}${
                    index === 0 ? "st" : index === 1 ? "nd" : "rd"
                  } Workshop`}</div>
                  <Dropdown
                    onSelect={(value) => {
                      const arr = [...registeredWorkshops];
                      arr[index] = value;
                      setRegisteredWorkshops(arr);
                    }}
                    options={unRegisteredWorkshopList
                      .filter(
                        (workshop, index) =>
                          !registeredWorkshops.includes(workshop.id) && (noOfWorkshops === "1" || workshop.basket !== "3")
                      )
                      .map((workshop, index) => ({
                        value: workshop.title,
                        valueStore: workshop.id,
                      }))}
                    placeholder={`Select Workshop ${index + 1}`}
                  />
                </div>
              );
            })}
          </div>
          {noOfWorkshops !== "1" && (
            <div className={`dropdown-wrapper ${device}`}>
              <div className="dropdown-label">
                Select the merch (if) you want to include in the combo
              </div>
              <Dropdown
                onSelect={(value) => {
                  setMerch(value);
                }}
                options={merchOptions}
                placeholder="Select merch options"
              />
            </div>
          )}
          {noOfWorkshops !== "1" && merch !== "No Merch" && (
            <div className={`dropdown-wrapper ${device}`}>
              <Dropdown
                onSelect={(value) => {
                  setSize(value);
                }}
                options={sizeOptions}
                placeholder="Select merch size"
              />
            </div>
          )}

          {noOfWorkshops !== "1" && (
            <>
              <InputFields
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCiscoWorkshop(!ciscoWorkshop);
                }}
                type="checkbox"
                label="Register for Cisco Workshop"
                // required
                // placeholder="Enter your name here"
              />
              <InputFields
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setAutodeskWorkshop(!autodeskWorkshop);
                }}
                type="checkbox"
                label="Register for Autodesk Workshop"
                // required
                // placeholder="Enter your name here"
              />
            </>
          )}

          <div className="amount">Total: {amount}</div>
          <PaymentWarning />
          <div className="register-btn" onClick={handleRegister}>
            Register
          </div>
        </div>
      </div>
    </div>
  );
};
export default RegisterWorkshop;
