import React, { useEffect, useState } from "react";
import "./event.scss";
import Navbar from "../../../ui-elements/navbar/navbar";
import EventCard from "./Card/eventCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import banner from "../../../assets/images/combosBanner.png";
import GlitchTitle from "../../../animations/glitchTitle/glitchTitle";
import { useEventList } from "../../../utils/events";
import WorkshopMobileCard from "./Card/eventMobileCard";
import { useNavigate } from "react-router-dom";
import Scrollindicator from "../../../ui-elements/scrollindicator/scrollindicator";
import EventMobileCard from "./Card/eventMobileCard";
import { useEventSJList } from "../../../utils/eventsj";

type EventProps = {};

const Workshop: React.FC<EventProps> = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);
  const navigate = useNavigate();

  const { eventList } = useEventList();

  const { eventsjList } = useEventSJList();

  const [scrollPosition, setPosition] = useState({ scrollX: 0, scrollY: 0 });

  useEffect(() => {
    function updatePosition() {
      setPosition({ scrollX: window.scrollX, scrollY: window.scrollY });
    }

    window.addEventListener("scroll", updatePosition);
    updatePosition();

    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return (
    <>
      <Navbar page="Events" />

      <div className={`workshop ${device}`}>
        <GlitchTitle title="Events" />
        {scrollPosition.scrollY === 0 ? (
          // <img className="scroll-indicator" alt="" src={downArrow} />
          <Scrollindicator/>
          ) : null}

{eventsjList.length !== 0 && <GlitchTitle title="Shaastra Juniors Events" size="small" />}
{eventsjList.length !== 0 && <div className={`workshop-cards ${device}`}>
        {eventsjList.length === 0 && "Events coming soon"}
          {eventsjList.map((event, index) =>
            device === "mobile" ? (
              <EventMobileCard event={event} index={index} />
            ) : (
              <EventCard event={event} index={index} />
            )
          )}
        </div>}

        <GlitchTitle title="Shaastra Events" size="small" />
        <div className={`workshop-cards ${device}`}>
          {eventList.length === 0 && "Events coming soon"}
          {eventList.map((event, index) =>
            device === "mobile" ? (
              <EventMobileCard event={event} index={index} />
            ) : (
              <EventCard event={event} index={index} />
            )
          )}
        </div>
        {/* <WorkshopDetails /> */}
        {/* <WorkshopDates /> */}
      </div>
    </>
  );
};
export default Workshop;
