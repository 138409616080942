import React from 'react';
import './fullScreenOverlay.scss'
import shaastraLogo from '../../assets/logos/ShaastraLogoWhite.png';

type FullScreenOverlayProps = {
    children: React.ReactNode
};

const FullScreenOverlay:React.FC<FullScreenOverlayProps> = ({children}) => {
    
    return (
        <div className={`full-screen-overlay`}>
            <div className='header'>
                <img alt='' className='logo' src={shaastraLogo}/>
            </div>
            <div className='children'>{children}</div>
        </div>
    )
}
export default FullScreenOverlay;