import "./carousel.scss";
import React, { useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-fade";
import "swiper/css/effect-cube";
import "swiper/css/effect-creative";

import {
  Autoplay,
  Navigation,
  EffectCoverflow,
  EffectFade,
  EffectCube,
  EffectCreative,
} from "swiper/modules";

import img1 from "../../assets/images/event1/IMG_5101.jpg";
import img2 from "../../assets/images/event1/IMG_8012.jpg";
import img3 from "../../assets/images/event1/IMG_8016.jpg";
import img4 from "../../assets/images/event1/IMG_8024.jpg";
import img5 from "../../assets/images/event1/IMG_8026.jpg";
import img6 from "../../assets/images/event1/IMG_8034.jpg";
import img7 from "../../assets/images/event1/IMG_8365.jpg";

import img8 from "../../assets/images/event3/DSCN1076.jpg";
import img9 from "../../assets/images/event3/DSCN1080.jpg";
import img10 from "../../assets/images/event3/DSCN1081.jpg";
import img11 from "../../assets/images/event3/IMG20230914225734.jpg";
import img12 from "../../assets/images/event3/IMG20230915145410_01.jpg";
import img13 from "../../assets/images/event3/IMG20230915145423_01.jpg";
import img14 from "../../assets/images/event3/IMG_8280.jpg";

import img15 from "../../assets/images/event4/IMG20230924181245.jpg";
import img16 from "../../assets/images/event4/IMG20230924191423.jpg";
import img17 from "../../assets/images/event4/IMG20230924195028.jpg";
import img18 from "../../assets/images/event4/IMG20230924203326.jpg";
import img19 from "../../assets/images/event4/20230924_202129.jpg";

import img20 from "../../assets/images/event2/IMG20230921163117_Original.jpg";
import img21 from "../../assets/images/event2/IMG20230921163127_Original.jpg";
import img22 from "../../assets/images/event2/IMG20230921163149_Original.jpg";
import img23 from "../../assets/images/event2/IMG20230921163223_Original.jpg";
import img24 from "../../assets/images/event2/IMG20230921163335_Original.jpg";
import img25 from "../../../src/assets/images/event2/IMG20230921165238_Original.jpg";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";

type ForgetMeNotCarouselProps = {
  title: string;
};

const ForgetMeNotCarousel: React.FC<ForgetMeNotCarouselProps> = ({ title }) => {
//   // const slides = [
//   //   { title: "Awareness Sessions  ", image: img1, date: "13 Sept" },
//   //   {
//   //     title: "Inter Chennai College Dementia Quiz ",
//   //     image: img2,
//   //     date: "21 Sept",
//   //   },
//   //   { title: "Shaastra’s Day Out ", image: img3, date: "15 Oct" },
//   //   { title: "Campaign Launch ", image: img4, date: "24 Oct" },
//   // ];

  const { device } = useSelector((state: RootState) => state.windowSize);
  const slides = [
    { image: img1 },
    {
      image: img2,
    },
    { image: img3 },
    { image: img4 },
    { image: img5 },
    { image: img6 },
    { image: img7 },
  ];
  const slides2 = [
    { image: img8 },
    {
      image: img9,
    },
    { image: img10 },
    { image: img11 },
    { image: img12 },
    { image: img13 },
    { image: img14 },
  ];

  const slides3 = [
    { image: img15 },
    {
      image: img16,
    },
    { image: img17 },
    { image: img18 },
    { image: img19 },
  ];
  const slides4 = [
    { image: img20 },
    {
      image: img21,
    },
    { image: img22 },
    { image: img23 },
    { image: img24 },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      const arrowElement = document.querySelector(".arrow");

      if (scroll >= 1) {
        arrowElement?.classList.add("fade");
      } else {
        arrowElement?.classList.remove("fade");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="carousel">
      <div className={`title ${device}`}>Awareness Sessions</div>

      <Swiper
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        modules={[EffectCreative]}
        className="mySwiper"
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            key={index}
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="top">
              {/* <div className={`title ${device}`}>{slide.title}</div> */}
              {/* <div className="date">{slide.date}</div> */}
            </div>
            <img src={slide.image} className="img" />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className={`title ${device}`}>
      Social Campaign Launch
      </div>

      <Swiper
        spaceBetween={30}
        effect={"fade"}
        modules={[Autoplay, EffectFade]}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        className="mySwiper"
      >
        {slides3.map((slide, index) => (
          <SwiperSlide
            key={index}
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="top">
              {/* <div className={`title ${device}`}>{slide.title}</div> */}
              {/* <div className="date">{slide.date}</div> */}
            </div>
            <img src={slide.image} className="img" />
          </SwiperSlide>
        ))}
      </Swiper>

       <div className={`title ${device}`}>Shaastra’s Day Out</div>

      <Swiper
        modules={[Autoplay, EffectCube]}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        effect={"cube"}
        cubeEffect={{
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        }}
        className="mySwiper"
      >
        {slides2.map((slide, index) => (
          <SwiperSlide
            key={index}
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="top">
              {/* <div className={`title ${device}`}>{slide.title}</div> */}
              {/* <div className="date">{slide.date}</div> */}
            </div>
            <img src={slide.image} className="img" />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className={`title ${device}`}>Inter Chennai College Dementia Quiz</div>

      <Swiper
        modules={[Autoplay, EffectCoverflow]}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        slidesPerView={device === "mobile" ? 1 : 2}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        effect={"coverflow"}
        className="mySwiper"
      >
        {slides4.map((slide, index) => (
          <SwiperSlide
            key={index}
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="top">
              {/* <div className={`title ${device}`}>{slide.title}</div> */}
              {/* <div className="date">{slide.date}</div> */}
            </div>
            <img src={slide.image} className="img" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ForgetMeNotCarousel;