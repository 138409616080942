import React from "react";
import './shaastraHeading.scss'
import RedoAnimText from "./RedoAnimText";
import CursorBlinker from "../../ui-elements/cursorBlinker/cursorBlinker";

type ShaastraHeadingProps = {

}

const ShaastraHeading: React.FC<ShaastraHeadingProps> = () => {
  return (
    <div className="type-writer-wrapper">
      <div className="line"></div>
      <div className="typewriter">
        <RedoAnimText delay={1 + 1} />
        <CursorBlinker />
      </div>
      <div className="line"></div>
    </div>
  );
};
export default ShaastraHeading;
