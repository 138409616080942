/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import "./App.scss";
import { useSelector } from "react-redux";
import { RootState } from "./redux/reducers";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./screens/pages/home/home";
import SpotLight from "./screens/pages/spotlight/SpotLight";
import Exhibitions from "./screens/pages/exhibitions/exhibitions";
import Login from "./screens/auth/login/login";
import Envisage from "./screens/pages/envisage/envisage";
import ExhiContainer from "./ui-elements/exhiContainer/exhiContainer";
import bgImage from "../src/assets/images/bgImages/1.png";
import Workshop from "./screens/pages/workshops/workshop";
import WorkshopDetails from "./screens/pages/workshops/Details/workshopDetails";
// import Navbar from "./ui-elements/navbar/navbar";
import ForgetMeNot from "./screens/pages/forgetMeNot/forgetMeNot";
import Footer from "./ui-elements/footer/footer";
import Events from "./screens/pages/events/event";
import RegisterWorkshop from "./screens/pages/workshops/registerWorkshop/registerWorkshop";
import Accomodation from "./screens/pages/accomodation/accomodation";
import Otp from "./screens/auth/otp/otp";
import ForgotPass from "./screens/auth/forgotPass/forgotPass";
import Guidelines from "./screens/pages/workshops/guidelines/guidelines";
import EventDetails from "./screens/pages/events/Details/eventDetails";
import AddEvent from "./screens/pages/events/Details/addEvent";
import { AuthContext } from "./utils/authProvider";
import { UserRole } from "./generated/graphql";
import Passport from "./screens/pages/passport/passport";
import Stats from "./screens/adminPages/stats/stats";
import EventSJDetails from "./screens/pages/events/Details/eventsjDetails";
import AutodeskDetails from "./screens/pages/workshops/Details/autodeskWorkshopDetails";
import ErrorPage from "./screens/pages/errorPage/errorPage";
import Industry from "./screens/pages/industry/industry";
import Sponsors from "./screens/pages/sponsors/sponsors";
import OHSlots from "./screens/pages/planetarium/planetarium";
import PassportStats from "./screens/adminPages/stats/passportStats";
import OpenHouse from "./screens/pages/openhouse/Openhouse";
import Sales from "./screens/pages/sales/sales";
import OpenHouseStats from "./screens/adminPages/stats/openHousestats";

function App() {
  const [logoAnimation, setIsLogoAnimation] = useState<boolean>(false);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    // setTimeout( () => {setIsLogoAnimation(false)}, 5000)
  }, []);

  const { device } = useSelector((state: RootState) => state.windowSize);
  return (
    <div className="App">
      <BrowserRouter>
        <div className={`website-content ${device}`}>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/" element={<Exhibitions/>}/> */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Login />} />
            <Route path="/verify" element={<Otp />} />
            <Route path="/forgotPass" element={<ForgotPass />} />
            <Route path="/spotLight" element={<SpotLight />} />
            <Route path="/sponsors" element={<Sponsors />} />
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/envisage" element={<Envisage />} />
            <Route path="/exhibitions" element={<Exhibitions />} />
            <Route
              path="/exhibitions/:past/:index"
              element={<ExhiContainer />}
            />
            <Route
              path="/exhibitions/:past/:index"
              element={<ExhiContainer />}
            />
            <Route path="/events" element={<Events />} />
            <Route path={`/events/:id`} element={<EventDetails />} />
            <Route path={`/sj-events/:id`} element={<EventSJDetails />} />
            <Route path="/workshops" element={<Workshop />} />
            <Route path={`/workshops/101`} element={<AutodeskDetails />} />
            <Route path={`/workshops/:id`} element={<WorkshopDetails />} />
            <Route path="/register-workshop" element={<RegisterWorkshop />} />
            <Route path="/guidelines" element={<Guidelines />} />
            {/* <Route path="/accommodation" element={<Accomodation />} /> */}
            <Route path="/forgetmenot" element={<ForgetMeNot />} />
            {user.role === UserRole.Admin && (
              <>
                <Route path="/addevent" element={<AddEvent />} />
                <Route path="/stats" element={<Stats />} />
                <Route path="/passport-stats" element={<PassportStats/>} />
                <Route path="/open-house-stats" element={<OpenHouseStats />} />
              </>
            )}
            {user.personalId && !user.disabled && 
            <>
            <Route path="/open-house/slots" element={<OHSlots />} />
            </>}
            <Route path="/forgetmenot" element={<ForgetMeNot />} />
            <Route path="/passport" element={<Passport />} />
            <Route path="*" element={<ErrorPage />} />
            <Route path="/industry-insights" element={<Industry />} />
            <Route path="/open-house" element={<OpenHouse />} />
            <Route path="/sales" element={<Sales/>}/>
            {/* <Route path="/lasertag" element={<LaserTag />} /> */}
          </Routes>
        </div>
        {/* <img className="bgImage" alt="" src={bgImage} /> */}
        <Footer />
      </BrowserRouter>
      {/* <img className="bgimg" src={bgimg} alt=""/>  */}
    </div>
  );
}

export default App;
